
import {RENDER_STATE} from 'react-map-gl-draw';

export const getEditHandleStyle=({feature, state})=> {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        fill: 'rgb(251, 176, 59)',
        fillOpacity: 1,
        stroke: 'rgb(255, 255, 255)',
        strokeWidth: 2,
        r: 7
      };

    default:
      return {
        fill: 'rgb(251, 176, 59)',
        fillOpacity: 1,
        stroke: 'rgb(255, 255, 255)',
        strokeWidth: 2,
        r: 5
      };
  };
};

export const getFeatureStyle=({feature, index, state})=> {

  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: 'rgb(251, 176, 59)',
        strokeWidth: 2,
        fill: 'rgb(251, 176, 59)',
        fillOpacity: 0.3,
        strokeDasharray: '4,2',
        cursor:'pointer'
      };
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.INACTIVE:
      return{
        stroke: '#ffffff',
        strokeWidth: 2,
        fill:"#3cb2d0",
        fillOpacity: 0.3,
        cursor:'pointer'
      }

    default:
      return {
        stroke: 'rgb(251, 176, 59)',
        strokeWidth: 2,
        fill: 'rgb(251, 176, 59)',
        fillOpacity: 0.3,
        cursor:'pointer'
      };
  }
};