import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Grid,Popper,ClickAwayListener, MenuItem} from '@material-ui/core';
import { SUGGESTIONS } from '../../constants';
import { setSearchText } from '../../actions/members';

const SuggestionPopup = ({onSelect}) => {
    const dispatch=useDispatch();

    const {searchCategory,searchText} = useSelector(state => state.members);
    const theme=useSelector(state=>state.user.theme);

    return(
        <>
            <Popper
            id={'suggestion-category-drop'}
            open={true}
            anchorEl={document.getElementById('suggestions-drop')}
            className={`suggestion-category-drop ${theme}`}
            role={undefined}
            transition
            disablePortal
            >
            <ClickAwayListener onClickAway={(e)=>{e.stopPropagation()}}>
               <Grid container className='drop-list'>
               {SUGGESTIONS[`${searchCategory.category}_${searchCategory.subCategory}`].map((sug,i)=>(
                    <MenuItem 
                    key={`${sug}_${i}`} 
                    className={`sub-category ${searchText===sug?'selected':''}`}
                    onClick={()=>{dispatch(setSearchText(sug));onSelect()}}>{sug}</MenuItem>))}
               </Grid>
            </ClickAwayListener>
            </Popper>
        </>
    );
}

export default SuggestionPopup