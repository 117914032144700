import React from 'react';
import {useSelector } from 'react-redux';
import { Grid,Typography,Link} from '@material-ui/core';
import { HEADER_OPTIONS } from '../../../constants';

const DashboardInitial = () => {
    const connected = useSelector(state => state.user.userInfo.connected);
    const headerRoutes = connected ? HEADER_OPTIONS.filter(op => op.auth) : HEADER_OPTIONS;
    const header = headerRoutes?.filter(op=> !op.theme);

    return (
        <Grid container className='dashboard-initial'>
            <Typography className='chitta-icon'>W</Typography>
            <Grid container direction="column" className='menu-container'>
                <Grid container className='menu'>
                    <div className='menu-list'>
                        {header.map((option)=>{
                            return(<Typography key={option.id} variant='body2' className='menu-item' onClick={e=>e.stopPropagation()}>
                                {option.link ? <Link href={option.link} target="_blank" color="inherit">{option.label.toLowerCase()}</Link> : option.label.toLowerCase()}
                            </Typography>)
                        })}
                    </div>
                </Grid>
            </Grid>    
        </Grid>
    );
};

export default DashboardInitial;