// LOGIN-INFO
export const REQUESTING_LOG_IN = 'REQUESTING_LOG_IN';
export const ON_LOGIN = 'ON_LOGIN';
export const SET_CONNECT_METHOD = 'SET_CONNECT_METHOD';
export const SET_USER_CREDS = 'SET_USER_CREDS';
export const CLEAR_USER_CREDS = 'CLEAR_USER_CREDS';
export const ON_SIGN_OUT = 'ON_SIGN_OUT';

// WALLET
export const SET_WALLET = 'SET_WALLET';
export const SET_REWARD = 'SET_REWARD';
export const SET_ASSETS = 'SET_ASSETS';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';

// MEMBERS
export const REQUESTING_MEMBERS_LIST = 'REQUESTING_MEMBERS_LIST';
export const SET_MEMBERS_LIST = 'SET_MEMBERS_LIST';
export const SET_SELECTED_MEMBER = 'SET_SELECTED_MEMBER';
export const SET_MESSAGE_COUNT = 'SET_MESSAGE_COUNT';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_SELECTED_FIELD_ID = 'SET_SELECTED_FIELD_ID';
export const SET_SELECTED_FEATURE = 'SET_SELECTED_FEATURE';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_FIELD = 'DELETE_FIELD';
export const SET_OVERLAY = 'SET_OVERLAY';

//THEME
export const SET_THEME = 'SET_THEME';

//FLIGHTS
export const REQUESTING_FLIGHT_LIST='REQUESTING_FLIGHT_LIST';
export const SET_FLIGHT_LIST='SET_FLIGHT_LIST';

//SEARCH CATEGORIES
export const SET_SEARCH_CATEGORY='SET_SEARCH_CATEGORY';
export const SET_SEARCH_TEXT='SET_SEARCH_TEXT';

//FARMER_DASHBOARD
export const SET_SELECTED_OPTION='SET_SELECTED_OPTION';
export const SET_SELECTED_CROP='SET_SELECTED_CROP';