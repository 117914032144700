import React,{useState,useEffect,Fragment} from 'react';
import { Grid,IconButton,Typography} from '@material-ui/core';
import { useSelector,useDispatch } from 'react-redux';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {Cache } from 'aws-amplify';
import { timerUnits,leading0,fetchGrid, getTimeUntil } from '../../helpers';
import { GRID_LIST } from '../../constants';
import { toggleTheme } from '../../actions/user';

const nextOverpass = Cache.getItem('nextOverPass');

const SatData = (props) => {
    const {setGetStart,setShow,collapseCondition}=props;

    const dispatch=useDispatch();
    const[registered,setRegistered]=useState({show:true,grids:[]});
    const [timeLeft, setTimeLeft] = useState({label:nextOverpass?.label,grid:nextOverpass?.grid,dl:getTimeUntil(nextOverpass)});
    const [ index, setIndex ] = useState(0);

    const theme=useSelector(state=>state.user.theme);
    const fieldList=useSelector(state=>state.members.fieldsList.list);
    const membersList = useSelector(state => state.members.membersList.list);

    useEffect(() => {
        let nextOverPass=getUpComingSat();
        const{label,grid}=nextOverPass;

        const timer = setInterval(() => {setTimeLeft({label,grid,dl:getTimeUntil(nextOverPass)})}, 1000);
        const interval = setInterval(() => setIndex(Math.floor(Math.random() * Math.floor(registered.grids.length))), 2500);

        // Clear interval if the component is unmounted
        return () => clearInterval(timer,interval);
    },[membersList]);

    useEffect(()=>{
        if(timeLeft.dl.T)getUpComingSat();

    },[timeLeft?.dl?.T]);

    const getUpComingSat=()=>{
        const fieldGrid=fetchGrid(fieldList);

        try{
            const overpasses=GRID_LIST.filter(item=>fieldGrid.includes(item.grid)).map(g=>{
                const interval=((new Date().getTime() - (g.dt * 1000))/g.condition);
                const pending=((Math.ceil(interval) - interval)*g.condition);
                return {label:g.label,dl:(new Date().getTime()+pending),grid:g.grid}
            }).reduce((acc,cur)=>{
                if(acc.dl<cur.dl)return acc;
                else return cur;
            });
            
            Cache.setItem('nextOverPass',overpasses);
            setTimeLeft({...overpasses,dl:getTimeUntil(overpasses)});
            
            return overpasses;
        }catch{
            const notReg = fieldGrid.filter(item=>!GRID_LIST.includes(item.grid));
            setRegistered({show:false,grids:notReg});

            return {label:'',grid:'',dl:''};
        }
    };

    return (
        <Grid className='satellite-data' >
            <Typography id='bold' variant='caption'>Next update :</Typography>
            {!registered.show?
            <Typography id='bold' variant='body1' style={{width:'6.421rem',marginTop:'0.5rem'}}>
                <span>{registered.grids[index]}</span>
                <span> is not registered</span>
            </Typography>
            :<>
                <Typography id='counter' variant='body1' style={{marginTop:'0.15rem'}} onClick={(e) => {e.stopPropagation(); setShow(true)}}>
                    {timeLeft.dl.T ? <span style={{marginRight:'0.25rem'}}>T+</span> : null }
                    {timerUnits.map(unit=><Fragment key={unit.id}>
                    {unit.id!==1&&<span style={{marginRight:'0.25rem'}}>:</span>}
                        <span style={{marginRight:'0.25rem'}}>{leading0(timeLeft.dl[unit.unit])}</span>
                    </Fragment>)}
                </Typography>
                <Typography id='bold' variant='caption' style={{marginTop:'0.25rem'}}>
                    <span style={{marginRight:'0.5rem'}}>type :</span>
                    <span>{timeLeft.label?`${timeLeft.label}`:'UNKNOWN'}</span>
                </Typography>
                <Typography id='bold' variant='caption'>
                    <span style={{marginRight:'0.5rem'}}>zone :</span>
                    <span>{timeLeft.grid?`${timeLeft.grid}`:'UNKNOWN'}</span>
                </Typography>
            </>}
            <Typography id='get-start' variant='caption' onClick={()=>(membersList.length && !collapseCondition)?setGetStart(getStart=>!getStart):{}}>Get started</Typography>
            <IconButton onClick={(e)=>{e.stopPropagation();dispatch(toggleTheme())}}>
                {theme==='light'?<Brightness2Icon id='moon'/>:<WbSunnyIcon id='sunny'/>}
            </IconButton>
        </Grid>
    );
};

export default SatData;