import { Auth,Cache} from 'aws-amplify';
import { toast } from 'react-toastify';
import sha256 from 'crypto-js/sha256';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import { Snack } from '../components/widgets/Toast';
import { REQUESTING_LOG_IN,ON_LOGIN,ON_SIGN_OUT,SET_WALLET,SET_USER_CREDS,SET_OVERLAY,
  CLEAR_USER_CREDS,SET_THEME,SET_MESSAGE_COUNT,SET_MESSAGE,SET_ASSETS} from "../constants/action-types";
import { getAccountInformation} from '../helpers/algorand';
import {AccountNeedsOptIn} from '../helpers';
import Processing from '../components/widgets/Processing';
import { setMembersList } from './members';

export const requestingSignIn = (loading) => ({ type: REQUESTING_LOG_IN, payload: loading });

export const onLogin=(data)=>({type:ON_LOGIN,payload:data});

export const setWallet=(wallet)=>({type:SET_WALLET,payload:wallet});

export const setAssets=()=>({type:SET_ASSETS});

export const setUserCreds=(userName,password)=>({type:SET_USER_CREDS,payload:{userName,password}});

export const clearUserCreds=()=>({type:CLEAR_USER_CREDS});

export const onSignOut=()=>({type:ON_SIGN_OUT});

export const setTheme=(theme)=>({type:SET_THEME,payload:theme});

export const setMessageCount=(count)=>({type:SET_MESSAGE_COUNT,payload:count});

export const setMessage=(type,status)=>({type:SET_MESSAGE,payload:{type,status}});

export const setOverlay=(show,text)=>({type:SET_OVERLAY,payload:{show,text}});

export const toggleTheme=()=>(dispatch,getState)=>{
  const theme = getState().user.theme;
  dispatch(setTheme(theme === 'light'?'dark':'light'));
};

export const onLoad =(cb=null)=> async (dispatch) => {
    const CachedMembersWithNotes=Cache.getItem('membersWithNotes');
    const CachedMethod = Cache.getItem('loginMode');
    dispatch(setMembersList(CachedMembersWithNotes));
  
  try {
    await Auth.currentSession()
    .then((session) => {
      getAccountInformation(session.accessToken.payload.username)
      console.log('getAccountInformation',getAccountInformation(session.accessToken.payload.username))
      .then((wallet) => {
        let NewOptins = AccountNeedsOptIn(wallet);
        session.idToken.payload['optin'] = NewOptins;
        
        const payload={
          session:session.idToken.payload,
          wallet,
          connected:true,
          connectMethod: CachedMethod,
        };
        
        Cache.setItem('userInfo',payload)
        dispatch(onLogin(payload));
        if(cb)cb(false);
        Cache.setItem('SessionWallet', { session: payload.session, wallet: payload.wallet });
      })
    })
  } catch (e) {
    console.log('onLoad error--> ', e);
    if(cb)cb(false);
  }
};

export const signIn =(userName,password,mnemonic,cb = null) => async (dispatch)=> {
  dispatch(requestingSignIn(true));

    try {
      Snack('Signing in...', 5000, Processing(),'dark',false);

      getAccountInformation(userName)
        .then( async (wallet) => {
          let NewOptins = AccountNeedsOptIn(wallet);
          const passwordOld = (sha256(userName + mnemonic)).words[0].toString();
          console.log('username passwordnew', userName, password, passwordOld)
          await Auth.signIn(userName, passwordOld).then((value, error) => {
              value.attributes['optin'] = NewOptins;

              const payload={
                session:value.attributes,
                wallet,
                connected:true,
                connectMethod:0
              };

              Cache.setItem('userInfo',payload);
              dispatch(onLogin(payload));
              if(cb)cb(true);
              Snack('Success, Your wallet is connected', 6000, null, 'success', true);
            })
            .catch (async e => {
              dispatch(requestingSignIn(false));
              if (e.code === "UserNotFoundException" || e.code === "UserNotConfirmedException" ||e.code==="NotAuthorizedException") {
                //Lets login with the old password, then change the password to the new
                // needs to be removed when all accounts have been updated with the new password
                console.log('username passwordold', userName, passwordOld)
                await Auth.signIn(userName, passwordOld)

                .then( async (value) => {
                  value.attributes['optin'] = NewOptins;
                  Auth.changePassword(value,passwordOld,password)
                  console.log('run change password', password)
                  const payload={
                    session:value.attributes,
                    wallet,
                    connected:true,
                    connectMethod:0
                  };
                  
                  dispatch(onLogin(payload));
                  if(cb)cb(true);
                })
                // remove untill here when all accounts have been updated with the new password.
                Snack('Your account has not been confirmed. Please contact info@chitta.org and ask for permission.', 6000, null, 'warning', true);
                if(cb)cb(false);
              }else {
                Snack('Ai. Something went wrong. Try again or contact us at info@chitta.org', 6000, null, 'error', true);
              }
            });
        }).catch(e => {
          console.log("algoError--> ", e);
          dispatch(requestingSignIn(false));
          Snack('Ai. Failed to get Algo account information.', 6000, null, 'error', true);
          if(cb)cb(false);
        });
    } catch (e) {
      console.log("signInError--> ", e);
      dispatch(requestingSignIn(false));
    }
};

export const signUp =(signupValue,cb=null) => async (dispatch,getState)=> {
  const {userName:username,password}=getState().user.tempCreds;

    getAccountInformation(username).then((wallet) => {
        dispatch(setWallet(wallet));
    });

    try {
      Auth.signUp({
        password, username, attributes: {
          given_name: signupValue.firstName,
          family_name: signupValue.lastName,
          phone_number: signupValue.phonenumber,
          'custom:companyName': signupValue.companyName,
        }
      }).then(() => {
        if(cb)cb();
        Snack('Great. Please verify your phone number.', 6000, null, 'success', true);
      })
    } catch (e) {
      if (e.code === "InvalidParameterException") {
        Snack('Please try again. Make sure you use a Indian Phone Number and do not add the country code yourself.', 12000, null, 'error', true);
      }
      dispatch(setWallet());
      console.log('error signing up--> ', e);
    }
};

export const signOut =(cb=null) => async (dispatch)=> {
    Snack('Signing-out', 2000, Processing(), 'dark', false);

    const connector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      qrcodeModal: QRCodeModal,
    });

    Auth.signOut().then(()=>{
        dispatch(onSignOut());
        Cache.clear();
        if(connector.connected)connector.killSession();
        if(cb)cb();
    })
    
    setTimeout(() => { toast.dismiss()}, 1000); 
};

export const reSendCode =() => async (dispatch,getState) =>{
    const {userName}=getState().user.tempCreds;

    try {
      await Auth.resendSignUp(userName).then(() => {
        Snack('Success, Code resent successfully.', 6000, null, 'success', true);
      });
    } catch (err) {
      console.log('error resending code--> ', err);
    }
};

export const confirmAccount =(code,cb=null) =>async (dispatch,getState)=>{
    Snack('Connecting...', 1000, Processing(),'dark',false);
    const {userName,password}=getState().user.tempCreds;

    try {
        await Auth.confirmSignUp(userName,code).then(() => {
        Snack('Success, your account has been verified.', 6000, null, 'success', true);
        dispatch(signIn(userName,password,cb));
      });
    } catch (error) {
      console.log('error confirming sign up--> ', error);
      Snack('Ai. we have not been able to verify your phone number.', 6000, null, 'error', true);
    }
};