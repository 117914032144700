import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Tooltip } from "@material-ui/core";
import { setSelectedOption } from "../../../actions/members";

const FarmerDashboardCard = (props) => {
    const{item,type,soil,setSoil}=props;

    const dispatch = useDispatch();
    const{selectedOption}=useSelector(state=>state.members);

    const handleClick=(item)=>{
        if(selectedOption.id!==item.id) dispatch(setSelectedOption(item));
        else dispatch(setSelectedOption());
    };

    return(
        <Grid container className={`content ${selectedOption.id===item.id?'selected':''}`} key={item.id} onClick={()=>handleClick(item)}>
            <Grid style={{display:"flex",flex:"1 1"}}>
                <Typography className="icon">{item.icon}</Typography>
                <Tooltip key={item.id} title={<Typography variant='body1'>{item.tip}{item?.analysisDate}</Typography>} arrow>
                    <Grid style={{alignSelf:"center",marginLeft:"2.5rem"}}>
                        <Typography variant='body1' style={{fontWeight:item.bold?600:''}}>{item.name}</Typography>
                        {item?.showMore ? <Typography variant='body2' color='primary' onClick={(e)=>{e.stopPropagation();setSoil(!soil)}}>{!soil  ? 'show more' : 'show less'}</Typography> : null }
                    </Grid>
                </Tooltip>
            </Grid>
            <Grid style={{display:"flex",flex:"0.5 1",justifyContent:"end"}}>
                <Typography className={`value ${item.bold?'bold':''}`}>{typeof(item.value)==='number'?parseFloat(item.value).toFixed(4):item.value} {`${type==="soil"?`(${item.status})`:''}`}</Typography>
            </Grid>
        </Grid>
);
};

export default FarmerDashboardCard;