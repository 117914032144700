import React from 'react';
import {Backdrop,Modal,Grid,IconButton} from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

export const CustomModal = (props) => {
  const {open,onClose,className,children,style}=props;

  return (
    <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClick={(e)=>e.stopPropagation()}
        onClose={(e,reason)=>{reason!=='backdropClick' && onClose();e.stopPropagation();}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        style:{backgroundColor:'#E0E0E0',opacity:'0.5'}
      }}
    >
      <Grid container className={`custom-modal ${className?className:''}`}  style={style}>
        {children}
      </Grid>
    </Modal>
  );
};

export const CustomModalHeader = (props) => {
  const {className ,onClose,children,hasArrow,onArrow} = props;

  return(
    <Grid container className='modal-header'>
      <Grid container className={`icon-container ${!hasArrow?'has-arrow':''}`}>
        {hasArrow&&<IconButton id='btn' onClick={onArrow}><ArrowBackIcon /></IconButton>}
        <IconButton id='btn' onClick={onClose} ><CloseIcon/></IconButton>
      </Grid>
      <Grid container className={`header-info ${className ? className : ""}`}>{children}</Grid>
    </Grid>
  );
};

export const CustomModalBody = (props) => {
  const { children, className } = props;

  return (
    <Grid container className={`modal-body ${className ? className : ""}`}>
        {children}
    </Grid>
  );
};

export const CustomModalFooter = (props) => {
  const { children, className } = props;

  return (
    <Grid container className={`modal-footer ${className ? className : ""}`}>
      {children}
    </Grid>
  );
};

CustomModal.Header = CustomModalHeader;
CustomModal.Body = CustomModalBody;
CustomModal.Footer = CustomModalFooter;