import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import '../../assets/css/app.css';
import store from '../store';
import Layout from './layout/Layout';

const theme = createTheme({
  typography: {
    "fontFamily": `"Titillium Web", sans-serif`,
    "fontSize": 13,
    "fontWeightLight": 300,
    "fontWeightRegular": 500,
    "fontWeightMedium": 600,
    "fontWeightBold": 700,
    subtitle1:{
      fontSize:'1.05rem'
    }
  },
  palette: {
    primary: {
      main: '#EC3C93',
    },
    secondary: {
      main: '#CBDB2A',
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#EC3C93"
        }
      },
    },
    MuiStepIcon: {
      active: {
        color: '#eda394 !important'
      }
    },
    MuiSlider: {
      root: {
        color: '#EC3C93'
      }
    },
    MuiInput: {
      underline: {
        '&:after': { borderBottom: '2px solid #6d7278' },
        '&:before': { borderBottom: '1px solid #6d7278' },
        '&:hover':{ borderBottom:'1px solid #6d7278' }
      },
      input: {
        color: 'inherit',
      },
    },
  },
});

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
    <ToastContainer
      newestOnTop
      limit={1}
      hideProgressBar={true}
      pauseOnFocusLoss={false}
      closeButton={false}
    />
    <Layout/>
    </ThemeProvider>
  </Provider>
);

export default App;