import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { toggleTheme } from '../actions/user';
import { v4 as uuidv4 } from 'uuid';
import store from '../store';
import learn from '../../assets/images/newAccount.png'
import adapt from '../../assets/images/climateResistent.png'
import linkage from '../../assets/images/connectMarket.png'
import earn from '../../assets/images/earnCHT.png'
import offer from '../../assets/images/offerServices.png'

export const HEADER_OPTIONS=[
    // {id:1,label:'CARD',style:{padding:'0.86px 0'},link:'https://docs.chitta.network',auth:false, theme: false},
    {id:2,label:'INTELLIGENCE',style:{padding:'0.86px 0'},link:'https://intelligence.chitta.network',auth:true, theme: false},
    // {id:3,label:'MARKET',style:{padding:'0.86px 0'},auth:true, theme: false},
    // {id:4,label:'MUTUAL',style:{padding:'0.86px 0'},auth:true, theme: false},
    // {id:5,label:'CREDIT',style:{padding:'0.86px 0'},auth:true, theme: false},
    {id:6,label:'DOCS',style:{padding:'0.86px 0'},link:'https://docs.chitta.network',auth:true, theme: false},
    // {id:7,label:'DEVELOPERS',style:{padding:'0.86px 0'},auth:true,  theme: false},
    // {id:8,label:'CHT',style:{padding:'0.86px 0'},link:'https://docs.chitta.network',auth:false, theme: false},
    {id:9,label:<Brightness2Icon style={{fontSize:'1.15rem',fontWeight:600,transform:'rotate(135deg)'}}/>,theme: 'dark', auth:true, style:{padding:'4.8px 0'},onClick:()=>store.dispatch(toggleTheme())},
    {id:10,label:<WbSunnyIcon style={{fontSize:'1.15rem',fontWeight:600}}/>,theme: 'light', auth:true, style:{padding:'4.8px 0'},onClick:()=>store.dispatch(toggleTheme())}
];

// WALLETS
export const WALLET_OPTIONS=[
  {id:1,label:'AlgoSigner',icon:'k',clickable:false,subLabel:null,subLabelStyle:{color:'#ed3b94',cursor:'pointer',marginLeft:'0.5rem'}},
  {id:2,label:'Algorand Mobile Wallet',icon:'a',clickable:true,subLabel:' (walletconnect)',subLabelStyle:{fontWeight:'normal'}},
  {id:3,label:'Ledger Nano',icon:'b',clickable:false,style:{color:'#6d7278',cursor:'not-allowed'}},
  {id:4,label:'Trezor',icon:'c',clickable:false,style:{color:'#6d7278',cursor:'not-allowed'}},
  {id:5,label:'Manual',icon:'d',clickable:true,subLabel:' (UNSAFE)',subLabelStyle:{fontSize:'0.6rem',fontWeight:600}},
];

export const CARDS = [
  {id:1,link: 'https://docs.chitta.network', media: learn,title: 'Learn the Basics.', text: ' Find out how you can setup your business to connect farmers to finance and global markets.'},
  {id:2,link: false, media: earn,title: 'Grow the Chitta community.', text: ' Chitta airdrops tokens to those that register farmers. Tokens that can be used to supply credit, insurance and futures contracts.'},
  {id:3,link: false, media: offer,title: 'Earn and innovate.', text: ' As a local member, you earn the benefits of securely offering agri services to your members. Think of credit interest or insurance profits. However you also take the default or non-procurement risks.'},
  {id:4, link: false, media: linkage,title: 'Market linkage.', text: ' Chitta lets you automatically limit services to market conditions; e.g. performance insights of your cooperative compared to the overall market. Limit credit or insurance to in demand crops, early sales through futures contracts and automated administration to prepare your goods for export.'},
  {id:5, link: false, media: adapt,title: 'Adopt to climate change.', text: ' Farmers are front-row to experience the negative impacts of climate change. As a result, farmers need to rethink their business and adopt new regenerative strategies; improve soil health, use more resistant crop seeds, limit fertilizer use and find new offset markets.', paragraph2: ' The Chitta community is dedicated to help farmers make the change. Condition credit or insurance to knowledge building, use chitta labels to find new urban and global markets.'},
];

export const STATS = [
  {id:1, title: 'CHT price', value: '₹100', category: 'crop loans'},
  {id:2, title: 'registered fields', value: '105', category: 'crop loans'},
  {id:3, title: 'certified commodities', value: 5, category: 'crop loans'},
  {id:4, title: 'agri-protocols', value: 0, category: 'crop loans'}
];
  
export const ECOSYSTEM = [
  {id:1, date: 'Q1 2022', value: 'Chitta Credit', category: 'crop loans'},
  {id:2, date: 'Q3 2022', value: 'Chitta Mutual', category: 'crop insurance'},
  {id:3, date: 'get a grant', value: 'Futures', category: 'future trade'},
  {id:4, date: 'Q4 2022', value: 'Markets', category: 'spot trading'},
  {id:5, date: 'get a grant', value: 'CSA', category: 'brand loyalty'},
  {id:6, date: 'get a grant', value: 'In store', category: 'brand loyalty'},
  {id:7, date: 'Q1 2022', value: 'Chitta intelligence', category: 'data analytics'},
  {id:8, date: 'Q2 2022', value: 'Organic Credit', category: 'organic investment loans'},
  {id:9, date: 'get a grant', value: 'Carbon Storage', category: 'carbon credit offsetting'},
  {id:10, date: 'get a grant', value: 'Export', category: 'Export linkage and certs'},
];

export const  ALGO_SIGNER=1;
export const  ALGORAND_MOBILE=2;
export const  LEDGER_NANO=3;
export const  TREZOR=4;
export const  MANUAL=5;
export const  REDIRECT_TO_MANUAL=6;

// ASSETS THAT CHITTA INTERACTS WITH
export const ASSETS=[
  { id: 10458941, name: 'USDC', type: 'asset'},
  { id: 38909410, name: 'CHT', type: 'asset'},
  { id: 22181339, name: 'banana', type: 'asset'},
  { id: 22179923, name: 'sugarcane', type: 'asset'},
  { id: 22180015, name: 'millets', type: 'asset'},
  { id: 14038534, name: 'Paddy', type: 'asset'},
  { id: 14038537, name: 'groundnut', type: 'asset'},
  //{ id: 48715057, name: 'paddy', type: 'asset'},
];

export const CROP_COLORS={
  14038534:'#CBDB29',
  //48715057:'#CBDB29',
  14038537:'#FCAC87',
  22179923:'#eeefdf',
  22180015:'#F6DBAC',
  22181339:'#FFE135',
  0:'lightgrey',
};

export const ASSETS_BY_ID={
  10458941:"USDC",
  38909410:"CHT",
  22181339:"Banana",
  22179923:"Sugarcane",
  22180015:"Millets",
  14038534:"Paddy",
  //48715057:"Paddy",
  14038537:"Groundnut",
  0:"unknown"
};

// APPS (SMART CONTRACTS) THAT CHITTA INTERACTS WITH
export const APPS=[
  { id: 14098863, name: 'with the futures exchange', type: 'app'},
];

// WALLET AMOUNTS
export const SIGN_IN_AMOUNT=0;

export const LANGUAGES=[
  {id:1,name:'English'},
  {id:2,name:'தமிழ் (Tamil)'},
  {id:3,name:'हिन्दी (Hindi)'},
  {id:4,name:'ಕನ್ನಡ (Kannadam)'},
  {id:5,name:'తెలుగు (Telugu)'}
];

//GRIDS
const for2Aand2B=(5*24*60*60*1000);
const for1A=(12*24*60*60*1000);

export const GRID_LIST = [
  {type:'SENTINEL-1A-RADAR',label:'S1A',grid:'44PLU',dt:1621463525.0,condition:for1A},
  {type:'SENTINEL-1A-RADAR',label:'S1A',grid:'43PGN',dt:1633308044.0,condition:for1A},
  {type:'SENTINEL-1A-RADAR',label:'S1A',grid:'43PGK',dt:1632875607.0,condition:for1A},
  {type:'SENTINEL-1A-RADAR',label:'S1A',grid:'44PKU',dt:1632868357.0,condition:for1A},
  {type:'SENTINEL-2B-OPTICAL',label:'S2B',grid:'44PLU',dt:1612675523.0,condition:for2Aand2B},
  {type:'SENTINEL-2B-OPTICAL',label:'S2B',grid:'44PKU',dt:1620451526.0,condition:for2Aand2B},
  {type:'SENTINEL-2B-OPTICAL',label:'S2B',grid:'43PGN',dt:1632547547.0,condition:for2Aand2B},
  {type:'SENTINEL-2B-OPTICAL',label:'S2B',grid:'43PGK',dt:1632547590.0,condition:for2Aand2B},
  {type:'SENTINEL-2A-OPTICAL',label:'S2A',grid:'44PLU',dt:1612243524.0,condition:for2Aand2B},
  {type:'SENTINEL-2A-OPTICAL',label:'S2A',grid:'44PKU',dt:1606799735.0,condition:for2Aand2B},
  {type:'SENTINEL-2A-OPTICAL',label:'S2A',grid:'43PGN',dt:1632979553.0,condition:for2Aand2B},
  {type:'SENTINEL-2A-OPTICAL',label:'S2A',grid:'43PGK',dt:1632979597.0,condition:for2Aand2B}
];

//SEARCH CATEGORIES.
// export const SEARCH_CATEGORIES=[
//   {id:uuidv4(),category:"farmers",subCategories:["farmer name"]},
//   {id:uuidv4(),category:"fields",subCategories:["field name"]},
//   {id:uuidv4(),category:"field activity",subCategories:["crop","stage"]},
//   {id:uuidv4(),category:"field status",subCategories:["water health","crop health"]},
//   {id:uuidv4(),category:"soil status",subCategories:["erosion","nutrients","organic carbon","slainity"]}
// ];

export const SEARCH_CATEGORIES=[
  {id:uuidv4(),category:"farmers",subCategories:["farmer name"]},
  {id:uuidv4(),category:"fields",subCategories:["field name"]},
  {id:uuidv4(),category:"field activity",subCategories:["crop"]}
];

export const SUGGESTIONS={
  'field activity_crop':['paddy','banana','sugarcane','groundnut','millets'],
};

//SEQUEL COLORS
export const SEQUEL_COLORS={
  'PH':{ high: "hsl(30, 100%, 50%)", low: "hsl(0, 100%, 50%)" },
  'Water storage':{ high: "hsl(195, 100%, 50%)", low: "hsl(180, 100%, 50%)" },
  'Irrigation':{ high: "hsl(225, 100%, 50%)", low: "hsl(210, 100%, 50%)" },
  'No-Till':{ high: "hsl(60, 100%, 30%)", low: "hsl(45, 100%, 30%)" },
  'Clay Mineral Content':{ high: "hsl(45, 100%, 10%)", low: "hsl(15, 100%, 10%)" },
  'Soil Crust':{ high: "hsl(15, 100%, 30%)", low: "hsl(30, 100%, 30%)" },
  'Salinity':{ high: "hsl(230, 100%, 50%)", low: "hsl(260, 100%, 50%)" },
  'Erosion':{ high: "hsl(60, 100%, 30%)", low: "hsl(75, 100%, 30%)" },
  'Soil Organic Carbon':{ high: "hsl(60, 100%, 20%)", low: "hsl(30, 100%, 20%)" },
  'Potassium (K)':{ high: "hsl(255, 100%, 70%)", low: "hsl(270, 100%, 70%)" },
  'Phosporous (P)':{ high: "hsl(15, 100%, 35%)", low: "hsl(30, 100%, 35%)" },
  'Nitrogen (N)':{ high: "hsl(270, 60%, 50%)", low: "hsl(285, 60%, 50%)" },
  'N:P ratio':{ high: "hsl(300, 100%, 40%)", low: "hsl(330, 100%, 40%)" },
  'Pest control':{ high: "hsl(135, 100%, 55%)", low: "hsl(160, 100%, 55%)" },
  'Green Manure':{ high: "hsl(120, 100%, 35%)", low: "hsl(150, 100%, 35%)" },
  'Animal Manure':{ high: "hsl(45, 100%, 40%)", low: "hsl(60, 100%, 40%)" },
  'Chemical Manure':{ high: "hsl(345, 100%, 50%)", low: "hsl(330, 100%, 50%)" },
  'Crop rotation':{ high: "hsl(60, 100%, 35%)", low: "hsl(75, 100%, 35%)" },
  'Cover crops':{ high: "hsl(105, 100%, 60%)", low: "hsl(75, 100%, 60%)" },
  'Buffer strips':{ high: "hsl(105, 100%, 25%)", low: "hsl(75, 100%, 25%)" },
  'Seasons':{ high: "hsl(40, 100%, 55%)", low: "hsl(60, 100%, 55%)" },
  'Expertise':{ high: "hsl(285, 100%, 25%)", low: "hsl(300, 100%, 25%)" },
  'Market alignment':{ high: "hsl(0, 100%, 20%)", low: "hsl(330, 100%, 20%)" },
  'Leased':{ high: "hsl(240, 100%, 25%)", low: "hsl(195, 100%, 25%)" },
  'Soil Management Score':{ low: "hsl(30, 100%, 45%)", high: "hsl(0, 100%, 45%)" }
}
//SEQUENTIALS
export const PH={label:'PH',colors:SEQUEL_COLORS['PH']};
export const WATER_STORAGE={label:'Water storage',colors:SEQUEL_COLORS['Water storage'] };
export const IRRIGATION={label:'Irrigation',colors:SEQUEL_COLORS['Irrigation'] };
export const NO_TILL={label:'No-Till',colors:SEQUEL_COLORS['No-Till'] };
export const CLAY_MINERAL_CONTENT={label:'Clay Mineral Content',colors:SEQUEL_COLORS['Clay Mineral Content'] };
export const SOIL_CRUST={label:'Soil Crust',colors:SEQUEL_COLORS['Soil Crust'] };
export const SALINITY={label:'Salinity',colors:SEQUEL_COLORS['Salinity'] };
export const EROSION={label:'Erosion',colors:SEQUEL_COLORS['Erosion'] };
export const SOIL_ORGANIC_CARBON={label:'Soil Organic Carbon',colors:SEQUEL_COLORS['Soil Organic Carbon'] };
export const POTASSIUM={label:'Potassium (K)',colors:SEQUEL_COLORS['Potassium (K)'] };
export const PHOSPOROUS={label:'Phosporous (P)',colors:SEQUEL_COLORS['Phosporous (P)'] };
export const NITROGEN={label:'Nitrogen (N)',colors:SEQUEL_COLORS['Nitrogen (N)'] };
export const N_P_RATIO={label:'N:P ratio',colors:SEQUEL_COLORS['N:P ratio'] };
export const PEST_CONTROL={label:'Pest control',colors:SEQUEL_COLORS['Pest control'] };
export const GREEN_MANURE={label:'Green Manure',colors:SEQUEL_COLORS['Green Manure'] };
export const ANIMAL_MANURE={label:'Animal Manure',colors:SEQUEL_COLORS['Animal Manure'] };
export const CHEMICAL_MANURE={label:'Chemical Manure',colors:SEQUEL_COLORS['Chemical Manure'] };
export const CROP_ROTATION={label:'Crop rotation',colors:SEQUEL_COLORS['Crop rotation'] };
export const COVER_CROPS={label:'Cover crops',colors:SEQUEL_COLORS['Cover crops'] };
export const BUFFER_STRIPS={label:'Buffer strips',colors:SEQUEL_COLORS['Buffer strips'] };
export const SEASONS={label:'Seasons',colors:SEQUEL_COLORS['Seasons'] };
export const EXPERTISE={label:'Expertise',colors:SEQUEL_COLORS['Expertise'] };
export const MARKET_ALIGNMENT={label:'Market alignment',colors:SEQUEL_COLORS['Market alignment'] };
export const LEASED={label:'Leased',colors:SEQUEL_COLORS['Leased'] };
export const SOIL_MANAGEMENT_SCORE={label:'Soil Management Score',colors:SEQUEL_COLORS['Soil Management Score'] };