import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';

const GetAssets = (D,d) => {
    let NC = d['asset-id']
    let byAsset = { name: NC, value: d.amount }
    return !d['is-frozen'] && NC !== 'none' ? [...D, byAsset] : [...D]
};

const Balance=(props)=> {
  const Assets = props.wallet.assets.reduce(GetAssets, [{ name: 0, value: 1 }]);
  const GetTotal = (D, d) => D + d.value;
  const total = Assets.reduce(GetTotal, 0);

  return (
    <ResponsiveContainer width="100%" height={150}>
      <PieChart >
         <Pie
              data={Assets}
              animationDuration={750}
              animationEasing='ease-in'
              cx="50%"
              cy="50%"
              innerRadius={45}
              outerRadius={65}
              fill='#eb3c93'
              dataKey="value" > 
          </Pie> 
          <text  x="50%" y="50%" fontSize={10} dy={-10} textAnchor="middle" opacity={Number.isFinite() ? 0 : 1} id='pie-text'>total value</text>
          <text x="50%" y="50%" fontSize={14} dy={10} textAnchor="middle" opacity={Number.isFinite() ? 0 : 1} id='pie-text'>{((total * 50) - 50).toLocaleString('en-IN', {
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'})}
          </text>
      </PieChart>
    </ResponsiveContainer>
    )
};

export default Balance;