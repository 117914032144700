import React,{useState} from 'react';
import {Grid, IconButton, Typography, Tooltip } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector,useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import FarmersList from './content/FarmersList';
import FieldsList from './content/FieldsList';
import FieldPopup from '../poppers/FieldPopup';
import MemberForm from '../forms/MemberForm';
import '../../../assets/scss/side-bar.scss';
import '../../../assets/scss/farmers.scss';
import SearchBar from './content/SearchBar';
import { setSearchCategory,setSearchText } from '../../actions/members';

const SideBar = (props) => {
    const { sidebarCollapse, setSidebarCollapse,drawPolygon,setDrawPolygon,setIsEditable,
    isEditable,setGetStart,openSearchBar,setOpenSearchBar} = props;

    const selectedMember = useSelector(state => state.members.selectedMember);
    const dispatch=useDispatch();

    const[register,setRegister]=useState(false);
    const[fieldEl,setFieldEl]=useState(null);
    
    const fieldDrop = Boolean(fieldEl);
    const fieldDropId = fieldDrop ? 'field-drop' : undefined;

    const handleFieldEl = (event) => {
        setFieldEl(fieldEl ? null : event.currentTarget);
    };

    return (
        <Grid container className='side-bar'>
            <Grid container className='side-bar-header'>
                <Grid item className='menu-container'>
                    <Tooltip title='collapse list' arrow><IconButton onClick={()=>{setSidebarCollapse(!sidebarCollapse)}}><ListIcon id='menu-icon'/></IconButton></Tooltip>
                    <Typography variant='body2' style={{marginLeft:'1.15rem'}}>{!selectedMember?'FARMS':'FIELDS'}</Typography>
                </Grid>
                <Grid item style={{marginRight:!selectedMember?'1.6rem':'1rem'}}>
                    <IconButton aria-describedby={fieldDropId} onClick={(e)=>!selectedMember?setRegister(true):handleFieldEl(e)} style={{marginRight:'1.1rem'}}>
                        <Tooltip title='add farms' arrow>
                            <AddIcon id='add-icon'/>
                        </Tooltip>
                            {fieldEl?
                            <FieldPopup 
                            id={fieldDropId} 
                            open={fieldDrop} 
                            fieldEl={fieldEl}
                            setSidebarCollapse={setSidebarCollapse} 
                            setDrawPolygon={setDrawPolygon}
                            setRegister={setRegister}
                            setIsEditable={setIsEditable}
                            setFieldEl={setFieldEl}/>:null}
                        </IconButton>
                    <IconButton 
                    title={!openSearchBar?'open search-bar':'close search-bar'}
                    onClick={()=>{
                        setOpenSearchBar(!openSearchBar);
                        if(openSearchBar){dispatch(setSearchCategory());dispatch(setSearchText());}
                    }}>
                        {!openSearchBar?<SearchIcon id='search-icon'/>:<CloseIcon id='search-icon'/>}
                    </IconButton>
                </Grid>
            </Grid>
            {openSearchBar && <SearchBar/>}
            <Grid container className={`side-bar-body ${openSearchBar?'has-search':''}`}>
                {!selectedMember ? <FarmersList setGetStart={setGetStart}/> : null}
                {selectedMember ? 
                <FieldsList 
                isEditable={isEditable}
                setIsEditable={setIsEditable} 
                drawPolygon={drawPolygon}
                setDrawPolygon={setDrawPolygon}
                setGetStart={setGetStart}/> : null}
            </Grid>
            {register&&<MemberForm open={true} onClose={()=>setRegister(false)}/>}
        </Grid>
    );
};

export default SideBar;