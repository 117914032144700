import React from 'react';
import mapboxgl from 'mapbox-gl';
import ReactMapGL, {Source ,Layer} from 'react-map-gl';
import {fillLayer,textLayer,lineLayer,cropLayer,selectedLayer,selectedLineLayer,selectedTextLayer,unverfiedLayer,
    clusterLayer,clusterCountLayer,unclusteredPointLayer,unclusteredCountLayer,sequentialLayer,selectedCropLayer} from './Layers';
import config from '../../constants/config';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const FieldMap = (props) => {
    const {viewport,setViewport,polygons,handleClick,polygonsPoints}=props;

    const interactiveLayerIds=['0','1','2','3','4','5','6','7','8','9','10','11','12','13'];

    return (
        <ReactMapGL
            {...viewport}
            width={'inherit'}
            height={'inherit'}
            mapStyle={config.map.MAP_STYLE_NORMAL}
            onViewportChange={nextViewport => setViewport(nextViewport)}
            interactiveLayerIds={interactiveLayerIds}
            localIdeographFontFamily= '"InaiMathi", "Tamil Sangam MN", "Nirmala UI", Latha, Bamini ,Roboto, Noto, "Noto Sans Tamil", sans-serif'
            onClick={(event) => {
                const field =  event.features && event.features[0]?.properties
                const param={
                    fieldId:field?.id ? field.id : null,
                    farmerId:field?.farmerId ? field.farmerId : null
                };
                handleClick(param);}
            }
            mapboxApiAccessToken={config.map.MAP_KEY}
        >  
            <Source type="geojson" data={polygons}>
                <Layer {...fillLayer} style={{width: '100%', height: '100%'}} />
                <Layer {...selectedCropLayer} style={{width: '100%', height: '100%'}} filter={["!=", "selectedCrop",'default']}/>
                <Layer {...unverfiedLayer} style={{width: '100%', height: '100%'}} filter={["in", "verified", false]}/>
                <Layer {...cropLayer} style={{width: '100%', height: '100%'}} filter={['!=','currentCrop','default']}/>
                <Layer {...sequentialLayer} style={{width: '100%', height: '100%'}} filter={["!=", "seqColor","default"]}/>
                <Layer {...lineLayer} style={{width: '100%', height: '100%'}} />
                <Layer {...textLayer} style={{width: '100%', height: '100%'}} />
                <Layer {...selectedLayer} style={{width: '100%', height: '100%'}} filter={["in", "Highlighted", true]}/>
                <Layer {...selectedLineLayer} style={{width: '100%', height: '100%'}} filter={["in", "Highlighted", true]}/>
                <Layer {...selectedTextLayer} style={{width: '100%', height: '100%'}} filter={["in", "Highlighted", true]}/>
            </Source> 
            <Source type="geojson" data={polygonsPoints} cluster={true} clusterMaxZoom={14} clusterRadius={50}>
                <Layer {...clusterLayer} style={{width: '100%', height: '100%'}}/>
                <Layer {...clusterCountLayer} style={{width: '100%', height: '100%'}}/>
                <Layer {...unclusteredPointLayer} style={{width: '100%', height: '100%'}}/>
                <Layer {...unclusteredCountLayer} style={{width: '100%', height: '100%'}}/>
            </Source>
        </ReactMapGL>
    );
};

export default FieldMap;