import React from 'react';
import { Grid} from '@material-ui/core';
import { useSelector } from 'react-redux';
import FieldCard from './FieldCard';
import {LinearLoader} from '../../widgets/Loader';
import { searchWord } from '../../../helpers';
import { ASSETS_BY_ID } from '../../../constants';

const FieldsList = (props) => {
    const {isEditable,drawPolygon,setGetStart}=props;
    
    const {fieldsList:{isRequesting,fieldByFarmerId,notesList},searchCategory,searchText,selectedMember} = useSelector(state => state.members);
    let searchedFieldsList=fieldByFarmerId[selectedMember];

    if(searchedFieldsList&&searchCategory&&searchText.length>0){
        if(searchCategory.category==='fields'&&searchCategory.subCategory==='field name')
            searchedFieldsList=searchedFieldsList.filter(e => searchWord(e.fieldName.trim(), searchText));
        if(searchCategory.category==='field activity'&&searchCategory.subCategory==='crop'){
            let filteredFieldIds=notesList.filter(e => searchWord(ASSETS_BY_ID[e.crop].trim().toLowerCase(), searchText)).map(e1=>e1.fieldId);
            searchedFieldsList=searchedFieldsList.filter(e =>filteredFieldIds.includes(e.id));
        };
    };

    return (
        <Grid className='field-list'>
            {isRequesting && <LinearLoader/>}
            {(searchedFieldsList&&searchedFieldsList.length)?searchedFieldsList.map(field => <FieldCard key={field.id} field={field} isEditable={isEditable} drawPolygon={drawPolygon} setGetStart={setGetStart}/>)
            :<Grid container id='no-field'>
                {!searchedFieldsList?'Please click + to add a field.':`No field found${(searchCategory.category==='field activity'&&searchCategory.subCategory==='crop'&&searchText.length>0)?` with active ${searchText}.`:'.'}`}
            </Grid>}
        </Grid>
    );
};

export default FieldsList;
