import React, { Fragment, useState} from 'react';
import { Grid, Button, Link, IconButton } from '@material-ui/core';
import {useSelector,useDispatch } from 'react-redux';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ListIcon from '@mui/icons-material/List';
import Logo from './Logo';
import { HEADER_OPTIONS } from '../../../constants';
import Connected from './Connected';
import DisConnected from './DisConnected';
import MenuPopup from './MenuPopup';
import { toggleTheme } from '../../../actions/user';


const Header = (props) => {
    const{show,setShow}=props;
    
    const connected = useSelector(state => state.user.userInfo.connected);
    const theme  = useSelector(state=>state.user.theme);
    const [menuEl, setMenuEl]=useState(null);
    const dispatch=useDispatch();
    const headerRoutes = connected ? HEADER_OPTIONS.filter(op => op.auth) : HEADER_OPTIONS;

    const handleMenu = (event) =>{
        setMenuEl(menuEl ? null : event.currentTarget);
    };

    return (
        <Grid container className={`header ${theme}`}>
            <Grid xs={9} sm={5} md={3} item style={{alignSelf:'center'}}><Logo /></Grid>
            <Grid xs={1} item className='header-menu'>
                <IconButton onClick={(e)=>{handleMenu(e)}}>
                  <>  
                    <ListIcon id='menu-icon'/>
                    {menuEl && <MenuPopup menuEl={menuEl} setMenuEl={setMenuEl}/>}
                  </>
                </IconButton>
            </Grid>
            <Grid xs={2} item className='header-menu'>
                <IconButton onClick={(e)=>{e.stopPropagation();dispatch(toggleTheme())}} style={{padding:'0.5625rem'}}>
                    {theme==='light'?<Brightness2Icon id='moon'/>:<WbSunnyIcon id='sunny'/>}
                </IconButton>
            </Grid>
            <Grid sm={4} md={7} item style={{ display: 'flex',padding:'0 1rem' }}>
            <Grid container className={`header-routes ${theme} ${connected?'connected':''} `}>
                    {headerRoutes.map(option=>(
                    <Fragment key={option.id}>{ option.theme === theme ? null :
                        <Button style={{...option.style}} onClick={option.onClick}>
                            {option.link ? <Link href={option.link} target="_blank" color="inherit" >{option.label}</Link> :<>{option.label}</>}
                        </Button>}
                    </Fragment>))}
                </Grid>
            </Grid>
            <Grid sm={3} md={2} item>
                <Grid container className='header-wallet'>
                    {connected ? <Connected/> : 
                    <Button className='wallet-oval-label' onClick={()=>setShow(true)}>
                        <span style={{margin:'0 1rem'}}>Connect</span>
                    </Button>}
                </Grid>
            </Grid>
            {show && <DisConnected setShow={setShow}/>}
        </Grid>
    );
};

export default Header;