
import { Link } from '@material-ui/core';
import { Cache } from 'aws-amplify';
import config from '../constants/config';
import {Snack} from '../components/widgets/Toast';
import {ALGORAND_MOBILE, ALGO_SIGNER } from '../constants';
import moment from "moment";

const testnetServer = "https://testnet-api.algonode.cloud/";
const indexerServer = "https://testnet-idx.algonode.cloud/";

const sdk = require('algosdk');

const token = ''

const port = '';

export let indexerClient = new sdk.Indexer(token, indexerServer, port);
export let algodClient = new sdk.Algodv2(token, testnetServer, port);

export const getAccountInformation = async(address) => {
  return await algodClient.accountInformation(address).do();
};

export const mnemonicToSecretKey = (mnemonic) => {
  return sdk.mnemonicToSecretKey(mnemonic);
};

export const generateAccount = () => {
  return sdk.generateAccount();
};

export const generateMnemonic = (account) => {
  return sdk.secretKeyToMnemonic(account.sk);
};

export const generatePassword=(blob)=>{
  const signedTx=blob.split('/');
  return signedTx[signedTx.length-1];
};

export const isAlgoSignerAvailable=()=>{
  let algoSigner=window.AlgoSigner;
  if(algoSigner) return true;
  else return false;
};

export const getTestNetAccounts=(cb=null)=>{
  let algoSigner=window.AlgoSigner;
  // find out how many accounts are there
  if (algoSigner){
    algoSigner.accounts({ ledger: 'TestNet' }).then(res=>cb(res));
  }
  else cb([]);
};

export const downloadAlgoSigner=()=>(
<Link href={config.links.ALGO_DWD_LINK}  style={{textDecoration:'none'}}>download</Link>
);

export const discoverCropSeason=(SOS)=>{
  return parseInt(SOS.substring(5,7)) > 4 && parseInt(SOS.substring(5,7)) < 9 ? 'Kharif' : 'Rabi'
};


export const getNotesFromIndexer = async(PK,membersList) => {
  let members = membersList;
  let response = await indexerClient.searchForTransactions().address(PK).do();

  // filter transactions on version, then decode note. 
  const seasons = response['transactions'].reduce((acc, tx) => {
    let version = Buffer.from('{"chitta": "V1.0.5"','binary').toString('base64');
    if( tx.hasOwnProperty('note') && tx['note'].startsWith(version.substring(0,25))){
       // only use the first 26 char of the bytes note
      tx['note'] = JSON.parse(Buffer.from(tx['note'], 'base64').toString('utf-8'));
      let sn_id = tx['note']['id'];
      if (!sn_id){}
      else if(!acc[sn_id]){
        acc[sn_id] = [];
        acc[sn_id].push(tx);
      }
      else {
        // if note is 0note, put at the end, 
        if (!tx['asset-transfer-transaction']) acc[sn_id].push(tx);
        // if note is a assetTX, newer then the first note in the list or that note is not a assetTX, put at first position.
        else if (tx['confirmed-round'] > acc[sn_id][0]['confirmed-round'] || !acc[sn_id][0]['asset-transfer-transaction']) acc[sn_id].unshift(tx);
        // if note is assetTx but not newer, push to the end.
        else acc[sn_id].push(tx);
      }
    };

    return acc;
  }, []);

  // spread seasons to individual fields in the members 
  members.map(mem=>{
    // add entire season to members
    let allnotes = [];
    if(mem.fields.items.length){
      mem.fields.items.forEach(field=>{
        let notes = [];
        let assessments = [];
        Object.entries(seasons).forEach(([key, v], index) => {
          // filter v for nonSSN, then merge note props to single object, then append that to newest (i=0) SSN
          let p = v.reduce((a,n) => { 
            return !n['asset-transfer-transaction'] ? {...a, ...n['note'] } : { ...a}
          }, {});
          
          // take fields in the first element!!
          let ids = p.hasOwnProperty('fields') ? p['fields'] : v[0]['note']['fields'];
          if(ids.includes(field.id) && v[0]['note']['type'] == 'SSN'){
            // create a note element from data in the seasons object
            let att = v[0]['asset-transfer-transaction'];
            let AssetId = v[0].hasOwnProperty('asset-transfer-transaction') ? att['asset-id'] : 0;
            let Amount = v[0].hasOwnProperty('asset-transfer-transaction') ? att['amount'] : 0;
            let note = {
              'crop':AssetId,
              'yield':Amount,
              'EOS': p.hasOwnProperty('EOS') ? p['EOS'] : v[0]['note']['EOS'],
              'SOS': p.hasOwnProperty('EOS') ? p['SOS'] : v[0]['note']['SOS'],
              'id': v[0]['note']['id'],
              'guess': v[0]['note']['guess'],
              'issues':v[0]['note']['issues'],
              'area': v[0]['note']['area'],
              'size': parseFloat(field['areaSize']),
              'variety': p.hasOwnProperty('variety') ? p['variety'] : v[0]['note']['variety'],
              'active':p.hasOwnProperty('EOS')?(p['EOS']>moment().format('YYYY-MM-DD')):(v[0]['note']['EOS']>moment().format('YYYY-MM-DD')),
              // 'active':  p.hasOwnProperty('EOS') ? Date.parse(p['EOS']) > Date.now() : Date.parse(v[0]['note']['EOS']) > Date.now(),
              'season' : p.hasOwnProperty('SOS') ? discoverCropSeason(p['SOS']) : discoverCropSeason(v[0]['note']['SOS']) 
            };
            allnotes.push(note);
            notes.push(note);
          }else if(ids.includes(field.id) && v[0]['note']['type'] == 'ASSESS'){
            let assess = {
              'id': v[0]['note']['id'],
              'assessment_round': v[0]['confirmed-round'],
              'SOC': v[0]['note']['SOC'],
              'clay': v[0]['note']['clay'],
              'crust': v[0]['note']['crust'],
              'erosion': v[0]['note']['erosion'],
              'ws': v[0]['note']['ws'],
              'irr': v[0]['note']['irr'],
              'organic': v[0]['note']['organic'],
              'ph': v[0]['note']['ph'],
              'salt': v[0]['note']['salt'],
              'tillage': v[0]['note']['tillage'],
            };
            assessments.push(assess);
          };
          field['notes'] = notes.sort((a,b) => (a.SOS < b.SOS) ? 1 : ((b.SOS < a.SOS) ? -1 : 0));
          field['assess'] = assessments.sort((a,b) => (a.assessment_round < b.assessment_round) ? 1 : ((b.assessment_round < a.assessment_round) ? -1 : 0));
        });
      });
    };
  });

  return members;
};

export const prepareTxs = (newMembers, from) => {
  let params = { fee: 0, firstRound: 1, lastRound: 1, genesisID: "testnet-v1.0" , genesisHash: "SGO1GKSzyE7IEPItTxCByw9x8FmnrCDexi9/cOUJOiI=" };
  let to = config.wallet.TO_TX_ID; 

  const txns = newMembers.map((member)=>{
    const enc = new TextEncoder();
    const note = enc.encode(member.phoneNumber);
    const txn = sdk.makePaymentTxnWithSuggestedParamsFromObject({from, to, amount : 0, note, suggestedParams: {...params}});

    return txn;
  });

  if(txns.length>1)sdk.assignGroupID(txns);
  const toSign=txns.map(tx=>{
    return{
      'txn': Buffer.from(sdk.encodeUnsignedTransaction(tx)).toString("base64")
    }
  });
  
  return toSign;
};

export const signedTx= async(from,to,amount)=>{
  console.log('hey im signing', algodClient)
  console.log(await algodClient.status().do());
  let params = await algodClient.getTransactionParams().do();
  console.log('params', params)
  const enc = new TextEncoder();
  const note = enc.encode("SignIn-Payment");

  const txn = sdk.makePaymentTxnWithSuggestedParamsFromObject({
    from,
    to,
    amount,
    note,
    suggestedParams: {...params}
  });
  console.log('txn', txn)
  return txn;
};

export const txManualSign= async(mnemonic, address, amount) => {
  let skey = sdk.mnemonicToSecretKey(mnemonic);
  
  try {   
    let txn =await signedTx(address,config.wallet.TO_TX_ID,amount);
    let rawSignedTxn=sdk.signTransaction(txn,skey.sk)
    const base64Tx=Buffer.from(rawSignedTxn.blob).toString('base64');
    let password=generatePassword(base64Tx);

    return password;
  }catch (e) { console.log('Manual sign error -->',e);}
};

export const prepareAssetTxs = async(from,to,amount,optIn) =>{
  let algoSigner=window.AlgoSigner;
  const mode = Cache.getItem('loginMode');

  let toGroup = [];
  let params = await algodClient.getTransactionParams().do();
  optIn.forEach(async(asset) => {
    const enc = new TextEncoder();
    const note = enc.encode("OptIn");
    const assetIndex = asset.id;
    let txn;
    if(asset.type === 'asset'){
      txn = sdk.makeAssetTransferTxnWithSuggestedParamsFromObject({from,to,assetIndex,note,amount,suggestedParams: {...params}});
    }else{
      txn = sdk.makeApplicationOptInTxnFromObject({suggestedParams:{...params},from,appIndex:assetIndex,note});
    };
    toGroup.push(txn);
  });

  sdk.assignGroupID(toGroup);
  let toSign =[];
  switch(mode){
    case ALGO_SIGNER:{
      toSign=toGroup.map(tx=>{
        return{
          'txn':algoSigner.encoding.msgpackToBase64(tx.toByte())
        }
      });
      break;
    }
    case ALGORAND_MOBILE:{
      toSign=toGroup.map(tx=>{
        return{
          'txn': Buffer.from(sdk.encodeUnsignedTransaction(tx)).toString("base64")
        }
      });
      break;
    }
    default:
      break;
  };

  return toSign;
};

export const txnOptin = async(from, to, amount, optin) => {
  let algoSigner = window.AlgoSigner;
  const mode = Cache.getItem('loginMode');

  return new Promise((resolve, reject) => {
    try {   
      if(optin.length){
        const opt = prepareAssetTxs(from,to,amount,optin).then((toSign)=>{
          switch(mode){
            case ALGO_SIGNER:{
              let signedTxn = algoSigner.signTxn(toSign);
              return resolve(signedTxn);
            }
            case ALGORAND_MOBILE:{
              return resolve(toSign);
            }
          };
        });
        
        return opt;
      };
    }catch (e) {
      console.log('Signing Optin Txn Error--->',e);
    };
  }).catch((e) => {
    if(e.message.includes('Validation failed')){
      Snack('No matching account found! Add this account to your wallet.',4000,null,'error',true);
    };
  });
};