import {Cache } from 'aws-amplify';
import { ASSETS_BY_ID } from '../constants';
import { REQUESTING_LOG_IN,ON_LOGIN,ON_SIGN_OUT,SET_WALLET,SET_CONNECT_METHOD,SET_REWARD,
    SET_USER_CREDS,CLEAR_USER_CREDS,SET_THEME,SET_MESSAGE_COUNT,SET_MESSAGE,SET_ASSETS,SET_ACCOUNTS, SET_OVERLAY} from "../constants/action-types";

const initialState={
    theme:'light',
    messageCount:0,
    userInfo:{
        isRequesting:false,
        connectMethod:0,
        connected:false,
        wallet:{},
        session:{},
    },
    tempCreds:{
        userName:'',
        password:''
    },
    message:{printMsg:false,inviteMsg:false,count:0},
    accounts:[],
    overlay: {
        show: false,
        text: "Loading"
    }
};

export default (state = initialState, action) =>{
    switch(action.type){
        case REQUESTING_LOG_IN:{
            const payload=action.payload?action.payload:false;

            state={
                ...state,
                userInfo:{
                    ...state.userInfo,
                    isRequesting:payload
                }
            };
            break;
        }
        case  ON_LOGIN :{
            const payload=action.payload?action.payload:{};
            console.log('payload', payload)
            state={
                ...state,
                userInfo:{
                    ...state.userInfo,
                    isRequesting:false,
                    ...payload
                },
                tempCreds:initialState.tempCreds
            };
            break;
        }
        case SET_WALLET:{
            const payload=action.payload?action.payload:{};

            state={...state,userInfo:{...state.userInfo,wallet:payload}};
            break;
        }
        case SET_ACCOUNTS:{
          if(!action.payload)return;

          state={...state,accounts:action.payload};
          break;
        }
        case SET_OVERLAY:{
            const payload=action.payload?action.payload:initialState.overlay;

            state={...state,overlay:payload};
            break;
        }
        case SET_REWARD:{
            const payload=action.payload?action.payload:0;
            
            let assets=[...state.userInfo.wallet.assets];
            const CHTIndex=assets.findIndex(asset=>ASSETS_BY_ID[asset['asset-id']]==='CHT');
            assets[CHTIndex]={
                ...assets[CHTIndex],
                amount:assets[CHTIndex].amount + payload
            };

            state={
                ...state,
                userInfo:{
                    ...state.userInfo,
                    wallet:{
                        ...state.userInfo.wallet,
                        assets
                    }}};
            break;
        }
        case SET_ASSETS:{
            const assets = state.userInfo.session.optin.map((ast)=>{
                let asset = {amount:0}
                asset['asset-id']=ast.id;
                return asset;
            });

            state={
                ...state,
                userInfo:{
                    ...state.userInfo,
                     wallet:{
                        ...state.userInfo.wallet,
                        assets
                    },
                    session:{
                        ...state.userInfo.session,
                        optin:[]
                    }
                },
            };
            break;
        }
        case SET_THEME:{
            const payload=action.payload?action.payload:initialState.theme;
            Cache.setItem('theme',payload);

            state={...state,theme:payload};
            break;
        }
        case SET_MESSAGE_COUNT:{
            const payload=action.payload?action.payload:initialState.messageCount;

            state={...state,messageCount:payload};
            break;
        }
        case SET_MESSAGE:{
            if(!action.payload.type)return;
            const {type,status}=action.payload;

            state={...state,message:{...state.message,[type]:status}};
            break;
        }
        case SET_CONNECT_METHOD:{
            const payload=action.payload?action.payload:initialState.userInfo.connectMethod;
            Cache.setItem('loginMode', payload);
            state={...state,userInfo:{...state.userInfo,connectMethod:payload}};
            break;
        }
        case SET_USER_CREDS:{
            const {userName,password}=action.payload?action.payload:initialState.tempCreds;

            state={...state,tempCreds:{userName,password}};
            break;
        }
        case CLEAR_USER_CREDS:{
            state={...state,userInfo:initialState.userInfo, tempCreds:initialState.tempCreds};
            break;
        }
        case ON_SIGN_OUT:{
            state={...initialState};
            break;
        }
        default:break;
    };

    return state;
};