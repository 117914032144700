export const fillLayer = {
    id: '0',
    type: 'fill',
    source: 'data',
    paint: {
        'fill-color': "#3cb2d0",
        'fill-opacity': 0.3,
        'fill-outline-color':'#ffffff',
    }
};

export const selectedCropLayer={
    id: '1',
    type: 'fill',
    source: 'data',
    paint: {
        'fill-color': ['get','selectedCrop'],
        'fill-opacity':1,
        'fill-outline-color':'#ffffff',
    }
}

export const unverfiedLayer={
    id: '2',
    type: 'fill',
    source: 'data',
    paint: {
        'fill-color': "#ed3b94",
        'fill-opacity': 1,
        'fill-outline-color':'#ffffff',
    }

};

export const cropLayer={
    id: '3',
    type: 'fill',
    source: 'data',
    paint: {
        'fill-color': ['get','currentCrop'],
        'fill-opacity':1,
        'fill-outline-color':'#ffffff',
    }
};

export const sequentialLayer={
    id:'4',
    type:'fill',
    source:'data',
    paint: {
        'fill-color': ['get','seqColor'],
        'fill-opacity': ['get','seqOpacity'],
        'fill-outline-color':'#000000',
    }
};

export const lineLayer = {
    id: '5',
    type: 'line',
    source: 'data',
    paint: {
        'line-color': "#ffffff",
        'line-width': 0.8
    }
};

export const textLayer = {
    id: '6',
    type: 'symbol',
    source: 'data',
    layout: {
        'text-field': ['get','FieldName'],
        'text-variable-anchor': ['center'],
        'text-justify': 'auto',
    },
    paint:{
        'text-color':'#ffffff'
    }
};

export const selectedLayer = {
    id: '7',
    type: 'fill',
    source: 'data',
    paint: {
        'fill-color': "#ffff00",
        'fill-opacity': 1,
        'fill-outline-color':'#000000',
    }
}; 

export const selectedLineLayer = {
    id: '8',
    type: 'line',
    source: 'data',
    paint: {
        'line-color':"#000000",
        'line-width': 1
        
    }
};

export const selectedTextLayer = {
    id: '9',
    type: 'symbol',
    source: 'data',
    layout: {
        'text-field': ['get','FieldName'],
        'text-variable-anchor': ['center'],
        'text-justify': 'auto',
    },
    paint:{
        'text-color':'#000000'
    }
};

export const clusterLayer = {
  id: '10',
  type: 'circle',
  source: 'data',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#ed3b94',100,'#ed3b94',500,'#ed3b94',750,'#ed3b94'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
  }
};

export const clusterCountLayer = {
  id: '11',
  type: 'symbol',
  source: 'data',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 12
  },
  paint:{
      'text-color':'#ffffff',
  },
};

export const unclusteredPointLayer = {
  id: '12',
  type: 'circle',
  source: 'data',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#ed3b94',
    'circle-radius': [
        "interpolate", ["linear"], ["zoom"],
        12, 20,
        14, 12,
        15, 0,
    ]
  }
};

export const unclusteredCountLayer = {
  id: '13',
  type: 'symbol',
  source: 'data',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'text-field': ['get','defaultCount'],
    'text-variable-anchor': ['center'],
    'text-justify': 'auto',

    'text-size': [
        "interpolate", ["linear"], ["zoom"],
        12, 16,
        14,12,
        15,0
    ]
  },
  paint:{
      'text-color':'#ffffff',
  },
};

export const newLayer={
    id:'14',
    type:'symbol',
    source:'point',
    layout:{
        'text-field':'மேற்கு குண்டு',
    }
}