import React from "react";
import { useSelector } from "react-redux";
import { HorizontalLoader } from './Loader';
import ScreenOverlay from "./ScreenOverlay";

const Overlay = () =>{
    const{show,text}=useSelector(state=>state.user.overlay);

    return(
        <ScreenOverlay show={show}>
            <ScreenOverlay.Content>
                <ScreenOverlay.Text>{text || "Loading"}</ScreenOverlay.Text>
                <HorizontalLoader />
            </ScreenOverlay.Content>
        </ScreenOverlay>
    );
};

export default Overlay;