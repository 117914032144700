/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMember = /* GraphQL */ `
  query GetMember($phoneNumber: String!) {
    getMember(phoneNumber: $phoneNumber) {
      id
      name
      deleted
      subscriptionDate
      representative {
        name
        phone
        pk
        __typename
      }
      representativeOff {
        name
        phone
        __typename
      }
      phoneNumber
      fiG
      language
      status
      org
      owner
      pk
      fields {
        items {
          id
          farmerID
          areaSize
          deleted
          assessed
          placeDescription
          soiltype
          dateRange
          initial
          supervised
          TileGrid
          fieldName
          verified
          latlng
          contour
          lng
          lat
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $phoneNumber: String
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMembers(
      phoneNumber: $phoneNumber
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        deleted
        subscriptionDate
        representative {
          name
          phone
          pk
          __typename
        }
        representativeOff {
          name
          phone
          __typename
        }
        phoneNumber
        fiG
        language
        status
        org
        owner
        pk
        fields {
          items {
            id
            farmerID
            areaSize
            deleted
            assessed
            placeDescription
            soiltype
            dateRange
            initial
            supervised
            TileGrid
            fieldName
            verified
            latlng
            contour
            lng
            lat
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFlights = /* GraphQL */ `
  query GetFlights($grid: String!, $id: ID!) {
    getFlights(grid: $grid, id: $id) {
      grid
      id
      type
      dt
      currentTime
      eo
      tokens
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFlightss = /* GraphQL */ `
  query ListFlightss(
    $grid: String
    $id: ModelIDKeyConditionInput
    $filter: ModelFlightsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFlightss(
      grid: $grid
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        grid
        id
        type
        dt
        currentTime
        eo
        tokens
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMemberFields = /* GraphQL */ `
  query GetMemberFields($id: ID!) {
    getMemberFields(id: $id) {
      id
      farmerID
      areaSize
      deleted
      assessed
      placeDescription
      soiltype
      dateRange
      initial
      supervised
      TileGrid
      fieldName
      verified
      latlng
      contour
      lng
      lat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMemberFieldss = /* GraphQL */ `
  query ListMemberFieldss(
    $filter: ModelMemberFieldsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberFieldss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmerID
        areaSize
        deleted
        assessed
        placeDescription
        soiltype
        dateRange
        initial
        supervised
        TileGrid
        fieldName
        verified
        latlng
        contour
        lng
        lat
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
