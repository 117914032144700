import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Popper } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { useSelector } from 'react-redux';

const FarmerMoreInfoPopup = (props) => {
    const {open,id,farmEl,setFarmEl,setDrawPolygon,setSidebarCollapse,memberInfo,setConfirmDelete}=props;

    const {theme,messageCount}=useSelector(state=>state.user);

    return (
        <>
        <div className='back-drop' onClick={()=>setFarmEl(null)}></div>
            <Popper className={`farmer-drop ${theme}`} style={{'--message-count':messageCount}} open={open} id={id} onClose={() => setFarmEl(null)} anchorEl={farmEl} onClick={(e)=>e.stopPropagation()}>
                <Grid container className='drop-list'>
                    <Grid container  direction='row'>
                        <Typography className='drop-header'>FIELD MANAGEMENT</Typography>
                        <Typography className='drop-item inactive'>EDIT NAME AND PHONE NUMBER</Typography>
                        <Typography className='drop-item' onClick={()=>{setDrawPolygon(true);setSidebarCollapse(true);}}>ADD FIELD</Typography>
                        <Typography className='drop-item inactive'>ADD PROPERTY BOUNDARY</Typography>
                        <Typography className='drop-item' onClick={()=>{setConfirmDelete(true);setFarmEl(null)}}>DELETE {memberInfo.name.toUpperCase()}</Typography>
                        {/* <Typography className='drop-item inactive'>MINT LAND CERTIFICATE</Typography> */}
                    </Grid>
                    {/* <Grid container  direction='row'>
                        <Typography className='drop-header'>COMMODITY TOKENS</Typography>
                        <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked size = 'small' color = 'primary' />} className = 'switch' label={`always sell ${memberInfo.name} token as future`}/>
                            <FormControlLabel control={<Switch size = 'small'/>} className = 'switch' label={`automatically offer ${memberInfo.name} an insurance`} />
                            <FormControlLabel control={<Switch size = 'small'/>} className = 'switch' label={`automatically offer ${memberInfo.name} a crop loan`} />
                        </FormGroup>
                    </Grid>
                    <Grid container direction='row'>
                        <Typography className='drop-header'>SECURITY</Typography>
                        <Typography className='drop-item'>SIGN MESSAGE TO BREAK WALLET KEY</Typography>
                        <Typography className='empty'></Typography>
                    </Grid> */}

                </Grid>
            </Popper>
        </>
    );
};

export default FarmerMoreInfoPopup;