/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      name
      deleted
      subscriptionDate
      representative {
        name
        phone
        pk
        __typename
      }
      representativeOff {
        name
        phone
        __typename
      }
      phoneNumber
      fiG
      language
      status
      org
      owner
      pk
      fields {
        items {
          id
          farmerID
          areaSize
          deleted
          assessed
          placeDescription
          soiltype
          dateRange
          initial
          supervised
          TileGrid
          fieldName
          verified
          latlng
          contour
          lng
          lat
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      name
      deleted
      subscriptionDate
      representative {
        name
        phone
        pk
        __typename
      }
      representativeOff {
        name
        phone
        __typename
      }
      phoneNumber
      fiG
      language
      status
      org
      owner
      pk
      fields {
        items {
          id
          farmerID
          areaSize
          deleted
          assessed
          placeDescription
          soiltype
          dateRange
          initial
          supervised
          TileGrid
          fieldName
          verified
          latlng
          contour
          lng
          lat
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      name
      deleted
      subscriptionDate
      representative {
        name
        phone
        pk
        __typename
      }
      representativeOff {
        name
        phone
        __typename
      }
      phoneNumber
      fiG
      language
      status
      org
      owner
      pk
      fields {
        items {
          id
          farmerID
          areaSize
          deleted
          assessed
          placeDescription
          soiltype
          dateRange
          initial
          supervised
          TileGrid
          fieldName
          verified
          latlng
          contour
          lng
          lat
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFlights = /* GraphQL */ `
  mutation CreateFlights(
    $input: CreateFlightsInput!
    $condition: ModelFlightsConditionInput
  ) {
    createFlights(input: $input, condition: $condition) {
      grid
      id
      type
      dt
      currentTime
      eo
      tokens
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFlights = /* GraphQL */ `
  mutation UpdateFlights(
    $input: UpdateFlightsInput!
    $condition: ModelFlightsConditionInput
  ) {
    updateFlights(input: $input, condition: $condition) {
      grid
      id
      type
      dt
      currentTime
      eo
      tokens
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFlights = /* GraphQL */ `
  mutation DeleteFlights(
    $input: DeleteFlightsInput!
    $condition: ModelFlightsConditionInput
  ) {
    deleteFlights(input: $input, condition: $condition) {
      grid
      id
      type
      dt
      currentTime
      eo
      tokens
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMemberFields = /* GraphQL */ `
  mutation CreateMemberFields(
    $input: CreateMemberFieldsInput!
    $condition: ModelMemberFieldsConditionInput
  ) {
    createMemberFields(input: $input, condition: $condition) {
      id
      farmerID
      areaSize
      deleted
      assessed
      placeDescription
      soiltype
      dateRange
      initial
      supervised
      TileGrid
      fieldName
      verified
      latlng
      contour
      lng
      lat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMemberFields = /* GraphQL */ `
  mutation UpdateMemberFields(
    $input: UpdateMemberFieldsInput!
    $condition: ModelMemberFieldsConditionInput
  ) {
    updateMemberFields(input: $input, condition: $condition) {
      id
      farmerID
      areaSize
      deleted
      assessed
      placeDescription
      soiltype
      dateRange
      initial
      supervised
      TileGrid
      fieldName
      verified
      latlng
      contour
      lng
      lat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMemberFields = /* GraphQL */ `
  mutation DeleteMemberFields(
    $input: DeleteMemberFieldsInput!
    $condition: ModelMemberFieldsConditionInput
  ) {
    deleteMemberFields(input: $input, condition: $condition) {
      id
      farmerID
      areaSize
      deleted
      assessed
      placeDescription
      soiltype
      dateRange
      initial
      supervised
      TileGrid
      fieldName
      verified
      latlng
      contour
      lng
      lat
      createdAt
      updatedAt
      __typename
    }
  }
`;
