import React, { useState } from 'react';
import { styled } from '@material-ui/styles';
import { Grid, Typography,Card, CardContent, CardActions, Button, IconButton, Collapse } from "@material-ui/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const IntroCard=(props)=>{
  const { media, text, title, paragraph, link } = props;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className='card_expand_element' onClick={handleExpandClick} onMouseEnter={handleExpandClick}>
      <CardContent>
        <Typography variant='h6' style={{ fontWeight: '700'}}>{title}</Typography>
        <Typography variant='body1'>{text}</Typography>
        <Typography variant='body1'>{paragraph}</Typography>
      </CardContent>
      <ExpandMore
      expand={expanded}
      aria-expanded={expanded}
      aria-label="show more">
        <ExpandMoreIcon id='actions'/>
      </ExpandMore>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid container justifyContent='center'>
            <img
            src={media} 
            width="200"
            height="200"
          />
          </Grid>
          { link ? 
          <CardActions>
            <Button href={link} size="large" variant='outlined' color='primary' fullWidth id='actions'>Click here</Button>
          </CardActions>
          : null }
      </Collapse>
    </Card>
  );
};

export default IntroCard;