import React, { useState} from "react";
import {Grid,Typography, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchCategoryPopup from "../../poppers/SearchCategoryPopup";
import { setSearchText} from "../../../actions/members";
import SuggestionPopup from "../../poppers/SuggestionPopup";

const SearchBar=()=>{
    const {searchCategory,searchText,selectedFieldId} = useSelector(state => state.members);
    const[searchCategoryEl,setSearchCategoryEl]=useState(null);
    const[suggestionsEl,setSuggestionsEl]=useState(false);

    const dispatch = useDispatch();
    const categoryId = Boolean(searchCategoryEl) ? 'search-category-drop' : undefined;

    const handleSearchCategoryEl= (event) =>{
        setSearchCategoryEl(searchCategoryEl ? null : event.currentTarget);
    };

    return(
        <Grid className='search-bar'>
            <Grid
            className='drop-down'
            onClick={(e)=>handleSearchCategoryEl(e)}>
                <Typography className='category-drop'>{searchCategory?searchCategory.subCategory:'category'}</Typography>
                <ArrowDropDownIcon/>
                {searchCategoryEl && 
                <SearchCategoryPopup 
                id={categoryId}
                open={Boolean(searchCategoryEl)}
                searchCategoryEl={searchCategoryEl}
                setSearchCategoryEl={setSearchCategoryEl}
                />}
            </Grid>
            <TextField
            id="suggestions-drop"
            autoComplete='off'
            value={searchText}
            size="medium"
            disabled={!searchCategory}
            onChange={(e)=>{dispatch(setSearchText(e.target.value));
                if(searchCategory.category==='field activity'&&searchCategory.subCategory==='crop'){
                    setSuggestionsEl(e.target.value.length>0);
                }}}
            placeholder={searchCategory?`enter ${searchCategory.subCategory}.`:"select a category to search for."}
            InputProps={{disableUnderline:true,cursor:`${!searchCategory?'not-allowed':'pointer'}`}}
            style={{width:'100%',paddingLeft:'0.5rem'}}/>
            {searchCategory.category==='field activity'&&searchCategory.subCategory==='crop'&&selectedFieldId&&
            searchText.length>0&&suggestionsEl&&<SuggestionPopup onSelect={()=>setSuggestionsEl(false)}/>}
        </Grid>
    );
};

export default SearchBar;