import React from 'react';
import {Grid, CircularProgress, Typography } from '@material-ui/core';

const WaitForPincode = () => {

    return (
        <Grid container justifyContent='center' alignItems='center' className='modal-container'>
            <CircularProgress color="primary" size={100} style={{ padding: '2em'}} />
            <Typography variant='button'>Wait a moment. We are generating a unique pincode for each new wallet.</Typography>
            <Typography variant='body2'>With your secret key, you are able to create a new pincode in case the farmer has lost his old one.</Typography>
        </Grid>
    );
};

export default WaitForPincode;