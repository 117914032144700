import React, { Fragment } from 'react';
import {TableBody, Table, TableCell, TableRow, TableHead} from '@material-ui/core';
import { useSelector } from 'react-redux';
import BalancePie from '../../../pies/Balance';
import { ASSETS_BY_ID } from '../../../../constants';

const BalanceSheet=()=> { 
    const {wallet}=useSelector(state=>state.user.userInfo);

    return (
      <>
        <BalancePie wallet={wallet}/>
        <Table>
          <TableHead className='table-header'>
            <TableRow>
                <TableCell id="label"></TableCell>
                <TableCell id="label" style={{width:'25%',textAlign:'left'}}>Asset</TableCell>
                <TableCell id="label" style={{textAlign:'left',width:'28%'}}>Balance</TableCell>
                <TableCell id="label" style={{textAlign:'left',width:'20%'}}>INR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='table-body'>
            <TableRow>
                <TableCell id="label"></TableCell>
                <TableCell id="label" style={{width:'20%',textAlign:'left'}}>Algo</TableCell>
                <TableCell id="label" style={{textAlign:'left'}}>{wallet.amount / 1000000}</TableCell>
                <TableCell id="label" style={{textAlign:'left'}}>{(0).toLocaleString('en-IN', {maximumFractionDigits: 2})}</TableCell>
            </TableRow>
            {wallet.assets.map((asset, index) => 
              <Fragment key={index}>
                {!asset['is-frozen'] ? 
                  <TableRow>
                    <TableCell id="label"></TableCell>
                    <TableCell id="label" style={{width:'20%',textAlign:'left'}}>{ASSETS_BY_ID[asset['asset-id']]}</TableCell>
                    <TableCell id="label" style={{textAlign:'left'}}>{asset.amount}</TableCell>
                    <TableCell id="label" style={{textAlign:'left'}}>{(asset.amount * 50).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                    })}</TableCell>
                  </TableRow>
                  : null }
              </Fragment>
            )}            
          </TableBody>
        </Table>
      </> 
    );
};
  
export default BalanceSheet;