import React,{ useRef,useState } from 'react';
import {Grid,IconButton,Typography,Button} from '@material-ui/core';
import { Cache } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import Print from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print'
import PDFpages from './PDFpages';
import createPagesWithJS from './createPagesWithJs';
import { setMessage } from '../../../actions/user';

const DistributePin = (props) => {
    const { onClose, pages, org,printPages}=props;

    const dispatch=useDispatch();
    const pdfpage = useRef();
    const [enable,setEnable]=useState(false);

    // even though we created a wallet for a farmer that has a friend-of-farmer, we dont actually send him a invite. 
    // we forget the pin, but did save the ciphertext,mac,salt so we can retreive it when the farmer decides to not longer need representation
    // then the office manager can retrieve the pin using his/her SK. 
    const removeRepresented = pages.filter(member => Object.keys(member.representative).length === 0 && member.representative.constructor === Object)

    const generatePDF = useReactToPrint({
        documentTitle:`Chitta network_${+new Date()}`,
        content: () => pdfpage.current,
        onAfterPrint:()=>setEnable(true)
    });

    const unableToPrint = () => {
        if(!printPages){
            const hasPages=Cache.getItem('toPrint');
            const pagesToCache=hasPages?[...hasPages,...pages]:pages;
            Cache.setItem('toPrint',pagesToCache);
            dispatch(setMessage('printMsg',true));
        };
        
        onClose();
        // run code to cache pages (or append pages to existing cache?) and create message bar..
        // after printed, clear cache
    };

    const SaveMemberandInvite = () => {
        const hasCachedMembers=Cache.getItem('newMembers');
        const comparedWithCache=hasCachedMembers&&pages.filter(p=>!hasCachedMembers.map(m=>m.phoneNumber).includes(p.phoneNumber));
        const membersToCache=hasCachedMembers?[...hasCachedMembers,...comparedWithCache]:pages;
        Cache.setItem('newMembers',membersToCache);
        dispatch(setMessage('inviteMsg',true));
        onClose();

        // run code to cache pages (or append pages to existing cache?) and create message bar to send invite message to farmer (after he got page..)
        // after invite, clear cache
    };

    return (
        <Grid container className='modal-container'>
            <Grid container className='modal-header'>
                <IconButton ></IconButton>
                <Typography className='title'>PLEASE PRINT A COPY. BE AWARE OF SECURITY ISSUES AS EACH PAGE HAS A SECRET PINCODE.</Typography>
                <Typography className='sub-title'>Hand each farmer the page that has his/her name on it.</Typography>
            </Grid>
            <Grid container justifyContent='space-between' alignItems='center' direction='column' className='modal-body'>
                <IconButton color="inherit" aria-label="print" style={{ margin: '3em'}} onClick={() => generatePDF()}>
                    <div style={{ display: "none" }}><PDFpages ref={pdfpage} pages={() => createPagesWithJS(removeRepresented, org)}/></div>
                    <Grid container direction='column' >
                        <Print style={{ fontSize: 200, padding: '0 0.1em 0 0.1em' }} />
                        <Typography variant='button'>print</Typography>
                    </ Grid>
                </IconButton>
            </Grid>
            <Grid container className='modal-footer'>
                <Grid container className='footer-btns'>
                    <Button id='cls-btn' onClick={unableToPrint} disabled={!pages.length}>I am unable to print the document at this moment.</Button>
                    <Button className={`inv-btn ${!enable?'disabled':''}`} onClick={SaveMemberandInvite} disabled={!enable}>DONE</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DistributePin;