import React,{Fragment} from "react";
import { Grid,Popper,Link,Divider } from '@material-ui/core';
import {useSelector } from 'react-redux';
import { HEADER_OPTIONS } from '../../../constants';

const MenuPopup = (props) => {
    const{menuEl,setMenuEl}=props;

    const connected = useSelector(state => state.user.userInfo.connected);
    const theme  = useSelector(state=>state.user.theme);

    const headerRoutes = connected ? HEADER_OPTIONS.filter(op => op.auth) : HEADER_OPTIONS;
    
    return(
        <>
            <div className='back-drop' onClick={()=>setMenuEl(null)}></div>
            <Popper
            className={`menu-drop ${theme}`}
            open={true}
            onClose={()=>setMenuEl(null)}
            anchorEl={menuEl}
            onClick={(e)=>{e.stopPropagation()}}>
                <Grid className='drop-list'>
                    {headerRoutes.map(option=>(
                        <Fragment key={option.id}>{ (!option.theme) &&
                        <>
                            <Divider className='divider'/>
                            <Grid className='drop-item' style={{...option.style}} onClick={option.onClick}>
                                {option.link ? <Link href={option.link} target="_blank" color="inherit" >{option.label}</Link> :<>{option.label}</>}
                            </Grid>
                        </>}
                        </Fragment> 
                    ))}
                </Grid>
            </Popper>
        </>
    );
};

export default MenuPopup;