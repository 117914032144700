import React,{useState} from 'react';
import WalletIcon from '../../widgets/WalletIcon';
import { CustomModal } from '../../widgets/CustomModal';
import AccountInfo from '../../wallets/connected-wallet/AccountInfo';

const Connected = () => {
    const [connectModal, setConnectModal] = useState(false);

    const handleClose=()=>{
        setConnectModal(false);
    };

    return(<>
        <WalletIcon onClick={()=>setConnectModal(!connectModal)}/>
        {connectModal && 
        <CustomModal open={connectModal} onClose={handleClose} className='connected-modal'>
            <AccountInfo handleClose={handleClose}/>
        </CustomModal>}
    </>);
};

export default Connected;