export default {
    app:{
        name: "chitta-interface",
        version: "0.1.0",
    },
    map:{
        MAP_KEY:'pk.eyJ1IjoiYmxvY2tjaGFpbjRjb21tb25zIiwiYSI6ImNraG94M3ZyeTBxcWQyd2s4ZmZubHVwN3QifQ.TvKBmWZokMer8V93_2xQ0w',
        MAP_STYLE_EDIT:'mapbox://styles/blockchain4commons/ckrjltrsl8f8t17qy6a8lkg2l',
        MAP_STYLE_NORMAL:'mapbox://styles/blockchain4commons/ckws11lzf0wnp15t7syzzuis9',
    },
    wallet:{
        TO_TX_ID:'DZLDJVFJUCX2C5H27U6AE5UL3KMCWX7Z37SIGJ4QAGIFHKEHKGRVQHECRI'
    },
    links:{
        ALGO_DWD_LINK:'https://chrome.google.com/webstore/detail/algosigner/kmmolakhbgdlpkjkcjkebenjheonagdm',
        NEW_TO_BLOCKCHAIN:"https://docs.chitta.network/new-to-blockchain"
    }
};