import { API, Cache, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { createMember,createMemberFields,deleteMember,updateMember,deleteMemberFields} from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { Snack } from '../components/widgets/Toast';
import { REQUESTING_MEMBERS_LIST, SET_MEMBERS_LIST,SET_SELECTED_MEMBER,SET_SELECTED_FIELD_ID,
    SET_SELECTED_FEATURE,DELETE_MEMBER,DELETE_FIELD,SET_SEARCH_CATEGORY,SET_SEARCH_TEXT,SET_SELECTED_CROP,SET_SELECTED_OPTION} from '../constants/action-types';
import { getAreaSize, getCenter } from '../helpers';
import { getNotesFromIndexer } from '../helpers/algorand';
import { setOverlay } from './user';
import { setRewards } from './wallet';

export const requestingMembersList = (loading) => ({ type: REQUESTING_MEMBERS_LIST, payload: loading });

export const setMembersList = (list) => ({ type: SET_MEMBERS_LIST, payload: list });

export const setSelectedMember=(farmerId)=>({type:SET_SELECTED_MEMBER,farmerId});

export const setSelectedFieldId=(fieldId)=>({type:SET_SELECTED_FIELD_ID,fieldId});

export const setSelectedFeature=(feature)=>({type:SET_SELECTED_FEATURE,feature});

export const setSearchCategory=(category)=>({type:SET_SEARCH_CATEGORY,payload:category});

export const setSearchText=(value)=>({type:SET_SEARCH_TEXT,payload:value});

export const setSelectedOption=(option)=>({type:SET_SELECTED_OPTION,payload:option});
export const setSelectedCrop=(crop)=>({type:SET_SELECTED_CROP,payload:crop});

export const onRemoveMember=(params)=>({type:DELETE_MEMBER,payload: params});

export const onRemoveField=(farmerID,fieldID)=>({type:DELETE_FIELD,payload:{farmerID,fieldID}})

export const getMembers = () => async (dispatch,getState) => {
    dispatch(requestingMembersList(true));
    const wallet=getState().user.userInfo.wallet;
    const memList=getState().members.membersList.list;
    const CachedMembersWithNotes=Cache.getItem('membersWithNotes');

    await API.graphql(graphqlOperation(queries.listMembers)).then(res => {
        console.log('res', res)
        const memberList = res.data.listMembers.items;
        console.log('memberlist', memberList)
        if(memberList.length) {
            console.log('member',memberList.length)
            if(!memList.length){
                console.log('mem',memList.length)
                const list=CachedMembersWithNotes?CachedMembersWithNotes:memberList;
                console.log('list', list)
                dispatch(setMembersList(list));
                console.log('till here?')
            }else dispatch(setMembersList(memberList));
            dispatch(getNotes(wallet.address,memberList));
        }else dispatch(requestingMembersList(false));
    }).catch(e => {
        console.log(`getting members error --> ${e}`, e);
        dispatch(requestingMembersList(false));
    });
};

export const createMembers = (newMembers) =>async (dispatch,getState)=> {
    const {session,wallet}=getState().user.userInfo;
    const memList=[...getState().members.membersList.list];

    try {
        newMembers.forEach( async(member) => {
            const companyName = session['custom:companyName']
            const owner = wallet.address;
            let firstName=member.firstName.trim();
            let lastName=member.lastName.trim();
            const name=`${firstName} ${lastName}`;

            const farmer = {
                id: uuidv4(),
                subscriptionDate: Date.now(),
                name: name,
                phoneNumber: member.phoneNumber,
                fiG: member.fiG,
                representative:member.representative,
                representativeOff:member.representativeOff,
                language: member.language,
                status: 0,
                owner: owner,
                pk:member.PK?member.PK:member.representative.pk,
                org: companyName 
            };
            await API.graphql(graphqlOperation(createMember, {input: { ...farmer }}));
            dispatch(setMembersList([...memList,{...farmer,fields:{items:[]}}]));
        });

        return true;
    }
    catch(e) {
        return false;
    }      
};

export const updateMembers = (members) =>async ()=> {
    try {
        members.forEach( async(member) => {
            await API.graphql(graphqlOperation(updateMember, {input: { ...member }}));
        });
        
        Snack(`Done. ${members?.length} ${members?.length > 1 ? 'farmers have' : 'farmer has'} been updated.`, 4000, null, 'success', true);
    }
    catch(e) {
        console.log('updating member error--->',e)
    }     
};

export const removeMember = (phoneNumber,cb) => async(dispatch,getState)=> {
    let memberById=getState().members.membersList.memberById;

    try {
        await API.graphql(graphqlOperation(deleteMember, { input: { phoneNumber } })).then((resp) => {
            dispatch(setSelectedMember());
            const hasRep=resp.data.deleteMember.representative&&resp.data.deleteMember.representative.phone
            const params={
                phone:resp.data.deleteMember.phoneNumber,
                hasRep
            }
            if(hasRep){
                const params={
                    phoneNumber: hasRep,
                };
                if(memberById[hasRep].representativeOff)params.representativeOff=memberById[hasRep].representativeOff.filter(mem=>mem.phone!==resp.data.deleteMember.phoneNumber);
                dispatch(updateMembers([params]));
            };
            dispatch(onRemoveMember(params));
            if(cb)cb(true);
            Snack(`Done. ${resp.data.deleteMember.name} has been unsubscribed from Chitta`,12000,null,'success',true);
        });
    }
    catch (e) {
        console.log('remove member error-->', e)
    }
};

export const updateField =(toUpdate,cb=null) =>async (dispatch)=> {
    try{
        await API.get('sendsampleapi', '/boundaries', toUpdate).then((response)=>{
            if(cb)cb();
            if(response.saved&&response.hasOwnProperty('gotReward')){
                dispatch(setRewards(response.gotReward));
            }
        });
    }catch(e){
        console.log('seding boundary update error -->',e);
        dispatch(setOverlay());
        Snack('Oops,Field updation failed!',4000,null,'error',true);
    }
};

export const createField =() =>async (dispatch,getState)=> {
    const {selectedFeature:features,selectedMember}=getState().members;

    try{
        features.forEach(async(feature)=>{
            const{lng,lat}=getCenter(feature);

            const toCreate={
                id: uuidv4(),
                areaSize:getAreaSize(feature),
                contour:feature.geometry.coordinates[0],
                farmerID:selectedMember,
                verified:true,
                fieldName:'New field',
                placeDescription:'please register the field on-site,',
                lng,
                lat
            };

            await API.graphql(graphqlOperation(createMemberFields, { input: { ...toCreate } }));
        });

        return true;
        
    }catch(e) {
        dispatch(setOverlay());
        Snack('Oops,Field creation failed!',4000,null,'error',true);
        return false;
    } 
};

export const deleteField = (fieldId,cb=null) =>async(dispatch)=>{
    try{
        await API.graphql(graphqlOperation(deleteMemberFields, {input: { id:fieldId }})).then((resp)=>{
            const {farmerID,id}=resp.data.deleteMemberFields;
            if(cb)cb({farmerID,id});
        });
    }catch(e){
        console.log('delete field error -->',e);
        if(cb)cb(false);
    }
};

export const sendSampleToRemote =(toUpdate,cb=null) => async(dispatch)=> {
    try{
        await API.get('sendsampleapi', '/samples', toUpdate).then((response)=>{
            if(cb)cb();
            if(response.saved&&response.hasOwnProperty('gotReward')){
                dispatch(setRewards(response.gotReward));
                Snack('Report saved successfully!',4000,null,'success',true);
            }
        });
    }catch(e){
        console.log('seding sample API (/samples) error -->',e);
    }
};

export const sendInviteMessages =(toUpdate,cb=null) => async()=> {
    try{
        await API.get('sendsampleapi', '/invites', toUpdate).then((response)=>{
            if(cb)cb(response.saved);
        });
    }catch(e){
        console.log('sending sample API (/invites) error -->',e);
    }
};

export const getNotes=(pk,memberList)=>async(dispatch)=>{
    try{
        await getNotesFromIndexer(pk,memberList).then(membersWithNotes=>{
            Cache.setItem('membersWithNotes',membersWithNotes);
            dispatch(setMembersList(membersWithNotes));    
        });
    }catch(e){
        console.log(`getting notes error --> ${e}`);
    }
};