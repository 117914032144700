import React, { Fragment } from "react";
import { Grid,Popper,ClickAwayListener, ListSubheader, MenuItem} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SEARCH_CATEGORIES } from "../../constants";
import { setSearchCategory } from "../../actions/members";

const SearchCategoryPopup = (props) => {
    const{id,open,searchCategoryEl,setSearchCategoryEl} = props;

    const dispatch=useDispatch();
    const theme=useSelector(state=>state.user.theme);
    const searchCategory=useSelector(state=>state.members.searchCategory);

    const handleSearchCategory=(data)=>{
        const{category,subCategory}=data;
        if(!searchCategory||searchCategory.category!==category||searchCategory.subCategory!==subCategory)dispatch(setSearchCategory(data));
        else dispatch(setSearchCategory());
    };

    return(
        <>
            <Popper
            id={id}
            open={open}
            className={`search-category-drop ${theme}`}
            anchorEl={searchCategoryEl}
            onClose={()=>setSearchCategoryEl(null)}
            role={undefined}
            transition
            disablePortal
            >
            <ClickAwayListener onClickAway={(e)=>{e.stopPropagation();setSearchCategoryEl(null);}}>
               <Grid container className='drop-list'>
               {SEARCH_CATEGORIES.map(data=>{
                   const category=data.category;
                   const subCategories=data.subCategories;
                   return(<Fragment key={data.id}>
                        <ListSubheader className='category'>{category}</ListSubheader>
                        {subCategories.map((value,i)=>(
                            <MenuItem 
                            key={`${value}_${i}`} 
                            className={`sub-category ${(searchCategory?.category===category&&searchCategory?.subCategory===value)?'selected':''}`} 
                            onClick={()=>handleSearchCategory({category:category,subCategory:value})}>{value}</MenuItem>))}
                    </Fragment>);
                })}
               </Grid>
            </ClickAwayListener>
            </Popper>
        </>
    );
};

export default SearchCategoryPopup;