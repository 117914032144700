import React from 'react';
import {Grid,IconButton,Typography,Table,TableBody,TableCell,TableHead, TableRow,
    Button,TableContainer} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@material-ui/core/Checkbox';
import InfoPop from '../../widgets/InfoPop';
import MemberRow from './MemberRow';

const MemberTable = (props) => {
    const {addMember,CheckedItems,newMembers,error,setError,handleChange,
        validateInput,removeMember,membersList,agree,setAgree,onClose,disableCondition,handleSendInvite}=props;

    const hasNoWhatsappContent=(
        <Typography variant='body2' className="bubble" style={{margin:0}}>
            Some farmers do not have access to a smart phone or do not know how to use Whatsapp. 
            You can use a Friend-Of-Farmer as a temporary solutions. 
            For security reasons it is not yet possible to let a friend handle payments using the whatsapp wallet. 
            Farmers connected via a representative have NO access to financial services.<br/><br/>
            Please do the following:<br/><br/>
            * Get a whatsapp enabled phone for the farmer or use a phone from a close relative to register the farmers field.<br/><br/>
            OR<br/><br/>
            * Select a Friend-Of-Farmer to handle the first registrations and switch to a normal account later.
        </Typography>
    );

    return (
        <Grid container className='modal-container'>
            <Grid container className='modal-header'>
                <IconButton onClick={(e)=>{e.stopPropagation();onClose()}} className='close-btn'><CloseIcon/></IconButton>
                <Typography className='title'>INVITE FARMERS THROUGH WHATSAPP</Typography>
                <Typography  className='sub-title'>This will create a digital wallet that farmers use to receive payouts and rewards</Typography>
            </Grid>
            <TableContainer className='modal-body'>
                <Table>
                    <TableHead className='table-header'>
                        <TableRow>
                            <TableCell align="left" id='label'>FIRST&nbsp;NAME</TableCell>
                            <TableCell align="left" id='label'>LAST&nbsp;NAME</TableCell>
                            <TableCell align="left" id='label'>LANGUAGE</TableCell>
                            <TableCell align="left" id='label'>PHONE&nbsp;NUMBER</TableCell>
                            <TableCell align="left" id='label'>GROUP&nbsp;(OPTIONAL)</TableCell>
                            <TableCell align="left" id='label' style={{display:'flex',width:'10rem'}}>HAS&nbsp;NO&nbsp;WHATSAPP&nbsp;&nbsp;
                            <InfoPop popperContent={hasNoWhatsappContent} popperClassName={'register-popper'} popperTitle={'Has no whatsapp'}/></TableCell>
                            <TableCell style={{width:'3rem'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='table-body'>
                        {newMembers.map((newMem, idx) => {
                            return (   
                                <MemberRow
                                    key={idx} 
                                    idx={idx}
                                    CheckedItems={CheckedItems}
                                    newMem={newMem}
                                    newMembers={newMembers}
                                    error={error}
                                    setError={setError}
                                    handleChange={handleChange}
                                    validateInput={validateInput}
                                    removeMember={removeMember}
                                    membersList={membersList}
                                />                       
                            )}
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='add-btn' >
                <Button onClick={addMember} style={{ fontSize: '1.4em'}}>+</Button>
            </div>
            <Grid container className='modal-footer'>
                <div className='add-btn'>
                    <Button disabled id='btn'>IMPORT USING A CSV FILE</Button>
                </div>
                <Grid container className='check-btn'>
                    <Checkbox id='check' checked={agree} onChange={(e)=>setAgree(e.target.checked)}/>
                    <Grid container id='msg'>
                        <Typography variant='body2'>The invited members have explicitly agreed to join Chitta and receive Whatsapp</Typography>
                        <Typography variant='body2'>messages or voicebot calls on their phone.</Typography>
                    </Grid>
                </Grid>
                <Grid container className='footer-btns'>
                    <Button id='cls-btn' onClick={onClose}>CLOSE</Button>
                    <Button 
                    className={`inv-btn ${!disableCondition()?'disabled':''}`} 
                    onClick={handleSendInvite}
                    disabled={!disableCondition()}>CREATE A WALLET FOR EACH FARMER</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MemberTable;