import React, { useState,useEffect }from 'react';
import {Grid} from '@material-ui/core';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { generateAccount ,generateMnemonic, txManualSign} from '../../../helpers/algorand';
import WalletOptions from '../../wallets/dis-connected-wallet/WalletOptions';
import SignUpFields from '../../wallets/dis-connected-wallet/SignUpFields';
import MnemonicTable from '../../wallets/dis-connected-wallet/MnemonicTable';
import VerifyOTP from '../../wallets/dis-connected-wallet/VerifyOTP';
import Login from '../../wallets/dis-connected-wallet/Login';
import { setConnectMethod } from '../../../actions/wallet';
import { MANUAL,REDIRECT_TO_MANUAL, SIGN_IN_AMOUNT } from '../../../constants';
import { signUp, setUserCreds } from '../../../actions/user';
import { CustomModal } from '../../widgets/CustomModal';

const DisConnected = ({setShow}) => {
    
    const [newAccount, setNewAccount] = useState(false);
    const [mnemonic, setMnemonic] = useState('');
    const [copyMnemonic, setCopyMnemonic] = useState(false);
    const [verifyPhone, setVerifyPhone] = useState(false);
    const [signUpError, setSignUpError] = useState({ companyName: false, phN: false });
    const [signupValue, setSignupValue] = useState({ firstName: '', lastName: '', companyName: '', phonenumber: '' });

    const dispatch=useDispatch();
    const connectMethod=useSelector(state=>state.user.userInfo.connectMethod);
    const disabled = (!signupValue.phonenumber || signUpError.phN);
    
    useEffect(() => {
        if (signupValue.companyName)setSignUpError({ ...signUpError, companyName: false });
        
    }, [signupValue.companyName]);

    useEffect(()=>{
        if(connectMethod===REDIRECT_TO_MANUAL)setNewAccount(true);

    },[connectMethod]);
    
    const handleOnChange = (event) => {
        if (event.target.name === 'phonenumber') {
            let appendCountryCode = '+91' + event.target.value;
            setSignupValue({ ...signupValue, [event.target.name]: appendCountryCode });
        } else {
            setSignupValue({ ...signupValue, [event.target.name]: event.target.value });
        }
    };
    
    const validateInput = (PhN) => {
        const isValidPhN = validator.isMobilePhone('+91' + PhN);
        setSignUpError({ ...signUpError, phN: !isValidPhN });
    };
    
    const backToMnemonic = () => {
        setNewAccount(false);
        setSignupValue({ firstName: '', lastName: '', companyName: '', phonenumber: '' });
        setSignUpError({ companyName: false, phN: false });
    };
    
    const backToForm = () => {
        setCopyMnemonic(false);
        setMnemonic('');
        setSignupValue({ firstName: '', lastName: '', companyName: '', phonenumber: '' });
    };
    
    // we are not saving the mnemonic, so it doesnt have to be passed up to app.js
    const CreateAlgoRandAccount = async () => {
        if (signupValue.companyName.length) {
            setSignUpError({ ...signUpError, companyName: false });
            
            try {
                // addr = pubkey, sk = secretkey
                const account = generateAccount();
                const Mnemonic = generateMnemonic(account);
                setMnemonic(Mnemonic);
                setCopyMnemonic(true);
                const password=await txManualSign(Mnemonic,account.addr,SIGN_IN_AMOUNT);
                dispatch(setUserCreds(account.addr,password));
            }
            catch (error) {
                console.log('creating algorand account error--> ',error);
            }
        } else setSignUpError({ ...signUpError, companyName: true })
    };

    const handleDirectVerify=()=>{
        dispatch(signUp(signupValue,()=>setVerifyPhone(true)));
    };

    const handleClose=()=>{
        setShow(false);
        setNewAccount(false);
        setCopyMnemonic(false);
        setVerifyPhone(false);
        setMnemonic('');
        setSignupValue({ firstName: '', lastName: '', companyName: '', phonenumber: '' });
        setSignUpError({ companyName: false, phN: false });
        // dispatch(setConnectMethod());
    };

    return (
        <CustomModal open={true} onClose={handleClose} className='dis-connected-modal'>
            {(connectMethod===MANUAL || connectMethod===REDIRECT_TO_MANUAL)?
                <Grid container direction='row'>
                    {newAccount ?
                    <>
                        {!copyMnemonic && !verifyPhone ?
                        // Sign-up fields 
                        <SignUpFields
                        mnemonic={mnemonic}
                        backToMnemonic={backToMnemonic}
                        handleClose={handleClose}
                        signupValue={signupValue}
                        handleOnChange={handleOnChange}
                        signUpError={signUpError}
                        validateInput={validateInput}
                        copyMnemonic={copyMnemonic}
                        disabled={disabled}
                        handleDirectVerify={handleDirectVerify}
                        CreateAlgoRandAccount={CreateAlgoRandAccount}/>
                        
                        : copyMnemonic && !verifyPhone ?
                            // Copy the word phrase 
                            <MnemonicTable
                            mnemonic={mnemonic}
                            handleClose={handleClose}
                            backToForm={backToForm}
                            signupValue={signupValue}
                            signUpError={signUpError}
                            setVerifyPhone={setVerifyPhone}
                            copyMnemonic={copyMnemonic}
                            />
                            :
                            // Phone Number OTP 
                            <VerifyOTP
                            handleClose={handleClose}
                            />
                        }
                    </>
                    :
                    <Login 
                    handleClose={handleClose}
                    setMnemonic={setMnemonic}
                    setNewAccount={setNewAccount}
                    mnemonic={mnemonic}
                    />
                    }
                </Grid>
                :
                <WalletOptions handleClose={handleClose} setShow={setShow}/>
            }
        </CustomModal>
    );
};

export default DisConnected;