import { Cache } from 'aws-amplify';
import { setMessage } from '../actions/user';
import { DELETE_MEMBER } from '../constants/action-types';

const userMiddleware =store=> {
    return next => action => {
        switch (action.type) {
            case DELETE_MEMBER: {
                if(!action.payload.phone)return;
                const toPrint=Cache.getItem('toPrint');
                const newMembers=Cache.getItem('newMembers');

                const updatedPrint=toPrint?toPrint.filter(e=>e.phoneNumber!==action.payload.phone):[];
                Cache.setItem('toPrint',updatedPrint.length?updatedPrint:null);
                const updatedNewMembers=newMembers?newMembers.filter(e=>e.phoneNumber!==action.payload.phone):[];
                Cache.setItem('newMembers',updatedNewMembers.length?updatedNewMembers:null);
                store.dispatch(setMessage('count',updatedNewMembers.length));
                if(!updatedNewMembers.length && !updatedPrint.length){
                    store.dispatch(setMessage('printMsg',false));
                    store.dispatch(setMessage('inviteMsg',false));
                }
                break;
            }
            default:break;
        };

        return next(action);
    };
};

export default userMiddleware;
