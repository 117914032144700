import React from 'react';
import {Button,Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { signUp } from '../../../actions/user';
import SignupForm from '../../forms/Signup';
import { CustomModal } from '../../widgets/CustomModal';

const MnemonicTable = (props) => {
    const {mnemonic,handleClose,backToForm,signupValue,setVerifyPhone,copyMnemonic,signUpError}=props;
    const dispatch=useDispatch();

    return (
        <>
            <CustomModal.Header onClose={handleClose} className='no-sub' hasArrow={true} onArrow={backToForm}>
                <Typography variant='body1' id='task-title'>MAKE SURE YOU WRITE DOWN THE SENTENCE!</Typography>
            </CustomModal.Header>
            <CustomModal.Body>
                <SignupForm mnemonic={mnemonic} copyMnemonic={copyMnemonic} error={signUpError}/>
            </CustomModal.Body>
            <CustomModal.Footer className='has-border'>
                <Button className='btn' onClick={() => dispatch(signUp(signupValue,()=>setVerifyPhone(true)))}>Yes i have written down the phrase</Button> 
            </CustomModal.Footer>
        </>
    );  
};

export default MnemonicTable;