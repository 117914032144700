import React,{Fragment, useEffect,useState} from 'react';
import { Grid, Typography,IconButton,Tooltip, Button} from '@material-ui/core';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSelector,useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FarmerMoreInfoPopup from '../../poppers/FarmerMoreInfoPopup';
import {CROP_COLORS, ASSETS_BY_ID, SEASONS, EXPERTISE, LEASED, PEST_CONTROL, WATER_STORAGE, IRRIGATION, 
    MARKET_ALIGNMENT, SOIL_MANAGEMENT_SCORE, CROP_ROTATION, NO_TILL, COVER_CROPS, GREEN_MANURE, ANIMAL_MANURE,
     CHEMICAL_MANURE, BUFFER_STRIPS, SOIL_ORGANIC_CARBON, SALINITY, NITROGEN, POTASSIUM, PHOSPOROUS, N_P_RATIO, 
     SOIL_CRUST, CLAY_MINERAL_CONTENT,EROSION,PH} from '../../../constants';
import { setSelectedMember,setSelectedFieldId,setSelectedOption,setSelectedCrop } from '../../../actions/members';
import DeleteMemberConfirmModal from '../../widgets/DeleteMemberConfirmModal';
import { getFormattedName } from '../../../helpers';
import FarmerDashboardCard from './FarmerDashboardCard';

const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

const FarmerDashboard = (props) => {
    const {setIsEditable,setDrawPolygon,setSidebarCollapse}=props;

    const [unused, setUnused ] = useState();
    const [showActive, setShowActive] = useState(false);
    const [seq, setSequential] = useState(false);
    const [season, setSeason ] = useState();
    const [soil, setSoil ] = useState(false);
    const [notes,setNotes]=useState({loading:true,seasons:null});
    const [ farmEl, setFarmEl ] = useState(null);
    const [confirmDelete,setConfirmDelete]= useState(false);

    const date_options = { year: 'numeric', month: 'short', day: 'numeric' };

    const {membersList:{memberById},selectedMember} = useSelector(state => state.members);

    const dispatch=useDispatch();
    const open = Boolean(farmEl);
    const id = open ? 'farmer-drop' : undefined;
    const memberInfo=memberById[selectedMember];

    const handleMore = (event) =>{
        setFarmEl(farmEl ? null : event.currentTarget);
    };

    const SumOfSize=(fields, fields_)=>{
        let total = memberInfo.fields.items.length ? memberInfo.fields.items.map(field => parseFloat(field.areaSize)).reduce((a,b)=>a+b) : 0
        let sum = fields_.reduce((a,f) => {
            if (fields.includes(f['id'])){
                return a - f['areaSize']
            }
            return a
        }, total);
        setUnused(sum);
    };

    const GetYieldInBags=(crop,yield_raw)=>{
        switch(crop){
            case 22181339:
                return "Banana";
            case 22179923:
                return "Sugarcane";
            case 22180015:
                return "Millets";
            case 14038534:
                return (yield_raw * 10)/75; //paddy
            case 14038537:
                return yield_raw/100; //groundnut
        };
    };

    const CountCropRotation=(p)=>{
        if (p <= 0.20){ return 'Very Poor'}
        else if (p > 0.20 && p < 0.4){ return 'Poor'}
        else if (p >= 0.4 && p < 0.6){ return 'Fair'}
        else if (p >= 0.6 && p < 0.8){ return 'Good'}
        else if (p >= 0.8){ return 'Very good'}
    };

    const lastYearDelta=(v)=>{
        // find last years cultivation of same crop
        const ssn_c = notes.seasons.map(s => s.crop == v.crop && parseInt(s.SOS.substring(0,4)) == parseInt(v.SOS.substring(0,4)) - 1 ? s : null ).filter(n => n)
        if (ssn_c.length > 0){
            let Yc = v.yield / v.size // yield per cent
            let YC_c= ssn_c[0].yield / ssn_c[0].size // yield per cent
            return `${Math.round(((Yc - YC_c)/YC_c)*100)}%`
        }
        else return '(no data)';
    };

    const CropRotation=(notes)=>{
        // for each field, list crops, then arbitrary add value, hardcode intercrop usefullness (such as groundnut)
        let avgP = 0
        let emptyFields = 0
        memberInfo.fields.items.map(field => {
            // for each field, find the cultivations
            let sequence = notes.filter(n => { return n.fields.includes(field.id) ? n.crop : null })
            if (sequence.length > 1){
                let points = 0
                sequence.reduce((a,b) => {
                    if (a !== undefined && b !== undefined && a.crop !== b.crop){ points += 1 } 
                    return b
                })
                avgP += points/(sequence.length - 1)
            }
            else { emptyFields += 1 }
            return sequence
        });

        return [avgP, emptyFields];
    };

    const CoverCrops=(notes)=>{
        // for each field, check if any tx['guess] has a covercrop, if so return true
        let ratio = [0,0]
        memberInfo.fields.items.map(field => {
            // for each field, find the cultivations
            let sequence = notes.filter(n => { return n.fields.includes(field.id) ? n : null }).map(n =>{ return n.guess })
            // ratio of boolean
            let r = [sequence.map(s => { return s[0] == 'green manure' ? 1 : 0 }).reduce((a, b) => a + b, 0), sequence.length]
            ratio[0] += r[0]
            ratio[1] += r[1]
        })
        let cc = ratio[0]/ratio[1] > 0.2 ? ratio[0]/ratio[1] > 0.4 ? 'Intensive use of covercrops' : 'occasional use of covercrops'  : 'No use of covercrops'
        // also calculate green manure ratio
        let gm = ratio[0]/ratio[1] > 0.2 ? ratio[0]/ratio[1] > 0.4 ? 'Intensive use of green manure' : 'occasional use of green manure'  : 'No use of green manure'
        
        return cc;
    };

    const Pest=(notes)=>{
        // for each field, check if any tx['guess] has a covercrop, if so return true
        let issues = []
        memberInfo.fields.items.map(field => {
            // for each field, find the cultivations
            let sequence = notes.filter(n => { return n.fields.includes(field.id) ? n : null }).map(n =>{ return n.issues !== '' ? n.issues[1] : null }); 
            issues.push(sequence);
        })
        let merged = issues.reduce((a,i) => {return a.concat(i)});
        
        return merged.filter(i => { return i == '8' ? i : null }).length / merged.length;
    };

    const GetSequentialData=(notes,assessments)=>{
        let seasons = [`(${notes.length})`,notes[notes.length-1]['SOS'].substring(0,4)]
        let expertise = [...new Set(notes.map(n => ASSETS_BY_ID[n.crop]))]
        const [ avgP, emptyFields ] = CropRotation(notes)
        let croprotation = CountCropRotation(avgP / (memberInfo.fields.items.length - emptyFields))
        let coverCrops = CoverCrops(notes)
        let pest__nmb = Pest(notes)
        let pest = pest__nmb < 0.05 ? 'NO ISSUES' : pest__nmb < 0.2 ? 'SOME ISSUES' : pest__nmb < 0.4 ? 'HAS ISSUES' : 'SEVERE ISSUES'
        let erosion = average(assessments.filter(n => n ? n['erosion'][0] !== 0 : null).map(a => { return a['erosion'][0] }))
        let avg_ws = average(assessments.filter(n => n ? n['ws'][0] !== 0 : null).map(a => { return a['ws'][0] }))
        // calculates the available water before harvest, marks 1 if a cultivation had sufficient 
        let ws = avg_ws < 0.25 ? 'EXTREME SHORTAGE' : avg_ws < 0.5 ? 'VERY LIMITED SHORTAGE' : avg_ws < 0.75 ? 'LIMITED AVAILABILITY' : 'SUFFICIENT'
        // calculates if the farmer had performded irrigation in the last quarter of the cultivation
        let avg_irr = average(assessments.filter(n => n ? n['irr'][0] !== 0 : null).map(a => { return a['irr'][0] }))
        let irr = avg_irr > 0.5 ? 'SUFFICIENT' : 'LIMITED'
        let organic = assessments.map(a => { return a ? a['organic'][0] : null })[0]
        let avg_til = average(assessments.filter(n => n ? n['tillage'][0] !== 0 : null).map(a => { return a['tillage'][0] }))
        let tillage = avg_til > 0.5 ? 'NO' : 'YES'
        let SOC = average(assessments.filter(n => n ? n['SOC'][0] !== 0 : null).map(a => { return a['SOC'][0] }))
        let sal = average(assessments.filter(n => n ? n['salt'][0] !== 0 : null).map(a => { return a['salt'][0] }))
        let ph = average(assessments.filter(n => n ? n['ph'][0] !== 0 : null).map(a => { return a['ph'][0] }))
        let crust = average(assessments.filter(n => n ? n['crust'][0] !== 0 : null).map(a => { return a['crust'][0] }))
        let clay = average(assessments.filter(n => n ? n['clay'][0] !== 0 : null).map(a => { return a['clay'][0] }))
        let n = '-'
        let pho = '-'
        let k = '-'
        let np_rat = '-'
        //let soilmanagement = NoTillage, GreenManure, Mulching, Manure, Fertilizer, BufferStrips, CoverCrops
        
        return [seasons.join(" "), expertise.join(","),irr,organic,pest,ws,erosion,croprotation,tillage,'gm','mulching','-','-','-',coverCrops,SOC,sal,ph,n,k,pho,np_rat,crust,clay];
    };

    useEffect(()=>{
        if(selectedMember){
            // merge all field note objects of the SELECTED farm to a list of seasons
            const seasons = memberInfo.fields.items.reduce((acc,n) => {
                if (n['notes']&&n['notes'].length > 0){
                    n.notes.map(nt => {
                        let id = nt['id']
                        if(!acc[id]){
                            acc[id] = {
                                'crop': nt['crop'],
                                'yield': nt['yield'],
                                'EOS': nt['EOS'],
                                'SOS': nt['SOS'],
                                'active': nt['active'],
                                'fields': [n['id']],
                                'size': nt['size'],
                                'variety': nt['variety'],
                                'guess': nt['guess'],
                                'issues':nt['issues'],
                                'area':nt['area'],
                            }
                        }
                        else {
                        acc[id]['fields'].push(n['id'])
                        let size = acc[id]['size'] + nt['size']
                        acc[id] = { ...acc[id], 'fields': acc[id]['fields'], 'size': size}
                        }
                    })  
                }
                return acc
            }, []); 
            const assessments = memberInfo.fields.items.reduce((acc,a) => {
                if (a['assess']){
                acc.push(a['assess'][0])
                }
                return acc
            }, []); 
            // change object attr to array
            let seasons_by_list = Object.entries(seasons).map(([k, v]) => { return {...v, 'id': k }});
            const activeSeasonsById = seasons_by_list.map(v => { return v.active ? v.fields : null })
            let notes = seasons_by_list.sort((a,b) => (a.EOS > b.EOS) ? 1 : ((b.EOS > a.EOS) ? -1 : 0)).reverse()
            if(notes.length){
                const [ s, e, l, sm, p, ws, ma, cr,nt,gm,mul,man,fert,bs,cc,soc,sal,ph,n,k,pho,np_rat,crust,clay ] = GetSequentialData(notes, assessments)
                SumOfSize(activeSeasonsById.flat(), memberInfo.fields.items)
                setNotes({loading:false, seasons: notes });
                setSequential({
                    's': s, 'e': e, 'sm': sm, 'p': p, 'ws': ws, 'ma': ma, 'cr': cr, 'l': l,'nt': nt, 'gm': gm, 'mul': mul, 'man': man, 'fert': fert, 'bs': bs, 'cc': cc, 'soc': soc,'sal': sal,'ph': ph,'n': n,'k': k,'pho': pho,'np_rat': np_rat,'crust': crust,'clay': clay});
            }
        }else setNotes({loading:false,seasons:null});
    },[selectedMember]);

    const handleBackArrow=()=>{
        dispatch(setSelectedOption());
        dispatch(setSelectedCrop());
        dispatch(setSelectedMember());
        dispatch(setSelectedFieldId());
        setIsEditable(false);
        setDrawPolygon(false);
    };

    const CalcGeneral=()=>{
        return [
            { icon: 'j',id: 1, name: SEASONS.label, value: seq.s, tip: 'Indicates the total detected cultivations since the indicated year.'},
            { icon: 'V',id: 2, name: EXPERTISE.label, value: seq.e, tip: 'Shows crop expertise of the farmer, based on his historical profile.', analysisDate: seq.e},
            { icon: '_',id: 3, name: LEASED.label, value: '-', tip: 'Use Chitta to manage field ownership (not yet available)', analysisDate: ''},
            { icon: '3',id: 4, name: EROSION.label, value: seq.ma, tip: 'Indicates the state of the soil structure.', analysisDate: ''},
            { icon: 'K',id: 5, name: PEST_CONTROL.label, value: seq.p, tip: 'Indicates signals of pest, either from yield prediction or manually reported.', analysisDate: seq.p},
            { icon: ']',id: 6, name: WATER_STORAGE.label, value: seq.ws, tip: 'Indicates the ability of the farm to have access to natural and irrigational water during the entire cultivation period.', analysisDate: seq.ws},
            { icon: ']',id: 7, name: IRRIGATION.label, value: seq.l, tip: 'Indicates the ability of the farm to have access to natural and irrigational water during the entire cultivation period.', analysisDate: seq.ws},
            { icon: 'f',id: 8, name: MARKET_ALIGNMENT.label, value: '-', tip: 'Indicates the ability of the farmer to adjust harvesting periods to annual price fluctuations.', analysisDate: ''},
            { icon: '[',id: 9, name: SOIL_MANAGEMENT_SCORE.label, value: seq.sm, tip: 'Indicates practices of the farmer to treat and protect soil and enhance its performance, either organic or chemical, or a mix thereof.', analysisDate: seq.sm,showMore:true,bold:true},
        ];
    };

    const CalcSoilManagement=()=>{
        return [
            { icon: 'X',id: 10, name: CROP_ROTATION.label, value: seq.cr, tip: 'Crop rotation is the practice of planting different crops sequentially on the same plot. It is a technique used to improve soil health, replenish nutrients and balance fungus, insects and other bacteria in the soil. Scale: Very Poor, Poor, Fair, Good, Very Good', analysisDate: ''},
            { icon: '6',id: 11, name: NO_TILL.label, value: seq.nt, tip: 'Tillage is known to break soil structures and increases evaporation. No-till is a technique to minimize soil surface disturbance, but requires special techniques such as making furrows, planting seeds and firming the soil.', analysisDate: ''},
            { icon: '1',id: 12, name: COVER_CROPS.label, value: seq.cc, tip: 'Cover crops are seeded to avoid soil exposure to the climate. Cover crops can be simple off-season weeds, green manure, or other leguminuous crops. The score given depends on length of time it had to grow deeper root networks and improve soil porosity and soil stability.', analysisDate: ''},
            { icon: '5',id: 13, name: GREEN_MANURE.label, value: seq.gm, tip: '', analysisDate: 'Green manure is a cover crop specifically sown to add organic matter and nitrogen to the soil. Green manure also acts as soil-acidifying, if a decrease in alcaline (PH is more then 7 is required'},
            { icon: '2',id: 14, name: ANIMAL_MANURE.label, value: seq.man, tip: '', analysisDate: 'Animal manure is an organic fertilizer used to add organic matter and nitrogen to the soil. Unlike green manures, animal manure can have unwanted components, that are detrimental to the soil mineral or nutrient balance.'},
            { icon: 'Z',id: 15, name: CHEMICAL_MANURE.label, value: seq.fert, tip: 'Chemical manure is a fertilizer from industrial derived natural or synthetic origin. Chemical manure can be beneficial because of its refined and concentrated form of macronutrients and supplements. However the outcome is highly dependent on the ability of farmers to distribute the manure correctly and avoid overfertilization.', analysisDate: ''},
            { icon: '4',id: 16, name: BUFFER_STRIPS.label, value: seq.bs, tip: 'Buffer strips are areas of shrubbed land alongside fields that help regulate air, soil and water quality. Buffers trap sediments, enhance filteration, natural pesticides and slow down surface runoff and water. Buffer zones with shrubs or trees provide better overall biodiversity and act as a windbreak or "shelterbelt" for the growing crops.', analysisDate: ''},
        ];
    };

    const CalcSoil=()=>{
        return [
            { icon: 'q',id:17, name: SOIL_ORGANIC_CARBON.label, value: seq.soc, status: 'beta', tip: 'Organic material available in the soil. High SOC improves soil structure or tilth, oxygen in soil and water drainage or retention. It is essential to keep nutrients from draining.'},
            { icon: 'p',id:18, name: SALINITY.label, value: seq.sal, status: 'beta', tip: 'Salt content in the soil of the fields. Salination negatively affects plant growth and yield. It increases soil erosion and the leaching (flushing) of micronutrients.'},
            { icon: 's',id:19, name: PH.label, value: seq.ph, status: 'beta', tip: 'Acidity levels of the soil. A PH of 7 is neutral, less then 7 acidic while PH levels greater than 7 are alkaline (basic) '},
            { icon: 'r',id:20, name: NITROGEN.label, value: seq.n, status: 'beta', tip: 'Nitrogen levels of the soil. Nitrogen is a important nutrient for green healthy leaves, too much however will decrease fruit and seed growth, and burn leaves due to dehydration and salt depletion.'},
            { icon: 'u',id:21, name: POTASSIUM.label, value: seq.k, status: 'beta', tip: 'Potassium levels of the soil. Potassium increases disease resistance, helps stalks to grow upright and sturdy and it improves drought tolerance. Too much potassium however will hinder the soil from taking other critical nutrients.'},
            { icon: 't',id:22, name: PHOSPOROUS.label, value: seq.pho, status: 'beta', tip: 'Phosporous levels of the soil. Phosporous is essential for cell division and the growing tip of the plant. However excessive soil phosphorus reduces the plant’s ability to take up required micronutrients, particularly iron and zinc.'},
            { icon: 'v',id:23, name: N_P_RATIO.label, value: seq.np_rat, status: 'beta', tip: 'The ratio between nitrogen and phosporous levels give a some insights into the fertilizer need. A ratio between 14-16 is considered optimal. Higher ratios indicate a nitrogen deficit, a lower ratio indicated a phosporous deficit.'},
            { icon: 'w',id:24, name: SOIL_CRUST.label, value: seq.crust, status: 'beta', tip: 'Soil crust on bare soil indicates living organisms on the soil surface, important for carbon and nitrogen fixation and soil stabilization.'},
            { icon: 'w',id:25, name: CLAY_MINERAL_CONTENT.label, value: seq.clay, status: 'beta', tip: 'Clay Mineral Content in soil indicates the ability to attract and hold nutrients and water. It further indicates closeness to a source of water.'},
        ];
    };

    return (
        <>
        <Grid className='farmer-dashboard'>
            <Grid container className='farmer-dashboard-header'>
                <Grid xs={3} id='nav' container alignItems='center' justifyContent='center'>
                    <Tooltip title='back' arrow>
                        <Grid item>
                        <IconButton id='dash-btn' onClick={(e)=>{e.stopPropagation();handleBackArrow()}}><ArrowBackIcon /></IconButton>
                    </Grid>
                    </Tooltip>
                </Grid>
                <Grid xs={7} container >
                    <Grid container className='content-top'>
                        <Tooltip title={memberInfo.name} arrow>
                            <Typography variant='h5' id='name'>{getFormattedName(memberInfo.name)}</Typography>
                        </Tooltip>
                        { memberInfo.hasOwnProperty('representative') ? 
                        <Typography variant='body2' id='phone'>{memberInfo.phoneNumber}</Typography> 
                        : 
                        <Tooltip title={`contact ${memberInfo.name} on Whatsapp`} arrow><IconButton id='dash-btn'><a id='cht_font' href={`//api.whatsapp.com/send?phone=${memberInfo.phoneNumber}`}>A</a></IconButton></Tooltip> }
                    </Grid>
                    <Typography variant="overline" gutterBottom>unused: {Math.round(unused)} cents</Typography>
                </Grid>
                <Grid xs={2} id='nav' container alignItems='center' justifyContent='center'>
                    <Grid item>
                        <IconButton id='dash-btn' aria-describedby={id} onClick={(e) =>{e.stopPropagation();handleMore(e);}}>
                            <>
                            <Tooltip title='settings' arrow><MoreHorizIcon /></Tooltip>
                            {farmEl && 
                            <FarmerMoreInfoPopup 
                            open={open} 
                            id ={id} 
                            setFarmEl={setFarmEl} 
                            setSidebarCollapse={setSidebarCollapse} 
                            setDrawPolygon={setDrawPolygon}
                            farmEl={farmEl} 
                            memberInfo={memberInfo}
                            setConfirmDelete={setConfirmDelete}/>}
                            </>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className='farmer-dashboard-content'>
            <Grid className='farmer-dashboard-current'>
                { !notes.loading ? Object.values(notes.seasons).map((v,i) => 
                    <Fragment key={i}>
                    { v['active']?
                    <Grid container className='content-list' onClick={() => {setSeason(season != i ? i : null); season!==i?dispatch(setSelectedCrop(v)):dispatch(setSelectedCrop())}} style={{ borderLeft: `6px solid ${CROP_COLORS[v['crop']]}` }}>
                        <Grid xs={3} item />
                        <Grid xs={9} item>
                            <Grid xs={12} item>
                                <Grid container direction='row' alignItems='center' justifyContent='space-between'>
                                    <Typography variant='button' style={{ fontSize: ASSETS_BY_ID[v['crop']] !== 'unknown' ? 'large' : 'medium', fontWeight: ASSETS_BY_ID[v['crop']] !== 'unknown' ? '700' : '300'}}>{ ASSETS_BY_ID[v['crop']] }</Typography>
                                    <Typography variant='button' id='active'><Tooltip title={`harvest date: ${v['EOS']}`} arrow><span>{Math.round((Date.parse(v['EOS']) - Date.now())/24/60/60/1000,0)} days</span></Tooltip></Typography>
                                </Grid>
                                <Typography variant='overline' id='subsize'>variety: {v['variety'] ? v['variety'] : 'unknown'}</Typography>
                            </Grid>
                            <Grid xs={12} item>
                                <Grid container direction='row' alignItems='center'>
                                    <Typography variant='body1' id='yield'><Typography  variant='overline' id='subsize'>yield: </Typography>{Math.round(GetYieldInBags(v['crop'],v['yield']))} BAGS</Typography>
                                    <Tooltip title={'change from last years season'} arrow><Typography variant='body1' id='yield_diff'>{lastYearDelta(v)}</Typography></Tooltip>
                                </Grid> 
                            </Grid>
                            { season == i ? 
                            <Grid item xs={12} id='expanded'>
                                <Typography variant='body1'>SIZE: {v['size']} cents</Typography>
                                <Typography variant='body1'>ISSUES: { typeof v['issues'] !== 'string' ? v['issues'].map((iss,i) => { return i % 2 == 1 ? iss + `${i===v['issues'].length-1?'':','}` : null }) : null }</Typography>
                                <Typography variant='body1'>STAGE: {v['stage']}</Typography>
                                <Typography variant='body1'>PLANTING DATE: {v['SOS']}</Typography>
                            </Grid>
                            : null }
                        </Grid>
                    </Grid>
                    : null }
                    </Fragment>
                ): null}
                { !notes.loading ? Object.values(notes.seasons).map((v,i) => (
                    <Fragment key={i}>
                    { !v['active'] && showActive ?
                    <Grid container className='content-list' onClick={() => {setSeason(season !== i ? i : null); season!==i?dispatch(setSelectedCrop(v)):dispatch(setSelectedCrop())}} style={{ borderLeft: `6px solid ${CROP_COLORS[v['crop']]}` }}>
                        <Grid xs={3} item/>
                        <Grid xs={9} item>
                            <Grid xs={12} item>
                                <Grid container direction='row' alignItems='center' justifyContent='space-between'>
                                    <Typography variant='button' style={{ fontSize: ASSETS_BY_ID[v['crop']] !== 'unknown' ? 'large' : 'medium', fontWeight: ASSETS_BY_ID[v['crop']] !== 'unknown' ? '700' : '300'}}>{ ASSETS_BY_ID[v['crop']] }</Typography>
                                    <Typography variant='button' id='inactive'>{new Date(Date.parse(v['EOS'])).toLocaleDateString('en-US',date_options)}</Typography>
                                </Grid>
                            </Grid>
                            { season == i ? 
                            <Grid item xs={12} id='expanded'>
                                <Grid container direction='row' alignItems='center'>
                                    <Typography variant='body1' id='yield'><Typography variant='overline' id='subsize'>yield: </Typography>{Math.round(GetYieldInBags(v['crop'],v['yield']))} BAGS</Typography>
                                    <Typography variant='body1' id='yield_diff'>{lastYearDelta(v)}</Typography>
                                </Grid> 
                                <Typography variant='body1'>variety: {v['variety'] ? v['variety'] : 'unknown'}</Typography>
                                <Typography variant='body1'>SIZE: {v['size']} cents</Typography>
                                <Typography variant='body1'>ISSUES: { typeof v['issues'] !== 'string' ? v['issues'].map((iss,i) => { return i % 2 == 1 ? iss + `${i===v['issues'].length-1?'':','}` : null }) : null }</Typography>
                                <Typography variant='body1'>STAGE: {v['stage']}</Typography>
                                <Typography variant='body1'>PLANTING DATE: {v['SOS']}</Typography>
                            </Grid>
                            : null }
                        </Grid>
                    </Grid>
                    : null }
                    </Fragment>
                )) : null}
                <Grid xs={12} item >
                    <Button variant="contained" id='listAllButton' onClick={() => setShowActive(!showActive)}>{ showActive ? 'hide harvested seasons':'list all seasons'}</Button>
                </Grid>
            </Grid>
            <Grid container className='farmer-dashboard-general'>
                <Grid xs={12} item>
                    <Grid className='content-list'>
                        <Typography id="title">General</Typography>
                        {CalcGeneral().map((item)=>(
                           <FarmerDashboardCard key={item.id} item={item} soil={soil} setSoil={setSoil}/>
                        ))}
                        { soil && CalcSoilManagement().map((item) => (
                           <FarmerDashboardCard key={item.id} item={item} />
                        ))}
                    </Grid>
                </Grid>
                <Grid xs={12} item>
                    <Grid className='content-list'>
                        <Typography id="title">Soil Status</Typography>
                        {CalcSoil().map((item)=>(
                           <FarmerDashboardCard key={item.id} item={item} type="soil"/>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
        {confirmDelete&&<DeleteMemberConfirmModal confirmDelete={confirmDelete} onClose={()=>setConfirmDelete(false)} memberInfo={memberInfo}/>}
        </>
    );
};

export default FarmerDashboard;
