import React from 'react';
import {Button,Typography} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { signIn } from '../../../actions/user';
import UseMnemonic from '../../forms/UseMnemonic';
import { setConnectMethod } from '../../../actions/wallet';
import { mnemonicToSecretKey, txManualSign } from '../../../helpers/algorand';
import { SIGN_IN_AMOUNT } from '../../../constants';
import { CustomModal } from '../../widgets/CustomModal';

const Login = (props) => {
    const {handleClose,setMnemonic,setNewAccount,mnemonic}=props;

    const dispatch=useDispatch();

    const handleConnect=async()=>{
        const account=mnemonicToSecretKey(mnemonic.trim());
        const password=await txManualSign(mnemonic.trim(),account.addr,SIGN_IN_AMOUNT);  

        dispatch(signIn(account.addr,password,mnemonic,(success)=>{if(success)setMnemonic('')}));
    };

    return (
        <>
            <CustomModal.Header onClose={handleClose} hasArrow={true} className='no-sub' onArrow={() => dispatch(setConnectMethod())}>
                <Typography variant='body1' id='task-title'>ENTER YOUR SECRET WORD PHRASE</Typography>
            </CustomModal.Header>
            <CustomModal.Body>
                <UseMnemonic width='85%' margin='4rem 0' setMnemonic={(value)=>setMnemonic(value)}/>
            </CustomModal.Body>
            <CustomModal.Footer className='has-border'>
                <Button className='btn' onClick={() => setNewAccount(true)}>create new account</Button>
                <Button className={`btn ${!mnemonic.trim().length ? 'disabled' : ''}`} onClick={handleConnect} disabled={!mnemonic.trim().length}>connect</Button>
            </CustomModal.Footer>
        </>
    );
};

export default Login;