import React from 'react';
import { Grid, Typography} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFieldId } from '../../../actions/members';

const FieldCard = (props) => {
    const {field,isEditable,drawPolygon,setGetStart}=props;

   const selectedFieldId=useSelector(state=>state.members.selectedFieldId);
   const dispatch=useDispatch();

   const handleSelection=()=>{
       setGetStart(false);
       if(!(isEditable||drawPolygon))dispatch(setSelectedFieldId(selectedFieldId===field.id?null:field.id));
    };

    return (
        <Grid container className={`card field-card ${selectedFieldId===field.id?'selected':''}`} 
        onClick={handleSelection}>
            <Grid item id='first'>
                <Typography className={`tag-icon ${!field.verified?'unverified':''}`}>j</Typography>
            </Grid>
            <Grid item className={`second ${!field.verified?'unverified':''}`}>
                <Typography id='name'>{field.fieldName}</Typography>
                <Typography id='loc'>{field.areaSize}cent</Typography>
            </Grid>
        </Grid>
    );
};

export default FieldCard;