import React,{useState,useEffect} from 'react';
import {Backdrop,Modal} from '@material-ui/core';
import { Cache } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import { CheckIfPhoneNumbersHaveBeenRegistered } from '../../../helpers';
import { Snack } from '../../widgets/Toast';
import { createMembers,updateMembers } from '../../../actions/members';
import { setMessage } from '../../../actions/user';
import { createWalletandEncrypt } from '../../../actions/wallet';
import { prepareTxs } from '../../../helpers/algorand';
import SignEncrypt from './SignEncrypt';
import WaitForPincode from './WaitForPincode';
import MemberTable from './MemberTable';
import DistributePin from './DistributePin';
import {ALGO_SIGNER, ALGORAND_MOBILE } from '../../../constants';

const Initial = { firstName: '',lastName:'', phoneNumber: '+91', language: '', fiG: '',representative: {} };

const MemberForm = (props) => {
    const {className,open,onClose,printPages}=props;

    const dispatch=useDispatch();
    const membersList = useSelector(state => state.members.membersList.list);
    const {session,wallet}=useSelector(state=>state.user.userInfo);

    const membersListByPhN=[
        {id:'0',name:session['custom:companyName'],phoneNumber:session['phone_number']},
        ...membersList].reduce((acc,cur)=>({...acc,[cur.phoneNumber]:cur}),{});

    const [newMembers, setNewMembers] = useState([{ ...Initial }]);
    const [ encryptionFlow, setEncryptionFlow ] = useState(0);
    const [ pages, setPages ] = useState([]);
    const [ error, setError] = useState([]);
    const [ agree, setAgree] = useState(false);
    const [CheckedItems, setCheckedItems] = useState([]);
    const mode = Cache.getItem('loginMode');

    useEffect(()=>{
        if(printPages){
            const pagesToPrint=Cache.getItem('toPrint');
            setEncryptionFlow(3);
            setPages(pagesToPrint);
        }
    },[printPages]);

    const validateInput = (PhN) => {   
        const isValidPhN = validator.isMobilePhone(PhN.input,'en-IN',{strictMode:true})
        let errors=[...error];

        if (!isValidPhN) {
            if(!errors.includes(PhN.index))errors.push(PhN.index);
        }else if(errors.includes(PhN.index))errors=errors.filter(i=>i!==PhN.index);

        setError(errors);
    };

    const handleChange = (e) => {
        const value=e.target.value;
        const updatedMembers = [...newMembers];
        const idx = e.target.name.split('-')[1];
        const name = e.target.name.split('-')[0];
        if(name==='representative'){
            updatedMembers[idx][name] = value==='remove'?{}:{name:membersListByPhN[value].name,phone:value,pk:membersListByPhN[value].pk};
        }else updatedMembers[idx][name] = value;
        setNewMembers(updatedMembers);
    };

    const addMember = () => {
        setNewMembers([...newMembers, { ...Initial }]);
    };

    const removeMember = (i) => {
        let Farms = newMembers;
        Farms.splice(i, 1);

        setNewMembers([...Farms]);
    };

    const disableCondition=()=>{
        let valid=true;

        newMembers.forEach(mem=>{
            const{firstName,lastName,language,phoneNumber}=mem;
            if(!firstName||!lastName||!language||!validator.isMobilePhone(phoneNumber,'en-IN',{strictMode:true})) valid=false;
        });

        return (valid && agree);
    };

    const prepareTx=async(newMembers,from,cb=null)=>{
        switch(mode){
            case ALGO_SIGNER:{
                let algoSigner=window.AlgoSigner;
                if(algoSigner){
                    await algoSigner.connect().then(()=>{
                        //getting testnet accounts
                        algoSigner.accounts({ ledger: 'TestNet' }).then((acc)=>{
                            if(acc.length){
                                // get package for signing
                                const toSign = prepareTxs(newMembers,from);
                                
                                if(cb)cb(toSign);
                            }else {
                                Snack('Please add TestNet account in algosigner.',4000,null,'info',true,{toastId:'add-testnet'});
                                if(cb)cb([]);
                            }
                        });
                    });
                }
                break;
            }
            case ALGORAND_MOBILE:{
                let accounts=[];
                
                const connector = new WalletConnect({
                    bridge: "https://bridge.walletconnect.org", // Required
                    qrcodeModal: QRCodeModal,
                });

                if (!connector.connected) {
                    connector.createSession();

                    connector.on("connect", (error, payload) => {
                        const { address } = payload.params[0];
                        accounts.push(address[0]);
                    });
                }else accounts.push(connector.accounts[0]);

                if(accounts.length){
                    const toSign = await prepareTxs(newMembers,accounts[0]);
                    const requestParams = [toSign];
                    const request = formatJsonRpcRequest("algo_signTxn", requestParams);
                    try{
                        if(request){
                            setEncryptionFlow(1);
                            Snack('Go to your mobile wallet and approve the request!',2000,null,'info',true,null);
                        }
                        const result = await connector.sendCustomRequest(request);
                        if(result&&cb)cb(toSign);       
                    }catch(e){
                        Snack(e.message,2000,null,'error',true,null);
                        setEncryptionFlow(0);
                    }
                }else {
                    Snack('Please add TestNet account in algosigner.',4000,null,'info',true,{toastId:'add-testnet'});
                    if(cb)cb([]);
                }
                break;
            }
            default:break;
        };
    };

    const signTx=async(toSign,newMembers)=>{
        try{
            switch(mode){
                case ALGO_SIGNER:{
                    let algoSigner=window.AlgoSigner;
                    const signed=await algoSigner.signTxn([...toSign]);
                    if(signed.length){
                        let passcodes = signed.map((passcode, i) => { 
                            console.log('passcode', passcode.blob.slice(0,32))
                            return { 
                                'firstName': newMembers[i].firstName,
                                'lastName': newMembers[i].lastName,
                                'phoneNumber': newMembers[i].phoneNumber,
                                'language': newMembers[i].language,
                                'passcode': passcode.blob.slice(0,32),
                                'representative': newMembers[i].representative,
                                'fiG':newMembers[i].fiG
                            }
                        });
                        return passcodes;
                    }
                    break;
                }
                case ALGORAND_MOBILE:{
                    if(toSign.length){
                        let passcodes = toSign.map((passcode, i) => { 
                            console.log('passcode', passcode.blob.slice(0,32))
                            return { 
                                'firstName': newMembers[i].firstName,
                                'lastName': newMembers[i].lastName,
                                'phoneNumber': newMembers[i].phoneNumber,
                                'language': newMembers[i].language,
                                'passcode': passcode.txn.slice(0,32),
                                'representative': newMembers[i].representative,
                                'fiG':newMembers[i].fiG
                            }
                        });

                        return passcodes;
                    }
                    break;
                }
                default:break;
            }
        }catch(e){
            setEncryptionFlow(0);
            return [];
        };
    };

    const SaveMemberandInvite = (hasNoWhatsappMembers=[],show) => {
        const hasCachedMembers=Cache.getItem('newMembers');
        const membersToCache=hasCachedMembers?[...hasCachedMembers,...pages,...hasNoWhatsappMembers]:[...pages,...hasNoWhatsappMembers];
        Cache.setItem('newMembers',membersToCache);
        show&&dispatch(setMessage('inviteMsg',true));
    };

    const handleSendInvite=()=>{
        CheckIfPhoneNumbersHaveBeenRegistered(newMembers).then((checkedNumbers) => {
            const removeNull = checkedNumbers.filter((el) => el != null);
            setCheckedItems(removeNull);

            if (!removeNull.length) {
                // approve a sk sign on each new member and get 32 byte passcode!
                prepareTx(newMembers, wallet.address,(toSign)=>{
                    if(toSign.length){
                        setEncryptionFlow(1);

                        signTx(toSign,newMembers).then(passcodes=>{
                            if(passcodes.length){
                                let hasWhatsapp=[];
                                let hasNoWhatsapp=[];
                                passcodes.forEach(p=>{
                                    const passcode={...p,representativeOff:[]}
                                    if(!passcode.representative.hasOwnProperty('phone'))hasWhatsapp.push(passcode);
                                    else hasNoWhatsapp.push(passcode);
                                });
                                if(hasWhatsapp.length){
                                    const params={
                                        queryStringParameters: {  
                                            passcodes: JSON.stringify(hasWhatsapp),
                                        }
                                    };
                                    setEncryptionFlow(2);
                                    
                                    dispatch(createWalletandEncrypt(params,(response)=>{
                                        if(response.length){
                                            setPages([...pages,...response]);
                                            dispatch(createMembers(response)).then(success=>{
                                                if(success){
                                                    //dispatch(getMembers());
                                                    setEncryptionFlow(3);
                                                    Snack(`Done. ${response?.length} ${response?.length > 1 ? 'farmers have' : 'farmer has'} been registered(has whatsapp).`, 6000, null, 'success', true);
                                                    setNewMembers([{ ...Initial }])
                                                    setAgree(false);
                                                }else Snack('Registration failed.Please try again.', 4000, null, 'error', true);
                                            });
                                        }
                                    }));
                                }
                                if(hasNoWhatsapp.length){
                                    let memList=[...membersList];
                                    let updatableMembers=[];
                                    
                                    hasNoWhatsapp.forEach((mem1,i)=>{
                                        const targetMem=memList.findIndex(mem2=>mem2.phoneNumber===mem1.representative.phone);
                                        if(memList[targetMem].representativeOff)memList[targetMem].representativeOff.push({name:mem1.firstName+' '+mem1.lastName,phone:mem1.phoneNumber});
                                        else memList[targetMem].representativeOff=[{name:mem1.firstName+' '+mem1.lastName,phone:mem1.phoneNumber}];
                                        
                                        const params={
                                            phoneNumber: memList[targetMem].phoneNumber,
                                            representativeOff:memList[targetMem].representativeOff
                                        };
                                        updatableMembers.push(params);
                                    });
                                    dispatch(createMembers(hasNoWhatsapp)).then(success=>{
                                        if(success){
                                            dispatch(updateMembers(updatableMembers));
                                            Snack(`Done. ${hasNoWhatsapp?.length} ${hasNoWhatsapp?.length > 1 ? 'farmers have' : 'farmer has'} been registered(has no whatsapp).`, 6000, null, 'success', true);
                                            setNewMembers([{ ...Initial }]);
                                            setAgree(false);
                                            SaveMemberandInvite(hasNoWhatsapp,!hasWhatsapp.length);
                                            !hasWhatsapp.length&&onClose();
                                        }else Snack('Registration failed.Please try again.', 4000, null, 'error', true);
                                    });
                                }
                            }
                        });
                    }
                })
            } else {
                Snack('These numbers are incorrect or already in use, please correct them before continuing.',6000,null,'warning',true);
            }
        }).catch(e => {
            if (e.errors && e.errors.length && e.errors[0].errorType === 'Unauthorized')
            Snack(`Sorry. Someone else already registered this phone number.`,6000,null,'warning',true);
        });
    };

    return (
       <Modal
            open={open}
            className={`${className?className:'register-modal'}`}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            onClose={(e,reason)=>{reason!=='backdropClick' && onClose()}}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            style:{backgroundColor:'#E0E0E0',opacity:'0.5'}
          }}
        >
           <>{ encryptionFlow === 0 ? <MemberTable 
                addMember={addMember}
                CheckedItems={CheckedItems}
                newMembers={newMembers}
                error={error}
                setError={setError}
                handleChange={handleChange}
                validateInput={validateInput}
                removeMember={removeMember}
                membersList={membersList}
                agree={agree}
                setAgree={setAgree}
                onClose={onClose}
                disableCondition={disableCondition}
                handleSendInvite={handleSendInvite}/> : null }
           { encryptionFlow === 1 ? <SignEncrypt /> :  null }
           { encryptionFlow === 2 ? <WaitForPincode /> : null }
           { encryptionFlow === 3 ? 
           <DistributePin 
           pages={pages} 
           onClose={onClose} 
           org={session['custom:companyName']} 
           printPages={printPages}/> : null }</>
        </Modal>
    );
};

export default MemberForm;