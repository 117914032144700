import React from 'react';
import DashboardInitial from './DashboardInitial';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FarmerDashboard from './FarmerDashboard';
import GifView from './GifView';
import GetStarted from './GetStarted';

const DashboardView = (props) => {
    const {isEditable,drawPolygon,setDrawPolygon,setSidebarCollapse,setIsEditable,fieldData,
        collapseCondition,getStart,hasNoMem}=props;

    const {membersList:{isRequesting:isRequestingMem},selectedMember} = useSelector(state => state.members);
    return (
        <Grid container className={`dashboard-view ${(collapseCondition||!selectedMember)?'sat-view':''}`}>
            {!collapseCondition?(selectedMember&&!getStart)?
            <FarmerDashboard 
            setDrawPolygon={setDrawPolygon}
            setSidebarCollapse={setSidebarCollapse}
            setIsEditable={setIsEditable}/>
            :
            <>
            <DashboardInitial/>
            {(!isRequestingMem && (getStart||hasNoMem))&&<GetStarted hasNoMem={hasNoMem}/>}
            </>:null}
            {collapseCondition&&
            <GifView
            isEditable={isEditable}
            setDrawPolygon={setDrawPolygon} 
            setSidebarCollapse={setSidebarCollapse}
            drawPolygon={drawPolygon}
            fieldData={fieldData}
            setIsEditable={setIsEditable}/>}
        </Grid>
    );
};

export default DashboardView;