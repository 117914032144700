import React,{useState} from 'react';
import {Button} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ASSETS_BY_ID } from '../../constants';
import { formatNumberToSmallScale } from '../../helpers';

const WalletIcon=({onClick})=> {
    const {session,wallet}=useSelector(state=>state.user.userInfo);
    const [conversion,setConversion]=useState(false);

    const getCHT=()=>{
        let cht=0;

        const hasCHTAsset=wallet.assets.filter(asset=>ASSETS_BY_ID[asset['asset-id']]==='CHT');
        if(hasCHTAsset.length)cht=hasCHTAsset[0].amount
        
        return cht;
    };

    return (
        <div className='wallet-oval' >
            <Button style={{width:'90px',padding:'8px 4.5px'}} size='large' onClick={()=>setConversion(!conversion)}>
                <span className='cht'>{conversion?`₹ ${formatNumberToSmallScale(getCHT()*100)}`:`${formatNumberToSmallScale(getCHT())} CHT`}</span>
            </Button>
            <Button className='wallet-oval-label connected' onClick={()=>onClick()} size='large'>
                <div className='wallet-oval-label-name'>{session['custom:companyName']}</div>
            </Button>
        </div>
    );
};

export default WalletIcon;