import React,{ useState} from 'react';
import {Grid, Button, Typography, CircularProgress} from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { CustomModal } from './CustomModal';
import { removeMember } from '../../actions/members';

const DeleteMemberConfirmModal=(props)=>{
    const {confirmDelete,onClose,memberInfo}=props;

    const [request, setRequest ]= useState(false);
    const dispatch=useDispatch();

    const handleDelete= ()=>{
        setRequest(true);
        dispatch(removeMember(memberInfo.phoneNumber,(success)=>{
            if(success)onClose(); 
        }));
    };
    
    return(
        <CustomModal open={confirmDelete} onClose={onClose} className='check-modal'>
            <CustomModal.Header onClose={onClose}>
                <Typography variant='body1' id='task-title'>DELETE MEMBER</Typography> 
                </CustomModal.Header>
            <CustomModal.Body>
                <Grid container justifyContent='center' alignItems='center' direction='column'>
                    {!request ?
                    <Typography variant='body1' >Are you sure you want to delete <b>{memberInfo.name}</b> from Chitta?</Typography>
                    : <CircularProgress/>}
                </Grid>
            </CustomModal.Body>            
            <CustomModal.Footer className='has-border'>
                <Button className='btn' onClick={() => handleDelete()}>Yes, delete {memberInfo.name}</Button>
            </CustomModal.Footer>
        </CustomModal>
    );
};

export default DeleteMemberConfirmModal;