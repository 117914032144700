import React,{useState} from 'react';
import { useSelector } from 'react-redux';
import {Grid,LinearProgress} from '@material-ui/core';
import Header from './header/Header';
import Welcome from './Welcome';

const Introduction = () => {
    const[show,setShow]=useState(false);
    const {isRequesting} = useSelector(state => state.user.userInfo);

    return (
        <Grid container className='introduction'>
            <Header show={show} setShow={setShow}/>
            <Grid container justifyContent='center' className='hexagon-container'>
                <Grid className='hexagon'></Grid>
            </Grid>
            <Grid container className='intro-container' >
                {isRequesting&&<LinearProgress color="primary" style={{ width: '100%'}}/>}
                    <Welcome setShow={setShow}/>
            </Grid>
        </Grid>
    );
};

export default Introduction;
