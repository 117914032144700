import React from 'react';
import { Grid, Typography,IconButton, Tooltip } from '@material-ui/core';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useDispatch} from 'react-redux';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import {setSelectedMember } from '../../../actions/members';

const FarmerCard = (props) => {
    const { member ,setGetStart}=props;

    const dispatch=useDispatch();
    const fields = member.fields.items;
    
    const getUnverifiedFieldCount=()=>{
        let count=0;
        count=fields.filter(f=>!f.verified).length;
        
        return count;
    };

    return (
        <Grid container className='card' onClick={()=>{setGetStart(false);dispatch(setSelectedMember(member.phoneNumber))}}>
            <Grid item id='first'>
                <IconButton><LocalOfferIcon id='tag-icon'/></IconButton>
            </Grid>
            <Grid item className='second'>
                <Typography id='name'>{member.name}</Typography>
                <Typography id='loc'>{fields.length !== 0 ? fields[0].placeDescription.split(',')[0] : 'Unknown. Please add a field.'}</Typography>
            </Grid>
            <Grid item style={{height:'100%'}}>
                {(member.representative&&member.representative.name)?
                <Tooltip title={member.representative.name} arrow><IconButton style={{marginTop:'7px'}}><DeviceUnknownIcon id='no-whatsapp'/></IconButton></Tooltip>:null}             
            </Grid>
            <Grid container className={`third ${(member.representative && member.representative.name)?'has-rep':''}`}>
                {getUnverifiedFieldCount()?<Typography id='count'>{getUnverifiedFieldCount()}</Typography>:null}
            </Grid>
        </Grid>
    );
};

export default FarmerCard;