import { REQUESTING_FLIGHT_LIST, SET_FLIGHT_LIST } from "../constants/action-types";

const initialState={
    flightList:{
        isRequesting:false,
        list:[]
    }
};

export default (state = initialState, action) =>{
    switch(action.type){
        case REQUESTING_FLIGHT_LIST:{
            const loading=action.loading?action.loading:false;
            
            state={
                ...state,
                flightList:{
                    isRequesting:loading,
                    list:[]
                }
            };
            break;
        }
        case SET_FLIGHT_LIST:{
            const payload=action.flights?action.flights:[];

            state={
                ...state,
                flightList:{
                    isRequesting:false,
                    list:payload
                }
            };
            break;

        }
    };

    return state;
};