import React from 'react';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import InfoPop from '../widgets/InfoPop';

const UseMnemonic = (props) => {
    const { width, skipInfoPop, setMnemonic, margin } = props;

    const isRequesting = useSelector(state => state.members.membersList.isRequesting);

    return (
        <>
            <Grid container direction='row' justifyContent='space-around' alignItems='center' style={{ margin: '1rem 0' }}>
                <TextField
                    multiline
                    id='text-field'
                    InputProps={{className: 'input-css'}}
                    rows={7}
                    style={{ width: width, ...margin && { margin: margin } }}
                    onChange={(e) => setMnemonic(e.target.value)}
                    label={<span className={`label`}>Type your mnemonic phrase</span>}
                />
                {!skipInfoPop ?
                    <InfoPop
                        popperContent={<Typography variant='body2' className="bubble">A mnemonic seed phrase is a 25 word combination representing your secret key. If you do not have one, Create a New Account. We will automatically create one for you.</Typography>}
                        popperTitle={'Create a wallet'}
                        popperClassName={'signUp-popper'}/>
                    : null}
            </Grid>
            {isRequesting && <LinearProgress color="secondary" style={{ width: '100%' }} />}
        </>
    );
};

export default UseMnemonic;