import { Cache } from "aws-amplify";
import { REQUESTING_MEMBERS_LIST, SET_MEMBERS_LIST, ON_SIGN_OUT,SET_SELECTED_MEMBER,
    SET_SELECTED_FIELD_ID, SET_SELECTED_FEATURE,DELETE_MEMBER, DELETE_FIELD, SET_SEARCH_CATEGORY, SET_SEARCH_TEXT,SET_SELECTED_CROP,SET_SELECTED_OPTION} from "../constants/action-types";
import { groupBy } from "../helpers";

const initialState = {
    membersList: {
        isRequesting: false,
        list: [],
        memberById:{}
    },
    fieldsList:{
        isRequesting:false,
        list:[],
        fieldByFarmerId:{},
        notesList:[]
    },
    selectedMember:'',
    selectedFieldId:'',
    selectedFeature:[],
    searchCategory:{category:"field activity",subCategory:"crop"},
    searchText:'',
    selectedOption:{},
    selectedCrop:null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUESTING_MEMBERS_LIST: {
            state = { 
                ...state, 
                membersList: { ...state.membersList, isRequesting: action.payload },
                fieldsList:{...state.fieldsList,isRequesting:action.payload}
            };
            break;
        }
        case SET_MEMBERS_LIST: {
            const payload = action.payload ? action.payload : [];
            let memberListByPhn={};

            const fieldsList=payload.reduce((acc,cur)=>{
                let curItems=cur.fields.items.map(e=>{
                    return{
                        farmerName:cur.name,
                        ...e
                    }
                });
                return [...acc,...curItems]
            },[]);

            const notesList=fieldsList.reduce((acc,cur)=>{
                let curItems=[];
                if(cur.hasOwnProperty('notes')){
                    curItems=cur.notes.filter(note=>note.active).map(e=>{
                        return{
                            farmerId:cur.farmerID,
                            fieldId:cur.id,
                            ...e
                        }
                    });
                };
                
                return [...acc,...curItems]
            },[]);
                        
            const listByPhn=groupBy(fieldsList,'farmerID');
            payload.forEach(mem=>memberListByPhn[mem.phoneNumber]=mem);

            state = {
                ...state,
                membersList: {
                    ...state.membersList,
                    isRequesting: false,
                    list: [...payload],
                    memberById:memberListByPhn
                },
                fieldsList:{
                    ...state.fieldsList,
                    isRequesting:false,
                    list:fieldsList,
                    fieldByFarmerId:listByPhn,
                    notesList
                }
            };
            break;
        }
        case DELETE_MEMBER:{
            if(!action.payload.phone)return;
            const{phone,hasRep}=action.payload;

            let memList= state.membersList.list.filter(mem=>mem.phoneNumber!==phone);
            let memberById=state.membersList.memberById;
            delete memberById[phone];

            if(hasRep){
                const targetMem=memList.findIndex((mem)=>mem.representativeOff&&mem.representativeOff.some(m=>m.phone===phone));
                memList[targetMem].representativeOff=memList[targetMem].representativeOff.filter(mem=>mem.phone!==phone);
                memberById[hasRep]={
                    ...memberById[hasRep],
                    representativeOff:memberById[hasRep].representativeOff.filter(mem=>mem.phone!==phone)
                }
            };
            
            const allFields=state.fieldsList.list.filter((field)=>field.farmerID!==phone);
            const allNotes=state.fieldsList.notesList.filter((note)=>note.farmerID!==phone);
            const fieldById=state.fieldsList.fieldByFarmerId;
            delete fieldById[phone];

            state={
                ...state,
                membersList:{
                    ...state.membersList,
                    list:[...memList],
                    memberById:{...memberById}
                },
                fieldsList:{
                    ...state.fieldsList,
                    fieldByFarmerId:{...fieldById},
                    list:[...allFields],
                    notesList:[...allNotes]
                }
            };

            Cache.setItem('membersWithNotes',memList);
            break;
        }
        case DELETE_FIELD:{
            if(!(action.payload.farmerID||action.payload.fieldID))return;

            const {farmerID,fieldID}=action.payload;
            let memList=state.membersList.list;
            let memberById=state.membersList.memberById;

            let targetIndex=memList.findIndex(item=>item.phoneNumber===farmerID);
            memList[targetIndex].fields.items=memList[targetIndex].fields.items.filter(item=>item.id!==fieldID);
            memberById[farmerID]={
                ...memberById[farmerID],
                fields:{
                    ...memberById[farmerID].fields,
                    items:memberById[farmerID].fields.items.filter(item=>item.id!==fieldID)
                }
            };

            let allFields=state.fieldsList.list.filter(item=>item.id!==fieldID);
            let allNotes=state.fieldsList.notesList.filter(item=>item.fieldId!==fieldID);
            let fieldById=state.fieldsList.fieldByFarmerId;
            let hasField=fieldById[farmerID];
            if(hasField.length>1)fieldById[farmerID]=fieldById[farmerID].filter(item=>item.id!==fieldID);
            else delete fieldById[farmerID];

            state={
                ...state,
                membersList:{
                    ...state.membersList,
                    list:[...memList],
                    memberById:{...memberById}
                },
                fieldsList:{
                    ...state.fieldsList,
                    fieldByFarmerId:{...fieldById},
                    list:[...allFields],
                    notesList:[...allNotes]
                }
            };

            Cache.setItem('membersWithNotes',memList);
            break;
        }
        case SET_SELECTED_MEMBER:{
            const farmerId=action.farmerId?action.farmerId:'';
            state={...state,selectedMember:farmerId};
            break
        }
        case SET_SELECTED_FIELD_ID:{
            const fieldId = action.fieldId?action.fieldId:'';
            state={...state,selectedFieldId:fieldId};
            break
        }
        case SET_SELECTED_FEATURE:{
            const feature = action.feature?action.feature:[];
            state={...state,selectedFeature:feature};
            break
        }
        case SET_SEARCH_CATEGORY:{
            const payload=action.payload?action.payload:initialState.searchCategory;

            state={...state,searchCategory:payload};
            break;
        }
        case SET_SEARCH_TEXT:{
            const payload=action.payload?action.payload:initialState.searchText;

            state={...state,searchText:payload.trim()};
            break;
        }
        case SET_SELECTED_OPTION:{
            const payload=action.payload?action.payload:initialState.selectedOption;

            state={...state,selectedOption:payload};
            break;
        }
        case SET_SELECTED_CROP:{
            const payload=action.payload?action.payload:initialState.selectedCrop;

            state={...state,selectedCrop:payload};
            break;
        }
        case ON_SIGN_OUT: {
            state = { ...initialState };
            break;
        }
    };

    return state;
};