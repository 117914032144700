import React, { useState, useEffect } from 'react';
import {Grid,Button,Link,Typography,MenuItem,TextField } from '@material-ui/core';
import { useDispatch, useSelector} from 'react-redux';
import { MANUAL, WALLET_OPTIONS,ALGO_SIGNER} from '../../../constants';
import { setConnectMethod ,connectWallet, setAccounts} from '../../../actions/wallet';
import config from '../../../constants/config';
import { isAlgoSignerAvailable,downloadAlgoSigner,getTestNetAccounts} from '../../../helpers/algorand';
import { CustomModal } from '../../widgets/CustomModal';

const WalletOptions = (props) => {
    const {handleClose,setShow}=props;

    const dispatch=useDispatch();
    const [ selected, setSelected ] = useState('');
    const accounts=useSelector(state=>state.user.accounts);

    useEffect(()=>{
        getTestNetAccounts((acc)=> dispatch(setAccounts(acc)));
    },[]);

    const handleMethodSelection=(methodId,selected)=>{
        const cb=(show)=>{
            if(show)setShow(true);
            else handleClose();
        };
        dispatch(setConnectMethod(methodId));
        (methodId!==MANUAL)&& dispatch(connectWallet(methodId,selected,cb));
    };

    return (
    <>
        <CustomModal.Header onClose={handleClose} className='no-sub'>
            <Typography variant='body1' id='task-title'>SELECT A CONNECT METHOD</Typography> 
        </CustomModal.Header>
        <CustomModal.Body>
            <Grid container direction='column' className='wallet-options'>
            {WALLET_OPTIONS.map((option, i) =>{
                if(i===0){
                    if(isAlgoSignerAvailable()){
                        WALLET_OPTIONS[0].subLabel = '';
                        WALLET_OPTIONS[0].clickable=true;
                    }else WALLET_OPTIONS[0].subLabel = downloadAlgoSigner();
                }
                return(
                    <Grid container key={option.id} className='menu-item' direction='column'>
                        <Grid className='item'>
                            <span className='icon'>{option.icon}</span>
                            <span id='option' style={{...option.style&&option.style,fontWeight:600}} onClick={() =>option.clickable?handleMethodSelection(option.id):{}}>{option.label}</span>
                            <span style={{...option.subLabelStyle}}>{option.subLabel}</span>
                            {option.id===MANUAL&&<p className='sub-label'>send transactions have been disabled.</p>}
                            {(option.id===ALGO_SIGNER&&accounts.length > 1)&&
                            <TextField 
                                type="text"
                                id='text-field'
                                select
                                fullWidth
                                label={<span className='label'>You have multiple accounts, select the account to use.</span>}
                                name='account' 
                                value={selected} 
                                size="small"
                                onChange={(e) => setSelected(e.target.value)}>
                                {accounts.map((a,i) => (
                                    <MenuItem key={i} value={i} onClick={() =>handleMethodSelection(ALGO_SIGNER,i)}>
                                    {a.address}
                                    </MenuItem>
                                ))}
                            </TextField>}
                        </Grid>
                    </Grid>
                )})}
            </Grid>
        </CustomModal.Body>
        <CustomModal.Footer className='has-border'>
            <Button className='btn'>
                <Link href={config.links.NEW_TO_BLOCKCHAIN} target="_blank" className='link-btn'>
                New to blockchain?
                </Link>
            </Button>
        </CustomModal.Footer>
    </>);
};

export default WalletOptions;