import React from 'react';
import { Grid} from '@material-ui/core';
import { useSelector } from 'react-redux';
import FarmerCard from './FarmerCard';
import {LinearLoader} from '../../widgets/Loader';
import { searchWord } from '../../../helpers';
import { ASSETS_BY_ID } from '../../../constants';

const FarmersList = ({setGetStart}) => {
    const {membersList:{isRequesting, list:membersList},fieldsList:{list:fieldsList,notesList},
    searchCategory,searchText} = useSelector(state => state.members);
    
    let searchedMemList=[...membersList];
    if(searchCategory&&searchText.length>0){
        if(searchCategory.category==='farmers'&&searchCategory.subCategory==='farmer name')
            searchedMemList=searchedMemList.filter(e => searchWord(e.name.trim(), searchText));
        if(searchCategory.category==='fields'&&searchCategory.subCategory==='field name'){
            let filteredFarmerIds=fieldsList.filter(e => searchWord(e.fieldName.trim(), searchText)).map(e1=>e1.farmerID);
            searchedMemList=searchedMemList.filter(e =>filteredFarmerIds.includes(e.phoneNumber));
        };
        if(searchCategory.category==='field activity'&&searchCategory.subCategory==='crop'){
            let filteredFarmerIds=notesList.filter(e => searchWord(ASSETS_BY_ID[e.crop].trim().toLowerCase(), searchText)).map(e1=>e1.farmerId);
            searchedMemList=searchedMemList.filter(e =>filteredFarmerIds.includes(e.phoneNumber));
        };
    };

    return (
        <Grid container className={`farmer-list`}>
            {isRequesting && <LinearLoader/>}
            {(!isRequesting && searchedMemList.length)?searchedMemList.map(member => <FarmerCard key={member.id} member={member} setGetStart={setGetStart}/>)
            :<Grid container id='no-farmer'>{`No farmer found${(searchCategory.category==='field activity'&&searchCategory.subCategory==='crop')?` with active ${searchText}`:''}.`}</Grid>}
        </Grid>
    );
};

export default FarmersList;