import React, { useState } from 'react';
import { Popper, Fade } from '@material-ui/core';
import { useSelector } from 'react-redux';

const InfoPop =(props) =>{
  const{className,popperContent,popperTitle,popperClassName}=props;

  const [anchorEl, setAnchorEl ] = useState(null);
    const theme=useSelector(state=>state.user.theme);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return (
    <div className={className||""}>
      <span className='info-pop-over' aria-describedby={id} onClick={handleClick} title={popperTitle||'infoPopOver'} >R</span>
      <Popper id={id} open={open} className={`${popperClassName||""} ${theme}`} anchorEl={anchorEl} transition style={{ zIndex: 1500}} >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            {popperContent}
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default InfoPop;