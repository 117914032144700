import React, { useState } from 'react';
import { Cache } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import {Grid, Button, Typography,CircularProgress } from '@material-ui/core'
import { Snack } from '../widgets/Toast';
import { CustomModal } from '../widgets/CustomModal';
import { sendInviteMessages } from '../../actions/members';
import { setMessage } from '../../actions/user';

const SendInvites=(props)=> {
    const {check, hasPrintedNewMembers,onClose}=props;
    
    const [request, setRequest ]= useState(false)
    const{userInfo:{session}}=useSelector(state=>state.user);
    const memberById = useSelector(state => state.members.membersList.memberById);
    const dispatch=useDispatch();

    const handleInvite=()=>{
        setRequest(true)
        // remove critical data not required to send over API (pin,pk), add memberid, PO and rep.
        const params = { 
            queryStringParameters: { members: JSON.stringify(hasPrintedNewMembers.map(m => { 
                const memId =  memberById[m.phoneNumber];
                return {
                    name: m.firstName + ' ' + m.lastName,
                    PO: session['custom:companyName'],
                    memberId: memId?.id,
                    phoneNumber: m.phoneNumber,
                    language: m.language,
                    representative: m.representative,
                    representativeOff:null
                }
            }))}
        };
        
        dispatch(sendInviteMessages(params,(success)=>{
            if(success){
                Snack(`Great.`, 6000, null, 'success', true);
                Cache.removeItem('newMembers');
                Cache.removeItem('toPrint');
                dispatch(setMessage('inviteMsg',false));
                setRequest(false);
                onClose();
            }else Snack('We were unable to send the messages, possibly you have already send it?', 4000, null, 'error', true);
        }));
    };

    return(<>
        <CustomModal open={Boolean(check)} onClose={onClose} className='check-modal'>
            <CustomModal.Header onClose={onClose}>
                <Typography variant='body1' id='task-title'>SEND INVITE MESSAGES TO REGISTER FIELDS.</Typography> 
                </CustomModal.Header>
            <CustomModal.Body>
                <Grid container justifyContent='center' alignItems='center' direction='column'>
                    { !request ? 
                        <>
                        <Typography variant='body1' ><b>Did each individual farmer agree to join Chitta and use the Whatsapp Chatbot?</b></Typography>
                        <Typography variant='caption' >Due to restrictions set by Meta you need to specifically ask each whatsapp user permission to send a whatsapp message.</Typography>
                        </>
                        : 
                        <CircularProgress />
                    }
                </Grid>
            </CustomModal.Body>            
            <CustomModal.Footer className='has-border'>
                <Button className='btn' onClick={() => handleInvite()}>Yes, Send messages</Button>
            </CustomModal.Footer>
        </CustomModal>
    </>);
};

export default SendInvites;