import React, { useState,useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactMapGL, {Source} from 'react-map-gl';
import { Editor, EditingMode, DrawPolygonMode } from 'react-map-gl-draw';
import { useDispatch,useSelector } from 'react-redux';
import {getEditHandleStyle,getFeatureStyle} from './StyleLayer';
import config from '../../constants/config';
import { setSelectedFeature } from '../../actions/members';
import { getAreaSize } from '../../helpers';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const EditField = (props) => {
    const {viewport,setViewport,polygons,features,setFieldData,drawPolygon}=props;
    
    const dispatch=useDispatch();
    const [mode,setMode]=useState(new EditingMode());
    const selectedFeature = useSelector(state => state.members.selectedFeature);
    const mapFeatures=drawPolygon?[...features,...selectedFeature]:selectedFeature;

    useEffect(()=>{
        if(drawPolygon)setMode(new DrawPolygonMode());
    },[drawPolygon]);

    const onUpdate = options => {
        if(drawPolygon&&options?.data?.length){
            let targetFeature=options.data[options.data.length-1];
            setFieldData({type:options.editType,size:0});
            
            if(options.editType==='addFeature'){
                dispatch(setSelectedFeature([...selectedFeature,targetFeature]));
                setFieldData({
                    type:options.editType,
                    size:getAreaSize(targetFeature)
                });
            }
        }else{
            if(options.editType==="movePosition"){
                setFieldData({
                    type:options.editType,
                    size:getAreaSize(options?.data[0])
                });
            }
            dispatch(setSelectedFeature(options?.data));
        }
    };

    return (
        <ReactMapGL
            {...viewport}
            width={'inherit'}
            height={'inherit'}
            mapStyle={config.map.MAP_STYLE_EDIT}
            onViewportChange={nextViewport => setViewport(nextViewport)}
            mapboxApiAccessToken={config.map.MAP_KEY}>
            <Source type="geojson" data={polygons}>  
                <Editor
                style={{width: '100%', height: '100%'}}
                clickRadius={12}
                mode={mode}
                onUpdate={onUpdate}
                editHandleShape={'circle'}
                selectedFeatureIndex={drawPolygon?null: 0}
                features={mapFeatures}
                featureStyle={getFeatureStyle}          
                editHandleStyle={getEditHandleStyle}/>
            </Source>
        </ReactMapGL>
    );
};

export default EditField;