import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Logo=()=>{
    return (
        <Grid container>
            <Grid item style={{ fontFamily: 'Chitta', cursor: 'pointer', fontSize: '2em', padding: '0.15em 0.25em 0.4em 0.75em' }}>W</Grid>
            <Grid>
                <Typography variant='h6' style={{ fontWeight: '700', cursor: 'pointer', marginBottom: '-0.75em' }}>chitta</Typography>
                <Typography variant='h6' style={{ fontWeight: '400', cursor: 'pointer' }}>network</Typography>
            </Grid>
            
        </Grid> 
    );
};

export default Logo;