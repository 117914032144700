import React,{useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid,Typography,Button,IconButton,ButtonGroup } from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { setSelectedFeature, setSelectedFieldId,updateField,createField,getMembers } from '../../../actions/members';
import moveCorner from '../../../../assets/images/MoveCorner.gif'
import add from '../../../../assets/images/AddLine.gif';
import moveShape from '../../../../assets/images/ChangePosition.gif';
import { Snack } from '../../widgets/Toast';
import { getCenter } from '../../../helpers';
import FieldUpdatePopup from '../../poppers/FieldUpdatePopup';
import DeleteFieldConfirmModal from '../../widgets/DeleteFieldConfirmModal';
import { setOverlay } from '../../../actions/user';

const GifView = (props) => {
    const{setDrawPolygon,setSidebarCollapse,isEditable,setIsEditable,drawPolygon,fieldData}=props;

    const [fieldUpdateEl,setFieldUpdateEl] = useState(null);
    const [confirmDelete,setConfirmDelete] = useState(false);

    const {membersList:{list:membersList,memberById},fieldsList:{list:fieldsList},
    selectedFieldId,selectedFeature,selectedMember} = useSelector(state => state.members);
    const wallet=useSelector(state=>state.user.userInfo.wallet);
    
    const dispatch=useDispatch();
    const selectedField=fieldsList.filter(f=>f.id===selectedFieldId)[0];
    const selectedFarmer = memberById[selectedMember];
    const disableCondition=drawPolygon && (!fieldData.type || fieldData.type!=='addFeature');
    const id = Boolean(fieldUpdateEl) ? 'field-update-drop' : undefined;

    // select the registered farmers of which this farmer is friend-of-farmer off.
    const optionlist = selectedFarmer ? membersList.filter(m => m.representative && m.representative.phone === selectedFarmer.phoneNumber ) : null

    const sumFieldSize = (sum, field) => sum + parseFloat(field.areaSize);

    const onUpdate=(e)=>{
        dispatch(setOverlay(true,!drawPolygon?'Updating':'Creating'));
        const afterSuccess=()=>{
            dispatch(getMembers());
            dispatch(setOverlay());
            setIsEditable(false);
            dispatch(setSelectedFieldId());
            dispatch(setSelectedFeature());
            setDrawPolygon(false);
            setSidebarCollapse(false);
        };

        if(!drawPolygon) {
            const feature=selectedFeature[0];
            const{lng,lat}=getCenter(feature);
            
            const params={
                queryStringParameters: {  
                    id: feature.properties.id,
                    farmerID: typeof e === 'string' ? e : selectedMember,
                    verified: feature.properties.TileGrid?true:false,
                    areaSize: fieldData.size,
                    totals: JSON.stringify({ farmers: membersList.length, fields: fieldsList.length, acres: fieldsList.reduce(sumFieldSize, 0) }),
                    contour: JSON.stringify(feature.geometry.coordinates[0]),
                    owner: wallet.address,
                    farmerWallet: selectedFarmer.owner,
                    lng,
                    lat
                }}
                
            const cb=()=>{
                afterSuccess();
                Snack('Great, the field boundaries are updated, activity on the field will now be monitored',4000,null,'success',true);
            };
            
            dispatch(updateField(params,cb));
        }
        else dispatch(createField()).then((success=>{
            if(success){
                Snack(`Done. ${selectedFeature?.length} ${selectedFeature?.length > 1 ? 'fields have' : 'field has'} been registered.`,4000,null,'success',true);
                afterSuccess();
            }     
        }));
    };

    const handleFieldUpdateEl = (event) => {
        setFieldUpdateEl(fieldUpdateEl ? null : event.currentTarget);
    };

    const cancelHandler =()=>{
        setIsEditable(false);
        dispatch(setSelectedFieldId());
        dispatch(setSelectedFeature());
        setSidebarCollapse(false);
        setDrawPolygon(false);
    };

    return (
        <Grid className='gif-dashboard'>
            <Grid className='gif-dashboard-header'>
                <Grid item style={{padding:'0 1.5rem'}}>
                    <Typography id='chitta-icon'>W</Typography>
                </Grid>
                <Grid item style={{padding:'0 1.5rem'}}>
                    <IconButton id='dash-btn' onClick={(e)=>{e.stopPropagation();cancelHandler()}}><ArrowBackIcon /></IconButton>
                </Grid>
            </Grid>
            <Grid container className='gif-dashboard-content'>
                <Grid className='content-header'>
                    <Typography variant='subtitle1'><b>{drawPolygon ? 'New field' : selectedField.fieldName}</b></Typography>
                    <Typography variant='body2' style={{marginBottom:'0.25rem'}}>Size: <b>{fieldData.size}</b> cents</Typography>
                    <ButtonGroup size="small" variant="contained" aria-label="split button" color='primary' style={{width:'150px'}} disabled={disableCondition}>
                        <Button onClick={(e)=>{e.stopPropagation();onUpdate(e)}} color='primary' style={{width:'110px',borderRightColor:disableCondition?'grey':'white'}} className={`${disableCondition?'disabled':''}`}>update</Button>
                        <Button
                        size="small"
                        color='primary'
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        style={{width:'40px'}}
                        className={`${disableCondition?'disabled':''}`}
                        onClick={(e)=>handleFieldUpdateEl(e)}>
                        <ArrowDropDownIcon />
                        {fieldUpdateEl&&
                        <FieldUpdatePopup
                            open={Boolean(fieldUpdateEl)} 
                            id ={id}
                            isEditable={isEditable}
                            cancelHandler={cancelHandler}
                            setFieldUpdateEl={setFieldUpdateEl} 
                            fieldUpdateEl={fieldUpdateEl}
                            setConfirmDelete={setConfirmDelete}/>}
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid container className='content-body'>
                    <Grid container direction='column'>
                        <Typography variant='caption' style={{width:'150px'}}>{drawPolygon ? '(1) Pick a starting point.' : '(1) Click corner to move.'}</Typography>
                        <img src={moveCorner} height='100px' width='150px' alt="loading..." style={{marginBottom:'0.5rem'}}/>
                    </Grid>
                    <Grid container direction='column'>
                        <Typography variant='caption' style={{width:'150px'}}>{drawPolygon ? '(2) Draw each corner of the field.' : '(2) Click line to add corner.'}</Typography>
                        <img src={add} height='100px' width='150px' alt="loading..." style={{marginBottom:'0.5rem'}}/>
                    </Grid>
                    <Grid container direction='column'>
                        <Typography variant='caption' style={{width:'150px'}}>{drawPolygon ? '(3)Close the shape by clicking the initial point.' : '(3) Click shape to move position.'}</Typography>
                        <img src={moveShape} height='100px' width='150px' alt="loading..." style={{marginBottom:'0.5rem'}}/>
                    </Grid>
                </Grid>
            </Grid>
            {confirmDelete&&<DeleteFieldConfirmModal open={true} onClose={()=>{setConfirmDelete(false);setSidebarCollapse(false);setIsEditable(false);}} selectedFieldId={selectedFieldId} fieldsList={fieldsList}/>}
        </Grid> 
    );
};

export default GifView;
