import React,{useState,useEffect} from 'react';
import {IconButton,TableCell,TableRow,TextField,FormControl,Select,MenuItem,Input,
    Typography, ListSubheader} from '@material-ui/core';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';
import { LANGUAGES } from '../../../constants';

const MemberRow = (props) => {
    const{CheckedItems,handleChange,validateInput,error,setError,newMembers,newMem,idx,
        removeMember,membersList}=props;
    
    const [check, setCheck] = useState(false);
    const [farmerMenu,setFarmerMenu] = useState(false);
    const session=useSelector(state=>state.user.userInfo.session);
    
    useEffect(() => {
        if(newMem.representative.phone){
            setCheck(true);
        }else setCheck(false);
    }, [newMem.representative]);

    const handleRemove=idx=>{
        removeMember(idx);
        if(error.includes(idx))setError(error.filter(i=>i!==idx));
    };

    const farmerOptions = [{ id: '0', name: session['custom:companyName'], phoneNumber: session['phone_number'] }, ...membersList.filter(item => (!item.representative || !item.representative.phone))];
    const isExisting = CheckedItems.includes(idx);
    const MenuProps = {
        PaperProps: {
            className:'farmer-menu'
        },
    };

    return (
        <TableRow className='table-body-row'>
            <TableCell id='label'>
                <TextField 
                type="text" 
                size="small"
                name={`firstName-${idx}`} 
                value={newMem.firstName}  
                onChange={handleChange}/>
            </TableCell>
            <TableCell id='label'>
                <TextField 
                type="text" 
                size="small"
                name={`lastName-${idx}`} 
                value={newMem.lastName}  
                onChange={handleChange}/>
            </TableCell>
            <TableCell id='label'>
                <FormControl>
                    <Select
                        name={`language-${idx}`}
                        size="small"
                        value={newMem.language}
                        onChange={handleChange}
                        className='dropdown'
                        input={<Input />}
                    >
                    {LANGUAGES.map(language => (
                        <MenuItem key={language.id} value={language.name}>
                            <span>{language.name}</span>
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell id='label'>
                <TextField                         
                    type="text" 
                    size="small"
                    name={`phoneNumber-${idx}`} 
                    value={newMem.phoneNumber} 
                    onChange={handleChange} 
                    style={{ border: isExisting? '3px solid #eb3c93' : null }}
                    onBlur={() => {
                    validateInput({ input: newMem.phoneNumber, index: idx });}}                      
                />                                    
                {error.includes(idx) ? <div style={{ color: '#EC3C93', fontSize: '0.75em'}}>Please verify phone no.</div> : null}
            </TableCell>
            <TableCell id='label'>
                <TextField 
                type="text" 
                name={`fiG-${idx}`}
                onChange={handleChange} 
                size="small"
                value={newMem.fiG}/>
            </TableCell>
            <TableCell id='label' style={{textAlign:'center'}}>
                {check ?
                <FormControl>
                    <Select
                        name={`representative-${idx}`}
                        size="small"
                        open={!newMem.representative?.phone || farmerMenu}
                        value={newMem.representative.phone?newMem.representative.phone:''}
                        onChange={handleChange}
                        onClick={()=>setFarmerMenu(true)}
                        className='dropdown'
                        MenuProps={MenuProps}
                        input={<Input />}>
                    <MenuItem value='remove' key='Remove' 
                    style={{borderBottom:'1px solid lightgray',fontWeight:'bold'}} 
                    onClick={(e)=>{e.stopPropagation();setCheck(false);setFarmerMenu(false)}}>Remove</MenuItem>
                    <ListSubheader value='remove' onClick={(e)=>e.stopPropagation()}><Typography variant='body2' style={{ padding: '1em 0',color:'#000000'}}>Select a friend-of-farmer as a representative of the farmer.</Typography></ListSubheader>
                    {farmerOptions.map(mem => (
                        <MenuItem key={mem.id} value={mem.phoneNumber} onClick={(e)=>{e.stopPropagation();setFarmerMenu(false)}}>
                            <span className='farmer-name'>{mem.name}</span>
                            <span style={{fontSize:'0.85rem'}}>{mem.phoneNumber}</span>
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                :<Checkbox id='check' checked={check} onChange={(e)=>setCheck(e.target.checked)}/>}
            </TableCell>
            <TableCell id='no-label-btn'>
                {(newMembers.length>1)?
                <IconButton onClick={() =>handleRemove(idx)}>
                    <HighlightOffIcon /></IconButton>:<IconButton><RadioButtonUncheckedIcon />
                </IconButton>}
            </TableCell>      
        </TableRow>
    );
};

export default MemberRow;