import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {TableBody,TableCell,TableContainer,TableHead,Table,Typography,Button,TableRow,Grid,} from "@material-ui/core";
import { CustomModal } from "../widgets/CustomModal";
import { getFlights } from "../../actions/flights";
import {LinearLoader} from "../widgets/Loader";

const SatDataForm = (props) => {
    const {open,onClose}=props;

    let DateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric',timeZone:'IST'};
    const dispatch=useDispatch();
    const {isRequesting,list:flightList}=useSelector(state=>state.flights.flightList);

    useEffect(()=>{
        if(!flightList.length) dispatch(getFlights());
    },[]);

    return (
        <CustomModal open={open} onClose={onClose} className="satellite-modal">
            <CustomModal.Header onClose={onClose}>
                <Typography variant="body1" id="task-title">Previous satellite overpasses</Typography>
                <Typography variant="body2" id="sub-title">Chitta analyses the field you registered after each overpass</Typography>
            </CustomModal.Header>
            <Table>
                <TableHead className='table-header'>
                    <TableRow>
                        <TableCell style={{textAlign:'center', width:'20%',paddingLeft:'2px'}} id="label">Grid</TableCell>
                        <TableCell style={{width:'22%'}} id="label">Date</TableCell>
                        <TableCell style={{width:'20%'}} id="label">Type</TableCell>
                        <TableCell style={{width:'12%'}} id="label">Cloud</TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <CustomModal.Body>
                {isRequesting?<LinearLoader minHeight={'inherit'}/>
                :<>{!flightList.length ? 
                    <Grid container id="no-field" style={{minHeight:'inherit'}}>No flights found.</Grid>
                    :
                    <TableContainer className="table-container">
                        <Table>
                            <TableBody className='table-body'>
                                {flightList.map((flight) => (
                                    <TableRow key={flight.id} className="table-body-row">
                                        <TableCell style={{textAlign:'center', width:'20%'}} id="label">{flight.grid}</TableCell>
                                        <TableCell style={{width:'22%'}} id='label'>{new Date(parseInt(flight.dt)*1000).toLocaleString('en-IN', DateOptions)}</TableCell>
                                        <TableCell style={{width:'20%'}} id="label">{flight.type}</TableCell>
                                        <TableCell style={{width:'12%'}} id='label'>{flight.eo?flight.eo === 'SAR' ? '-' : `${flight.eo}%`:'-'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                </TableContainer>}</>}
            </CustomModal.Body>
            <CustomModal.Footer className={`${isRequesting||!flightList.length ? 'has-border':""}`}>
                <Button className="btn" onClick={onClose}>Close</Button>
            </CustomModal.Footer>
        </CustomModal>
    );
};

export default SatDataForm;