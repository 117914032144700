import React, { useEffect, useState } from 'react';
import {IconButton, Typography, Grid} from '@material-ui/core';
import { Cache } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Print from '@mui/icons-material/Print';
import { OptInItems } from '../../../helpers';
import SendInvites from '../../forms/SendInvites';
import { setMessageCount} from '../../../actions/user';
import MemberForm from '../../forms/MemberForm';
import { handleOptIn } from '../../../actions/wallet';

const Messages= ()=> {
    const dispatch=useDispatch();

    const{userInfo:{wallet,session},message:{printMsg,inviteMsg,count}}=useSelector(state=>state.user);
    const [msg,setMsg]=useState({zero:true,optin:true,hasPage:true,invite:true});
    const[register,setRegister]=useState(false);
    const [check,setCheck]=useState(false);
    const hasPrintedNewMembers = Cache.getItem('newMembers');
    const notPrintedNewMembers = Cache.getItem('toPrint');

    useEffect(()=>{
        if(inviteMsg)setMsg({...msg,invite:true,hasPage:false});

    },[inviteMsg]);

    useEffect(()=>{
        if(printMsg)setMsg({...msg,hasPage:true});
        else if(hasPrintedNewMembers) setMsg({...msg,hasPage:false});

    },[printMsg]);

    useEffect(()=>{
        let messageCount=0;
        if(msg.zero&&!wallet.amount > 0) messageCount=messageCount+1;
        if(msg.optin&&OptInItems(session))messageCount=messageCount+1;
        if(msg.hasPage&&notPrintedNewMembers)messageCount=messageCount+1;
        if(msg.invite&&hasPrintedNewMembers)messageCount=messageCount+1;

        dispatch(setMessageCount(messageCount));
    },[msg.zero,msg.optin,msg.hasPage,msg.invite]);

    const handleRemove=type=>{
        setMsg({...msg,[type]:false});
    };

    return (
        <>              
        { msg.zero ? <Grid container className='message'>                
            { !wallet.amount > 0 ? <>
            <Grid item xs={11}><Typography variant="body1" className='message-content' onClick={() => alert('As Chitta is still in BETA, please go to https://bank.testnet.algorand.network and enter your public key to receive some coins. NEVER send actual money to this account!')}>To start, please top-up your wallet to at least 0.1 <b>TESTNET</b> ALGO</Typography></Grid>
            <Grid item xs={1} style={{textAlign:'end'}}><IconButton onClick={() => handleRemove('zero')} aria-label="close"><CloseIcon id='close-btn'/></IconButton></Grid>
            </> : handleRemove('zero')}          
        </Grid> : null} 
        { msg.optin ? <>                
            {OptInItems(session) ? <Grid container className='message'>
            <Grid item xs={11}><Typography variant="body1" className='message-content' onClick={() =>dispatch(handleOptIn())}>You can now exchange {OptInItems(session)}. Opt-in so they can be send to your wallet.</Typography></Grid>
            <Grid item xs={1} style={{textAlign:'end'}}><IconButton onClick={() => handleRemove('optin')} aria-label="close"><CloseIcon id='close-btn'/></IconButton></Grid>
            </Grid> : handleRemove('optin') }
        </> : null}
        { msg.hasPage ? <Grid container className='message'>                
            { notPrintedNewMembers ? <>
            <Grid item xs={11}><Typography variant="body1" className='message-content' onClick={()=>setRegister(true)}>Ok. When you are ready, click here to print the invitations. This is a requirement to complete the registration. Each page has the unique access code for digital wallet we created.</Typography></Grid>
            <Grid item xs={1} style={{textAlign:'end'}}><IconButton onClick={() => handleRemove('hasPage')} aria-label="close"><CloseIcon id='close-btn'/></IconButton></Grid>
            </> : handleRemove('hasPage')}          
        </Grid> : null}
        { msg.invite ? <Grid container className='message'>                
            { hasPrintedNewMembers ? <>
            <Grid item xs={10}><Typography variant="body1" className='message-content' onClick={() => setCheck(true)}> Congratulations. {count?count:hasPrintedNewMembers.length} new farm {count?count:hasPrintedNewMembers.length > 1 ? 'have' : 'has'} been registered. Handout each invitation. When done, click here to send each farmer a Whatsapp message.</Typography></Grid>
            <Grid item xs={2} style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                <Typography variant="body1" className='message-content' style={{lineHeight:'0.5'}}>{(notPrintedNewMembers)&&<span onClick={()=>setRegister(true)}><Print style={{ fontSize: 20 }} /></span>}</Typography>
                <IconButton onClick={() => handleRemove('invite')} aria-label="close"><CloseIcon id='close-btn'/></IconButton>
            </Grid>
            </> : handleRemove('invite')}          
        </Grid> : null}
        {register&&<MemberForm open={true} onClose={()=>setRegister(false)} printPages={register}/>}
        {check && <SendInvites check={check} hasPrintedNewMembers={hasPrintedNewMembers} onClose={()=>setCheck(false)}/>}
        </>       
    );
};

export default Messages;