import { API} from 'aws-amplify';
import {fetchGrid, filterByLastOverpass} from '../helpers';
import * as queries from '../../graphql/queries';
import { REQUESTING_FLIGHT_LIST, SET_FLIGHT_LIST } from '../constants/action-types';

export const requestingFlightList=(loading)=>({type:REQUESTING_FLIGHT_LIST,loading});

export const setFlightList=(flights)=>({type:SET_FLIGHT_LIST,flights});

export const getFlights=()=>async(dispatch,getState)=>{
    dispatch(requestingFlightList(true));
    const fieldList=getState().members.fieldsList.list;

    try{
        // define TileGrids by member fields
        const getGrids =fetchGrid(fieldList);
        
        let filterByGrid={or:[]};
        getGrids.forEach(grid=>filterByGrid['or'].push({ grid: {eq: grid} }));
        
        // fetch flights by grid
        const flightsByGrid = await API.graphql({ query: queries.listFlightss, variables: {filter:filterByGrid}})
        
        // sort list by datetime
        const sortByDatetime=flightsByGrid.data.listFlightss.items.sort((a,b)=>(parseInt(b.dt) - parseInt(a.dt)));
       
        // pick only last from each satType & tilegrid
        const slice = sortByDatetime.reduce(filterByLastOverpass, []);
        dispatch(setFlightList(slice));
    }catch(e){
        dispatch(requestingFlightList(false));
    } 
};
