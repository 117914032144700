import React from "react";
import "../../../assets/scss/overlay.scss";

const ScreenOverlay=(props)=>{
    return(
        <div className={`overlay ${props.show ? "show" : "hide"}`}>
            {props.children}
        </div>
    );
};

export const ScreenOverlayContent = ({ children }) => <div className="overlay-content">{children}</div>;
export const ScreenOverlayText = ({ children }) => <div className="overlay-text">{children}</div>;

ScreenOverlay.Content = ScreenOverlayContent;
ScreenOverlay.Text = ScreenOverlayText;

export default ScreenOverlay;