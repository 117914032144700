import React from 'react';
import {Button,IconButton,Typography} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Snack } from '../../../widgets/Toast';
import {CustomModal} from '../../../widgets/CustomModal';
import { signOut } from '../../../../actions/user';
import BalanceSheet from './BalanceSheet';

const AccountInfo = ({handleClose}) => {
    const dispatch=useDispatch();
    const {userInfo:{wallet}}=useSelector(state=>state.user);

    const copyToClipboard = (address) => {
        let textField = document.createElement("textarea");
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
    };
    
    const handleShowPK = () => {
        const CopyIcon = () => (<IconButton onClick={() => copyToClipboard(wallet.address)}>
        <FileCopyOutlinedIcon style={{ color: 'white' }} /></IconButton>);

        Snack(`${wallet.address}`, 12000, CopyIcon(), 'dark', false,{toastId: "pk"},true);
    };

    return (<>
        <CustomModal.Header onClose={handleClose} className='no-sub'>
            <Typography variant='body1' id='task-title'>WALLET</Typography> 
        </CustomModal.Header>
        <CustomModal.Body>
            <BalanceSheet/>
        </CustomModal.Body>
        <CustomModal.Footer>
            <Button id='pk' className='btn' onClick={handleShowPK}>Show Public Key</Button>
            <Button className='btn' onClick={()=>{dispatch(signOut(()=>handleClose()))}}>SignOut</Button>
        </CustomModal.Footer>
    </>);
};

export default AccountInfo;
