import React,{useState} from 'react';
import { Grid,IconButton } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from 'react-redux';
import '../../../assets/scss/dashboard.scss';
import SatData from './SatData';
import SatDataForm from '../forms/SatDataForm';
import DashboardView from './dashboard-view/DashboardView';
import MemberForm from '../forms/MemberForm';

const Dashboard = (props) => {
    const {hasNoMem,fieldData,isRequesting,setDrawPolygon,setSidebarCollapse,setIsEditable,isEditable,
        drawPolygon,getStart,setGetStart}=props;

    const [show, setShow] = useState(false);
    const [register, setRegister] = useState(false);
    const {selectedMember} = useSelector(state => state.members);

    const collapseCondition=(isEditable||drawPolygon);

    return (
        <Grid container className={`dashboard ${(!isRequesting && hasNoMem)?'has-no-mem':''}`}>
            <DashboardView
                setDrawPolygon={setDrawPolygon}
                setSidebarCollapse={setSidebarCollapse}
                setIsEditable={setIsEditable}
                isEditable={isEditable}
                drawPolygon={drawPolygon}
                hasNoMem={hasNoMem}
                getStart={getStart}
                collapseCondition={collapseCondition}
                fieldData={fieldData}/>
            {(!selectedMember||collapseCondition)&&<Grid container className='satellite-container'>
                <SatData
                    setShow={setShow}
                    collapseCondition={collapseCondition}
                    setGetStart={setGetStart}/>
                {hasNoMem &&
                    <Grid style={{width:'100%',paddingTop:'1.35rem'}} container justifyContent="center">
                        <IconButton id='sat-btn' title='add farmer' onClick={() => setRegister(true)}>
                            <AddIcon />
                        </IconButton>
                    </Grid>}
                {show && <SatDataForm open={show} onClose={() => setShow(false)}/>}
                {register && <MemberForm open={true} onClose={() => setRegister(false)}/>}
            </Grid>}
        </Grid>
    );
};

export default Dashboard;