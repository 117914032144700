import React from 'react'
import {
    TextField, Typography, LinearProgress, Grid, InputAdornment, Table, TableCell, TableBody,
    TableRow, IconButton
} from '@material-ui/core';
import ForumIcon from '@mui/icons-material/Forum';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RoomIcon from '@mui/icons-material/Room';
import { useSelector } from 'react-redux';
import InfoPop from '../widgets/InfoPop';

const SignUp = (props) => {
    const { signupValue, handleOnChange, mnemonic: Mnemonic, copyMnemonic, validateInput, error } = props;

    const isRequesting = useSelector(state => state.members.membersList.isRequesting);

    const mnemonicToTable = () => {
        const mnemonic = Mnemonic.split(" ");
        let newdata = [];

        for (var i = 0; i < mnemonic.length; i += 5) { // i+=3 can solve your problem
            let three = { first: mnemonic[i], second: mnemonic[i + 1], third: mnemonic[i + 2], fourth: mnemonic[i + 3], fifth: mnemonic[i + 4] };
            newdata.push(three);
        };

        return newdata;
    };

    return (
        <>
            {!copyMnemonic ?
                <Grid className='popper-item' container>
                    <Grid container className='form-field'>
                        <IconButton className='field-icon'>
                            <ForumIcon />
                        </IconButton>
                        <TextField
                            name='firstName'
                            size="small"
                            id='text-field'
                            label={<span className='label'>First name</span>}
                            value={signupValue.firstName}
                            className={`text-field`}
                            onChange={handleOnChange} />
                    </Grid>
                    <Grid container className='form-field'>
                        <IconButton className='field-icon'>
                            <ForumIcon />
                        </IconButton>
                        <TextField
                            name='lastName'
                            size="small"
                            id='text-field'
                            label={<span className='label'>Last name</span>}
                            value={signupValue.lastName}
                            className='text-field'
                            onChange={handleOnChange} />
                    </Grid>
                    <Grid container className='form-field'>
                        <IconButton className='field-icon'>
                            <RoomIcon />
                        </IconButton>
                        <TextField
                            required
                            name='companyName'
                            size="small"
                            id='text-field'
                            label={<span className='label'>Name of your digital cooperative</span>}
                            value={signupValue.companyName}
                            className='text-field'
                            onChange={handleOnChange}
                            error={error.companyName}
                            helperText={error.companyName ? "Digital cooperative name is required." : ""} />
                        <InfoPop
                            popperContent={<Typography variant='body2' className="bubble">Digital cooperative name is used to communicate with farmer.</Typography>}
                            popperTitle={'Digital cooperative'}
                            className="cn-info"
                            popperClassName={'signUp-popper'}
                        />
                    </Grid>
                    <Grid container className='form-field'>
                        <IconButton className='field-icon'>
                            <LocalPhoneIcon />
                        </IconButton>
                        <TextField
                            name='phonenumber'
                            error={error.phN}
                            className='text-field'
                            id='text-field'
                            size="small"
                            label={<span className='label'>Phone number</span>}
                            InputProps={{startAdornment: (<InputAdornment  position="start">
                            <span className='label'>+91</span></InputAdornment>) }}
                            helperText={error.phN ? "Please check your phone number. Only +91 numbers are allowed." : ""}
                            onChange={(e) => { handleOnChange(e); validateInput(e.target.value) }}
                        />
                    </Grid>
                </Grid>
                :
                <Grid className='popper-item pad' container>
                    <Table>
                        <TableBody>
                            {mnemonicToTable().map((w, i) =>
                                <TableRow>
                                    <TableCell id='text-field' align="left" >{(i * 5 + 1)} {w.first}</TableCell >
                                    <TableCell id='text-field' align="left" >{(i * 5 + 1) + 1} {w.second}</TableCell >
                                    <TableCell id='text-field' align="left" >{(i * 5 + 1) + 2} {w.third}</TableCell >
                                    <TableCell id='text-field' align="left" >{(i * 5 + 1) + 3} {w.fourth}</TableCell >
                                    <TableCell id='text-field' align="left" >{(i * 5 + 1) + 4} {w.fifth}</TableCell >
                                </TableRow>)}
                        </TableBody>
                    </Table>
                    <Typography variant='body2' id='text-field' style={{ paddingTop: '1em', width: '100%',textAlign:'center' }}>Please save the phrase carefully. We are NOT able to recover your account!</Typography>
                </Grid>
            }
            {isRequesting && <LinearProgress color="secondary" style={{ width: '85%', marginLeft: '10px' }} />}
            {false ? <div>Wait. Something went wrong. Please try again.</div> : null}
        </>
    );
};

export default SignUp;