import React,{ useEffect, useState}from "react";
import { Typography,Grid,Button } from "@material-ui/core";
import TypeAnimation from 'react-type-animation';

const data = [
    { ln: 'चित्तौड़ में आपका स्वागत है'},
    { ln: 'சிட்டாவுக்கு வருக'},
    { ln: 'ಚಿತ್ತಕ್ಕೆ ಸುಸ್ವಾಗತ'},
    { ln: 'చిట్టాకు స్వాగతం'},
];

const Initial = ({setShow}) => {
    const [ commodity, setCommodity ] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => setCommodity(Math.floor(Math.random() * Math.floor(data.length))), 4500);
        
        return () => clearInterval(interval);
    }, []);

    return(
        <Grid xs={10} sm={10} md={8} lg={5} item style={{marginTop:'6rem',display:'flex',flexDirection:'column',alignItems:'center'}}>
            <Typography variant='caption' style={{marginBottom:'3rem',fontSize:'0.8rem',fontWeight:'700'}}>{data[commodity].ln}</Typography>
            <Typography variant='h3' component='span' style={{marginBottom:'3rem',textAlign:'flex-start',fontWeight:'700'}}>
                Link farms to global markets and new ways of <TypeAnimation cursor={true} wrapper='span' sequence={['finance',5000,'agriculture',5000,'']}/>.
            </Typography>
            <Button className='wallet-oval-label' onClick={()=>setShow(true)}>
              <span style={{margin:'0 1rem'}}>Connect</span>
            </Button>
        </Grid>
    );
};

export default Initial;