import { createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { userMiddleWare } from '../middlewares';
import rootReducer from '../reducers';

const initialState = {};
let middleware=applyMiddleware(thunk,userMiddleWare);

const store = createStore(rootReducer, initialState,middleware);

export default store;