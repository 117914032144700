import React,{useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { Grid } from '@material-ui/core';
import SideBar from '../../side-bar/SideBar';
import Map from '../../maps/Map';
import { getMembers } from '../../../actions/members';
import Dashboard from '../../dashboard/Dashboard';

const Content = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMembers());
    }, []);
    
    const [sidebarCollapse, setSidebarCollapse] = useState(false);
    const [drawPolygon,setDrawPolygon]=useState(false);
    const [isEditable,setIsEditable]=useState(false);
    const [getStart,setGetStart]=useState(false);
    const [fieldData,setFieldData]=useState({type:'',size:0});
    const [openSearchBar,setOpenSearchBar]=useState(false);

    const membersList = useSelector(state => state.members.membersList.list);
    const {isRequesting} = useSelector(state => state.user.userInfo);

    useEffect(()=>{
        if(!drawPolygon)setFieldData({type:'',size:0});
    },[drawPolygon]);
   
    const hasNoMem = membersList.length === 0;

    return (
        <>
            <Dashboard 
            hasNoMem={hasNoMem}
            getStart={getStart}
            setGetStart={setGetStart}
            isRequesting={isRequesting}
            isEditable={isEditable}
            drawPolygon={drawPolygon}
            setDrawPolygon={setDrawPolygon}
            setSidebarCollapse={setSidebarCollapse} 
            setIsEditable={setIsEditable}
            fieldData={fieldData}/>
            {(!sidebarCollapse && !hasNoMem)&&
            <SideBar 
            drawPolygon={drawPolygon} 
            setDrawPolygon={setDrawPolygon} 
            setGetStart={setGetStart}
            sidebarCollapse={sidebarCollapse} 
            setSidebarCollapse={setSidebarCollapse} 
            isEditable={isEditable} 
            setIsEditable={setIsEditable}
            openSearchBar={openSearchBar}
            setOpenSearchBar={setOpenSearchBar}/>}
            <Grid  
            className={`map-view ${(!isRequesting && hasNoMem)?'has-no-mem':''}`}>
                <Map 
                hasNoMem={hasNoMem}
                setGetStart={setGetStart}
                drawPolygon={drawPolygon}
                setDrawPolygon={setDrawPolygon} 
                sidebarCollapse={sidebarCollapse}
                setSidebarCollapse={setSidebarCollapse}
                setFieldData={setFieldData}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                openSearchBar={openSearchBar}
                setOpenSearchBar={setOpenSearchBar}/>
            </Grid>
        </>
    );
};

export default Content;