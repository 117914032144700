import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import  { LinearInterpolator, WebMercatorViewport} from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton, LinearProgress, Typography,Tooltip} from '@material-ui/core';
import bbox from '@turf/bbox';
import {setSelectedFieldId, setSelectedMember,setSelectedFeature,setSearchCategory,setSearchText} from '../../actions/members';
import EditField from './EditField';
import FieldMap from './FieldMap';
import Legends from './Legends';
import WalletIcon from '../widgets/WalletIcon';
import { CustomModal } from '../widgets/CustomModal';
import AccountInfo from '../wallets/connected-wallet/AccountInfo';
import '../../../assets/scss/map-view.scss';
import FieldPopup from '../poppers/FieldPopup';
import MemberForm from '../forms/MemberForm';
import { ASSETS_BY_ID, CROP_COLORS } from '../../constants';
import SearchBar from '../side-bar/content/SearchBar';
import { getSequentialValue, searchWord } from '../../helpers';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = (props) => {
    const {sidebarCollapse,setSidebarCollapse,isEditable,setIsEditable,
        drawPolygon,setFieldData,setDrawPolygon,hasNoMem,openSearchBar,setOpenSearchBar} = props;

    let southIndVp={
        longitude:76.99751085819281,
        latitude:14.000041233686742,
        zoom:13,
        transitionInterpolator: new LinearInterpolator(),
        transitionDuration: 1000,
        mode: null,
    };

    const dispatch=useDispatch();

    const[register,setRegister]=useState(false);
    const[fieldEl,setFieldEl]=useState(null);
    const [initialVp, setInitialVp] = useState({...southIndVp});
    const [viewport, setViewport] = useState({...southIndVp});
    const [features, setFeatures] = useState([]);
    const [polygons, setPolygons] = useState({type:'FeatureCollection',features:[]});
    const [polygonsPoints, setPolygonsPoints] = useState({type:'FeatureCollection',features:[]});
    const [connectModal,setConnectModal] = useState(false);

    const fieldDrop = Boolean(fieldEl);
    const fieldDropId = fieldDrop ? 'field-drop' : undefined;

    const {selectedFieldId,selectedMember,selectedFeature,searchCategory,searchText,
        membersList:{list:membersList},fieldsList:{list:allFields,fieldByFarmerId,isRequesting,notesList},selectedCrop,selectedOption} = useSelector(state => state.members);

    const fieldsList=selectedMember?fieldByFarmerId[selectedMember]?fieldByFarmerId[selectedMember]:allFields:allFields;

    let searchedPolygons={...polygons};
    let searchedPolygonsPoints={...polygonsPoints};
    
    if(searchCategory&&searchText.length>0){
        if(searchCategory.category==='farmers'&&searchCategory.subCategory==='farmer name'){
            searchedPolygons.features=searchedPolygons.features.filter(e=>searchWord(e.properties.farmerName.trim(), searchText));
            searchedPolygonsPoints.features=searchedPolygonsPoints.features.filter(e=>searchWord(e.properties.farmerName.trim(), searchText));
        };
        if(searchCategory.category==='fields'&&searchCategory.subCategory==='field name'){
            searchedPolygons.features=searchedPolygons.features.filter(e=>searchWord(e.properties.FieldName.trim(), searchText));
            searchedPolygonsPoints.features=searchedPolygonsPoints.features.filter(e=>searchWord(e.properties.FieldName.trim(), searchText));
        };
        if(searchCategory.category==='field activity'&&searchCategory.subCategory==='crop'){
            let filteredFieldIds=notesList.filter(e => searchWord(ASSETS_BY_ID[e.crop].trim().toLowerCase(), searchText)).map(e1=>e1.fieldId);
            searchedPolygons.features=searchedPolygons.features.filter(e =>filteredFieldIds.includes(e.properties.id));
            searchedPolygonsPoints.features=searchedPolygonsPoints.features.filter(e =>filteredFieldIds.includes(e.properties.id));
        };
    };

    const searchedFeatureIds=searchedPolygons.features.map(f=>f.properties.id);

    useEffect(() => {
        handleSelection(selectedFieldId);
        
        if(selectedFieldId){
            setFieldData({type:'',size:fieldsList.filter(f=>f.id===selectedFieldId)[0].areaSize});
            getViewPort(selectedFieldId);
        }else {
            defaultVp();
            if(selectedFeature.length)dispatch(setSelectedFeature());
        }

    }, [selectedFieldId]);

    useEffect(()=>{
        if(membersList.length===1&&!fieldsList.length)setViewport(southIndVp);
    },[fieldsList.length]);

    useEffect(() => {
        if(fieldsList.length){
            let features=[];
            let points=[];
            
            fieldsList.forEach((f) => {
                // geojson has lnglat instead of latlng
                var date = new Date().toISOString().split('T')[0];
                let currentCrop=null;
                if(f.hasOwnProperty('notes') && !selectedOption.hasOwnProperty('id')){
                    let note = f.notes.filter(note=>note.active===true && date < note['EOS']);
                    currentCrop=note.length?note.length===1?note[0]['crop']:note.reduce((a,b)=>{return a['EOS']>b['EOS']?a:b})['crop']:null;
                };

                let feature={
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates":[JSON.parse(f.contour)],
                    },
                    "properties": {
                        "FieldName": f.fieldName,
                        "currentCrop":currentCrop?CROP_COLORS[currentCrop]:'default',
                        "Highlighted": false,
                        "ManualPoints": f.latlng,
                        "TileGrid": f.TileGrid,
                        "areaSize": f.areaSize,
                        "id": f.id,
                        "farmerId":f.farmerID,
                        "farmerName":f.farmerName,
                        "verified":f.verified,
                        "seqOpacity":(selectedOption.hasOwnProperty('id'))?getSequentialValue(selectedOption,f)[1]:1,
                        "seqColor":(selectedOption.hasOwnProperty('id'))?getSequentialValue(selectedOption,f)[0]:"default",
                        "selectedCrop":(selectedCrop && selectedCrop.fields.includes(f.id))?CROP_COLORS[selectedCrop.crop]:'default'
                    }
                };

                let point={
                    "type": "Feature",
                    "geometry": {
                        "type": "Points",
                        "coordinates": [JSON.parse(f.contour)[0][0],JSON.parse(f.contour)[0][1]]
                    },
                    "properties": {
                        "FieldName": f.fieldName,
                        "ManualPoints": f.latlng,
                        "TileGrid": f.TileGrid,
                        "areaSize": f.areaSize,
                        "id": f.id,
                        "farmerId":f.farmerID,
                        "farmerName":f.farmerName,
                        "verified":f.verified,
                        "defaultCount":'1'
                    }
                };

                features.push(feature);
                points.push(point);
            });
            
            let geoJSON = {};
            let geoJSON_points = {}
            geoJSON.type=geoJSON_points.type = "FeatureCollection";
            geoJSON.features = [...features];
            geoJSON_points.features = [...points];
     
            const [minLng, minLat, maxLng, maxLat] = bbox(geoJSON);
            const view = new WebMercatorViewport({ width: 800, height: 600 }); // central India: latitude: 78.310556, longitude: 21.125556
            const { latitude, longitude, zoom } = view.fitBounds([[minLng, minLat], [maxLng, maxLat]]);
            const vp={
                ...viewport,
                longitude,
                latitude,
                zoom: zoom > 19 ? 18.5 : zoom - 0.3,
                transitionInterpolator: new LinearInterpolator(),
                transitionDuration: 1000,
                mode: null,
            };

            setInitialVp({...vp});
            setViewport({...vp});
            setFeatures([...features]);
            setPolygons({...geoJSON});
            setPolygonsPoints({...geoJSON_points});
        }
    }, [fieldsList,fieldsList.length,selectedCrop,selectedOption.id]);

    const handleFieldEl = (event) => {
        setFieldEl(fieldEl ? null : event.currentTarget);
    };

    const getViewPort=fieldId=>{
        const coordinates=fieldsList.filter(f=>f.id===fieldId)[0].contour;
        
        let geoJSON = {
            type:"FeatureCollection",
            features : [{
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [JSON.parse(coordinates)]
                }
            }]
        };
        
        const [minLng, minLat, maxLng, maxLat] = bbox(geoJSON);
        const vp = new WebMercatorViewport({ width: 400, height: 300 });
        const {longitude, latitude, zoom} = vp.fitBounds([[minLng, minLat],[maxLng, maxLat]],{padding: 40});
        
        setViewport({
            ...viewport,
            longitude,
            latitude,
            zoom,
            transitionInterpolator: new LinearInterpolator(),
            transitionDuration: 1000
        });
    };

    const handleSelection=(fieldId)=>{
        const updatedFeature=[...features].map(item=>{
            if(fieldId===item.properties.id){
                let targetFeature={
                    ...item,
                    properties:{
                        ...item.properties,
                        Highlighted:!item.properties.Highlighted
                    }
                };
                dispatch(setSelectedFeature([targetFeature]));
                return targetFeature;
            }else return {
                ...item,
                properties:{
                    ...item.properties,
                    Highlighted:false
                }
            }
        });
        setPolygons({...polygons,features:updatedFeature});
        setFeatures(updatedFeature);
    };

    const defaultVp=()=>{
        setViewport({
            ...initialVp,
            transitionDuration: 1000
        });
    };

    const handleClick=(data)=>{
        const {fieldId,farmerId} = data;

        try{
            if(farmerId)dispatch(setSelectedMember(farmerId));
            dispatch(setSelectedFieldId((selectedFieldId===fieldId||!fieldId)?null:fieldId));
        }catch(e){
            defaultVp();
        }
    };

    const editBtnHandler=()=>{
        setIsEditable(!isEditable);
        setSidebarCollapse(true);
        if(isEditable){
            // dispatch(setSelectedFieldId());
            // dispatch(setSelectedFeature());
            setSidebarCollapse(false);
        }
    };

    const handleClose=()=>{
        setConnectModal(false);
    };

    const handleCancel=()=>{
        setDrawPolygon(false);
        setSidebarCollapse(false);
        dispatch(setSelectedFeature());
    };

    return (
        <div id='field-map'>
            {(isRequesting && !fieldsList.length) &&<LinearProgress color="primary" style={{ width: '100%'}}/>}
            {(sidebarCollapse &&!hasNoMem)&&
            <Grid item xs={2} sm={5} className={`side-bar ${openSearchBar?'has-search':''}`}>
            <Grid container className={`side-bar-header ${openSearchBar?'has-search':''}`}>
                <Grid item className='menu-container'>
                    <IconButton onClick={(e)=>{e.stopPropagation();setSidebarCollapse(!sidebarCollapse)}}><ListIcon id='menu-icon'/></IconButton>
                    <Typography variant='body2' style={{marginLeft:'0.85rem'}}>{!selectedMember?'FARMS':'FIELDS'}</Typography>
                </Grid>
                <Grid item>
                    <IconButton aria-describedby={fieldDropId} onClick={(e)=>{e.stopPropagation();!selectedMember?setRegister(true):handleFieldEl(e)}}>
                        <Tooltip title='add farms' arrow>
                            <AddIcon id='add-icon'/>
                        </Tooltip>
                            {fieldEl?
                            <FieldPopup 
                            id={fieldDropId} 
                            open={fieldDrop} 
                            fieldEl={fieldEl}
                            setSidebarCollapse={setSidebarCollapse} 
                            setDrawPolygon={setDrawPolygon}
                            setRegister={setRegister}
                            setIsEditable={setIsEditable}
                            setFieldEl={setFieldEl}/>:null}
                        </IconButton>
                    <IconButton 
                    title={!openSearchBar?'open search-bar':'close search-bar'}
                    onClick={()=>{
                        setOpenSearchBar(!openSearchBar);
                        if(openSearchBar){dispatch(setSearchCategory());dispatch(setSearchText());}
                    }}>
                        {!openSearchBar?<SearchIcon id='search-icon'/>:<CloseIcon id='search-icon'/>}
                    </IconButton>
                </Grid>
            </Grid>
            {(sidebarCollapse &&!hasNoMem) && openSearchBar && <SearchBar/>}
            </Grid>
            }
            <Grid item xs={10} sm={11} className='map-btn' onClick={(e)=>e.stopPropagation()}>
                <div className='main-header-wallet'>
                <WalletIcon onClick={()=>setConnectModal(!connectModal)}/>
                </div>
                {selectedFieldId &&searchedFeatureIds.includes(selectedFieldId)&& <Button onClick={editBtnHandler} className='edit-field-btn'>{!isEditable ? 'edit' : 'cancel'}</Button>}
                {drawPolygon && <Button onClick={handleCancel} className='edit-field-btn'>cancel</Button>}
            </Grid>            
            {(isEditable||drawPolygon)?
                <EditField
                viewport={viewport}
                setViewport={setViewport}
                features={fieldByFarmerId[selectedMember]?features:[]}
                polygons={{...polygons,features:selectedFeature}}
                drawPolygon={drawPolygon} 
                setFieldData={setFieldData}/>
                :<FieldMap
                polygonsPoints={searchedPolygonsPoints}
                viewport={viewport}
                setViewport={setViewport}
                polygons={searchedPolygons}
                handleClick={handleClick}/>
            }
            {selectedOption.id?<Legends selectedOption={selectedOption}/>:null}
            {connectModal && 
            <CustomModal open={connectModal} onClose={handleClose} className='connected-modal'>
                <AccountInfo handleClose={handleClose}/>
            </CustomModal>}
            {register&&<MemberForm open={true} onClose={()=>setRegister(false)}/>}
        </div>
    );
};

export default Map;