import React from 'react';
import {Grid,Typography} from '@material-ui/core';
import logo from '../../../../assets/images/clean-gradient.png'

const createPagesWithJS = (pages, org) => {
    // as we cannot use javascript within the class component, we need to create the pages before passing them in.
    const getLanguageWiseContent=(language,type,pin)=>{
        switch(language){
            case 'English':{
                switch(type){
                    case 'warning':return 'READ THIS DOCUMENT CAREFULLY';
                    case 'appName':return 'CHITTA';
                    case 'greeting':return 'Dear';
                    case 'wishes':return 'Congratulations. You are the owner of a digital Chitta wallet.';
                    case 'pincode':return <>pincode : {pin} &nbsp; <u><i>never share this code</i></u></>
                    case 'key':return 'KEY';
                    case 'whatCanYouDo':return 'What can you do with a digital wallet?';
                    case 'digitalWallet':return <>The wallet is like a free digital bank account. Use it with the Chitta Bot on Whatsapp. In the Chitta conversation, type <u>HELP</u> to discover new features. As you farm, Chitta will send rewards and labels of detected cultivations in your field to this wallet.</>;
                    case 'useYourWallet':return 'Use your new wallet to';
                    case 'uses':return ['Get crop insurance.','Get a crop loan.','Get investment loans that are uniquely tailored to your needs.',
                                        'Sell your crops on a global exchange, using the crop label of each cultivation.',
                                        'Sell your crops as a future before harvest.','Hold CHT as your ownership share of Chitta.'
                                    ];
                    case 'note':return <>* Ask the office manager of <b>{org}</b> for the available possibilities. Not all features may be open for you.</>;
                    case 'toGetRewards':return 'How can I receive rewards and tokens?';
                    case 'rewards':return <>To get any rewards and tokens, you need to register your fields. After registration, your {org} office manager has to check the field boundaries. If you are not receiving any rewards, please go to {org} and ask to complete the field check. It can take a few days before you get the rewards.</>;
                    case 'satellite':return 'You receive a token each time our satellites detect a new cultivation on your land. This can be a few days or a few weeks after planting. We will give you the amount of tokens proportional to the yield we estimate.';
                    case 'checkAcBal':return 'How can I check my account balance?';
                    case 'accBal':return <>Type <u>ACCOUNT</u> in the Chitta Bot conversation on Whatsapp.</>;
                    default:return ''
                }
            }
            case 'தமிழ் (Tamil)':{
                switch(type){
                    case 'warning':return 'இந்த பதிவை கவனமாக படிக்கவும்';
                    case 'appName':return 'சிட்டா';
                    case 'greeting':return 'வணக்கம்';
                    case 'wishes':return 'நீங்கள், டிஜிட்டல் சிட்டா வாலட்டின் உரிமையாளராகியதற்கு வாழ்த்துக்கள்.';
                    case 'pincode':return <>ரகசிய குறியீடு : {pin} &nbsp; <u><i>யாருடனும் பகிர வேண்டாம்</i></u></>;
                    case 'key':return 'பப்ளிக் கீ';
                    case 'whatCanYouDo':return 'நீங்கள் டிஜிட்டல் வாலட்டில் என்ன செய்ய முடியும்?';
                    case 'digitalWallet':return <>இந்த வால்ட், இலவச டிஜிட்டல் வங்கி கணக்கை போன்றது. சிட்டா பாட்(Chitta Bot) என்பதன் மூலம் வாட்ஸாப்(Whatsapp) செயலியில் பயன்படுத்தலாம்.சிட்டா உரையாடல் பகுதியில் <u>உதவி(HELP)</u> என்று டைப் செய்து, அதன் சிறப்பம்சங்களை காணலாம் பயிரிடும் போது அதற்கான வெகுமதியும், முத்திரையும்(label) உங்கள் வாலட்டில் சேர்க்கப்படும்.</>;
                    case 'useYourWallet':return 'உங்களின் புதிய வால்ட் கொண்டு';
                    case 'uses':return ['பயிர் காப்பீட்டை பெறலாம்.','பயிர் கடனை பெறலாம்.','தேவைக்கேற்ப, முதலீட்டு கடனை பெறலாம்.',
                                        'ஒவ்வொரு விளைச்சலின் பயிர் முத்திரையைக்(label) கொண்டு உலகளாவிய விற்பனை செய்யலாம்.',
                                        'பயிர் அறுவடைக்கு முன்பே,உங்களால் விற்பனை செய்ய இயலும்.','CHT என்பது உங்களின் சிட்டா உரிமையாளர் பங்கு ஆகும்.'
                                    ];
                    case 'note':return <>குறிப்பு :&nbsp;<b>{org}</b> அதிகாரியை தொடர்பு கொண்டு உங்களுக்கான மேற்கண்ட சிறப்பம்சங்களால் பயன் பெறலாம், அனைத்து சிறப்பம்சங்களையும் ஒருவரால் உபயோகிக்க இயலாது.</>;
                    case 'toGetRewards':return 'உங்களின் வெகுமதியையும், டோக்கனையும் எப்படி பெறலாம்?';
                    case 'rewards':return <>முதலில், நீங்கள் உங்கள் விளை நிலங்களை பதிவு செய்வது அவசியம் பதிவிற்கு பிறகு {org} அதிகாரி உங்களின் விளை நிலங்களின் எல்லைகளை சரிபார்ப்பார். அதன் பிறகே, உங்களுக்கான வெகுமதி சில தினங்களில் வழங்கப்படும். வெகுமதியை பெற தாமதமானால் உங்களின் {org} அதிகாரியை தொடர்பு கொள்ளலாம்.</>;
                    case 'satellite':return 'எங்களின் செயற்கைக்கோள், புதிய விளைச்சலை உங்கள் விளை நிலங்களில் காணும் ஒவ்வொரு முறையும், ஒரு டோக்கன் உங்களுக்கு வழங்கப்படும்.மொத்த டோக்கன்களின் எண்ணிக்கையானது, எங்களின் விளைச்சல் மதிப்பீட்டை பொறுத்தது.';
                    case 'checkAcBal':return 'உங்களின் வங்கி கணக்கை பார்ப்பது எப்படி?';
                    case 'accBal':return <>வாட்ஸாப்(Whatsapp) செயலியில், சிட்டா பாட்(Chitta Bot) உரையாடல் பகுதியில், <u>ACCOUNT</u> என்று டைப் செய்து உங்களின் வங்கி கணக்கில் உள்ள தொகையை காணலாம்.</>;
                    default:return ''
                }
            } 
            default:{
                switch(type){
                    case 'warning':return 'READ THIS DOCUMENT CAREFULLY';
                    case 'appName':return 'CHITTA';
                    case 'greeting':return 'Dear';
                    case 'wishes':return 'Congratulations. You are the owner of a digital Chitta wallet.';
                    case 'pincode':return <>pincode : {pin} &nbsp; <u><i>never share this code</i></u></>
                    case 'key':return 'KEY';
                    case 'whatCanYouDo':return 'What can you do with a digital wallet?';
                    case 'digitalWallet':return <>The wallet is like a free digital bank account. Use it with the Chitta Bot on Whatsapp. In the Chitta conversation, type <u>HELP</u> to discover new features. As you farm, Chitta will send rewards and labels of detected cultivations in your field to this wallet.</>;
                    case 'useYourWallet':return 'Use your new wallet to';
                    case 'uses':return ['Get crop insurance.','Get a crop loan.','Get investment loans that are uniquely tailored to your needs.',
                                        'Sell your crops on a global exchange, using the crop label of each cultivation.',
                                        'Sell your crops as a future before harvest.','Hold CHT as your ownership share of Chitta.'
                                    ];
                    case 'note':return <>* Ask the office manager of <b>{org}</b> for the available possibilities. Not all features may be open for you.</>;
                    case 'toGetRewards':return 'How can I receive rewards and tokens?';
                    case 'rewards':return <>To get any rewards and tokens, you need to register your fields. After registration, your {org} office manager has to check the field boundaries. If you are not receiving any rewards, please go to {org} and ask to complete the field check. It can take a few days before you get the rewards.</>;
                    case 'satellite':return 'You receive a token each time our satellites detect a new cultivation on your land. This can be a few days or a few weeks after planting. We will give you the amount of tokens proportional to the yield we estimate.';
                    case 'checkAcBal':return 'How can I check my account balance?';
                    case 'accBal':return <>Type <u>ACCOUNT</u> in the Chitta Bot conversation on Whatsapp.</>;
                    default:return ''
                }
            }
        };
    };

    return (
        <div className="print-container" style={{ margin: "1em", padding: '0 2em 2em 2em' }}>
            { pages.map((page, i) => 
                <div className="page-break" key={i}>
                    <br/>
                    <br/>
                    <Grid container justifyContent='center'>
                    <Typography variant='button' >{getLanguageWiseContent(page.language,'warning')}</Typography>
                    </Grid>
                    <br/>
                    <Grid container alignContent='center' direction='column'>
                        <img src={logo} height='50px' width='50px' alt="chitta" />
                        <Typography variant='button' >{getLanguageWiseContent(page.language,'appName')}</Typography>
                    </Grid>
                    <br/>
                    <Grid>
                    <Typography variant='body1' >{getLanguageWiseContent(page.language,'greeting')} {page.firstName} {page.lastName},</Typography>
                    <br/>
                    <Typography variant='body1' ><b>{getLanguageWiseContent(page.language,'wishes')}</b></Typography>
                    <br/>
                    <Typography variant='button' >{getLanguageWiseContent(page.language,'pincode',page.pin)}</Typography>
                    <br/><br/>
                    <Typography variant='button' >{getLanguageWiseContent(page.language,'key')} : {page.PK} </Typography>
                    <br/><br/>
                    <Typography variant='body1' ><b>{getLanguageWiseContent(page.language,'whatCanYouDo')}</b></Typography>
                    <br/>
                    <Typography variant='caption' >{getLanguageWiseContent(page.language,'digitalWallet')}</Typography>
                    <br/>
                    <Typography variant='caption' >{getLanguageWiseContent(page.language,'useYourWallet')}: </Typography >
                    <ul>
                        {getLanguageWiseContent(page.language,'uses').map((use,i)=>{
                            return <li key={i}><Typography variant='caption' >{use}</Typography ></li>
                        })}
                    </ul>
                    <Typography variant='caption' ><i>{getLanguageWiseContent(page.language,'note')}</i></Typography >
                    <br/><br/>
                    <Typography variant='body1' ><b>{getLanguageWiseContent(page.language,'toGetRewards')}</b></Typography>
                    <br/>
                    <Typography variant='caption' >{getLanguageWiseContent(page.language,'rewards')}</Typography>
                    <br/>
                    <Typography variant='caption' >{getLanguageWiseContent(page.language,'satellite')}</Typography>
                    <br/><br/>
                    <Typography variant='body1' ><b>{getLanguageWiseContent(page.language,'checkAccBal')}</b></Typography>
                    <br/>
                    <Typography variant='caption' >{getLanguageWiseContent(page.language,'accBal')}</Typography>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default createPagesWithJS; 
