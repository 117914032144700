import React from 'react';
import {Button,Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SignupForm from '../../forms/Signup';
import { REDIRECT_TO_MANUAL } from '../../../constants';
import { CustomModal } from '../../widgets/CustomModal';

const SignUpFields = (props) => {
    const {backToMnemonic,handleClose,signupValue,handleOnChange,signUpError,validateInput,
    copyMnemonic,disabled,CreateAlgoRandAccount,mnemonic,handleDirectVerify}=props;

    const connectMethod=useSelector(state=>state.user.userInfo.connectMethod);

    const handleOnClick=()=>{
        if(connectMethod!==REDIRECT_TO_MANUAL)CreateAlgoRandAccount();
        else handleDirectVerify();
    };

    return (
        <>
            <CustomModal.Header onClose={handleClose} className='no-sub' hasArrow={connectMethod!==REDIRECT_TO_MANUAL} onArrow={backToMnemonic}>
                <Typography variant='body1' id='task-title'>NEW WALLET. PLEASE PROVIDE DETAILS</Typography>
            </CustomModal.Header>
            <CustomModal.Body>
                <SignupForm
                    signupValue={signupValue}
                    handleOnChange={handleOnChange}
                    mnemonic={mnemonic}
                    error={signUpError}
                    validateInput={validateInput}
                    copyMnemonic={copyMnemonic} />
            </CustomModal.Body>
            <CustomModal.Footer className='has-border'>
                <Button className={`btn ${disabled ? 'disabled' : ''}`} disabled={disabled} onClick={handleOnClick}>verify by SMS</Button>
            </CustomModal.Footer>
        </>
    );
};

export default SignUpFields;
