import React,{useState} from 'react';
import {Button ,TextField,Grid,Typography} from '@material-ui/core';
import { useDispatch} from 'react-redux';
import {reSendCode,confirmAccount, clearUserCreds} from '../../../actions/user';
import { CustomModal } from '../../widgets/CustomModal';

const VerifyOTP = ({handleClose}) => {
    const dispatch=useDispatch();
    const [code, setCode] = useState('');

    const handleConfirmAccount=()=>{
        const cb=(success)=>{
            if(success){handleClose();setCode('');}
        };

        dispatch(confirmAccount(code,cb));
    };

    const handleDiscard=()=>{
        dispatch(clearUserCreds());
        handleClose();
        setCode('');
    };

    return (
        <>
            <CustomModal.Header onClose={handleClose} className='no-sub'>
                <Typography variant='body1' id='task-title'>ENTER THE ONE-TIME CODE WE SEND YOU IN A SMS</Typography>
            </CustomModal.Header>
            <CustomModal.Body>
                <Grid container style={{justifyContent:'center',height:'100%'}}>
                    <TextField 
                    id='text-field'
                    className='text-code'
                    style={{ alignSelf: 'center', margin: '1em',  fontSize: '25px' }} 
                    onChange={(e) => setCode(e.target.value)} />
                </Grid>
            </CustomModal.Body>
            <CustomModal.Footer className='verify-footer'>
                <Button className='btn' onClick={handleDiscard}>discard</Button>
                <Button className={`btn ${code.trim().length ? 'disabled' : ''}`} onClick={() => dispatch(reSendCode())} disabled={code.trim().length}>send again</Button>
                <Button className={`btn ${!code.trim().length ? 'disabled' : ''}`} onClick={handleConfirmAccount} disabled={!code.trim().length}>confirm</Button>
            </CustomModal.Footer>
        </>
    );
};

export default VerifyOTP;