import React from 'react';
import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import IntroCard from './introCard';
import Initial from './Initial';
import { ECOSYSTEM, STATS, CARDS } from '../../constants';

const Welcome = ({setShow}) => {
    return(
        <Grid>
            <Grid className='welcome-bg' />
            <Grid className='welcome'>
                <Initial setShow={setShow}/>
                <Grid xs={12} sm={8} md={8} lg={5} xl={3} item className='containers'>
                    <Typography id='subtitle'>GET STARTED</Typography>
                    <Card className='card_expand_element' style={{ backgroundColor:'#EC3C93'}}>
                        <CardContent>
                            <Typography style={{ fontWeight: '500', color: 'white'}}>CONNECT YOUR NEW DIGITAL FARM COOPERATIVE AND GET UP TO 100 CHT FOR EVERY 10 FIELD REGISTRATIONS. EQUAL TO 10.000 RS</Typography>
                        </CardContent>
                    </Card>
                    { CARDS.map((card) => 
                    <IntroCard key={card.id} link={card.link} media={card.media} title={card.title} text={card.text} paragraph={card.paragraph2}/>
                    )}
                </Grid>
                <Grid xs={12} sm={8} md={8} lg={5} xl={3} item className='containers'>
                    <Typography id='subtitle'>STATS</Typography>
                    <Grid container >
                        { STATS.map((c) => 
                            <Grid item xs={6} md={4} lg={3} key={c.id} style={{justifyContent:'center'}}>
                                <Card className='card_element'>
                                    <CardContent>
                                        <Grid item><Typography gutterBottom variant='h3' style={{fontWeight:'700', padding: '1em 0 1em 0'}}>{c.value}</Typography></ Grid>
                                        <Grid item><Typography gutterBottom variant='body2' style={{fontWeight:'700'}}>{c.title}</Typography></ Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                    <Typography id='subtitle' style={{ marginTop: '5em'}}>ECOSYSTEM</Typography>
                    <Grid container >
                        { ECOSYSTEM.map((c) => 
                            <Grid item xs={6} md={4} lg={3} key={c.id} style={{justifyContent:'center'}}>
                                <Card className='card_element'>
                                    <CardContent >
                                        <Grid item><Typography gutterBottom variant='body1' style={{fontWeight:'500', color: 'lightgrey', padding: '3em 0 3em 0'}}>{c.date}</Typography></Grid>
                                        <Grid item><Typography gutterBottom variant='body1' style={{fontWeight:'700'}}>{c.value}</Typography></Grid>
                                        <Grid item><Typography gutterBottom variant='body2' style={{fontWeight:'500'}}>{c.category}</Typography></Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Welcome;