import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cache} from 'aws-amplify';
import { onLoad, setTheme} from '../../actions/user';
import {LinearLoader} from '../widgets/Loader';
import PostConnect from './post-connect/PostConnect';
import Introduction from '../introduction';

const Layout = () =>{
    
    const [loading,setLoading]=useState(true);
    const theme=useSelector(state=>state.user.theme);
    const connected = useSelector(state => state.user.userInfo.connected);
    
    const dispatch=useDispatch();
    
    useEffect(() => {
       setLoading(true);
       dispatch(onLoad((load)=>setLoading(load)));
       dispatch(setTheme(Cache.getItem('theme')));
    }, []);

    return <div className={`layout ${theme}`}>
        {loading?<LinearLoader/>:<>{connected?<PostConnect/>:<Introduction/>}</>}
    </div>
};

export default Layout;