import React,{ useState} from 'react';
import {Grid, Button, Typography, CircularProgress} from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { CustomModal } from './CustomModal';
import { deleteField, onRemoveField, setSelectedFieldId } from '../../actions/members';

const DeleteFieldConfirmModal=(props)=>{
    const {open,onClose,selectedFieldId,fieldsList}=props;

    const dispatch=useDispatch();
    const [request, setRequest ]= useState(false);
    const field=fieldsList.find(item=>item.id===selectedFieldId);
    
    const handleDelete=()=>{
        setRequest(true);
        dispatch(deleteField(selectedFieldId,(params)=>{
            if(params){
                onClose();
                dispatch(onRemoveField(params.farmerID,params.id));
                dispatch(setSelectedFieldId());
            }
            else setRequest(false);
        }));
    };
    
    return(
        <CustomModal open={open} onClose={onClose} className='check-modal'>
            <CustomModal.Header onClose={onClose}>
                <Typography variant='body1' id='task-title'>DELETE FIELD</Typography> 
                </CustomModal.Header>
            <CustomModal.Body>
                <Grid container justifyContent='center' alignItems='center' direction='column'>
                    {!request ?
                    <Typography variant='body1' >Are you sure you want to delete <b>{field.fieldName}</b> from Chitta?</Typography>
                    : <CircularProgress/>}
                </Grid>
            </CustomModal.Body>            
            <CustomModal.Footer className='has-border'>
                <Button className='btn' onClick={handleDelete}>Yes, delete {field.fieldName}</Button>
            </CustomModal.Footer>
        </CustomModal>
    );
};

export default DeleteFieldConfirmModal;