import React from 'react';
import { Grid,Popper,Divider,Typography, ClickAwayListener} from '@material-ui/core';
import { useSelector } from 'react-redux';

const FieldUpdatePopup = (props) => {
  const {id,open,fieldUpdateEl,setFieldUpdateEl,isEditable,cancelHandler,setConfirmDelete}=props;

  const theme=useSelector(state=>state.user.theme);
    
    return (
    <>
      <Popper
        id={id}
        className={`field-update-drop ${theme}`}
        open={open}
        anchorEl={fieldUpdateEl}
        onClose={()=>setFieldUpdateEl(null)}
        role={undefined}
        transition
        disablePortal
        >
        <ClickAwayListener onClickAway={(e)=>{e.stopPropagation();setFieldUpdateEl(null);}}>
        <Grid container className='drop-list'>
            {isEditable&&
            <>
                <Typography className='drop-item inactive'>split-in-two</Typography>
                <Divider className='divider'/>
                <Typography className='drop-item inactive'>merge</Typography>
                <Divider className='divider'/>
                <Typography className='drop-item' onClick={(e)=>{e.stopPropagation();setConfirmDelete(true)}}>delete</Typography>
                <Divider className='divider'/>
            </>}
            <Typography className='drop-item' onClick={(e)=>{e.stopPropagation();cancelHandler()}}>cancel</Typography>
        </Grid>
        </ClickAwayListener>
      </Popper>
    </>
    )
}

export default FieldUpdatePopup
