import React from 'react';
import { Grid,LinearProgress } from '@material-ui/core';
import '../../../assets/scss/loader.scss';

export const LoadingStyle = () => {
    return (
        <div className="loading-style">
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export const LinearLoader = ({width,minHeight}) => {
    return(
        <>
        <LinearProgress color="primary" style={{ width: width?width:'100%'}}/>
        <Grid container id='no-field' style={{ minHeight: minHeight?minHeight:''}}>Loading<LoadingStyle/></Grid>
        </>
    );
};

export const HorizontalLoader = () =>{
    return (
        <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
    