import React from 'react';
import { Grid,Popper,Divider,Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFieldId } from '../../actions/members';

const FieldPopup = (props) => {
  const {id,open,fieldEl,setFieldEl,setDrawPolygon,setIsEditable,setSidebarCollapse,setRegister}=props;
  
  const dispatch=useDispatch();
  const theme=useSelector(state=>state.user.theme);
  
  const handleDrawField=()=>{
    setFieldEl(null);
    setDrawPolygon(true);
    dispatch(setSelectedFieldId());
    setIsEditable(false);
    setSidebarCollapse(true);
  };
  
  return (
    <>
      <div className='back-drop' onClick={()=>setFieldEl(null)}></div>
      <Popper
        id={id}
        className={`field-drop ${theme}`}
        open={open}
        anchorEl={fieldEl}
        onClose={()=>setFieldEl(null)}
        >
        <Grid container className='drop-list'>
            <Typography className='drop-item' onClick={()=>setRegister(true)}>add farmer</Typography>
            <Divider className='divider'/>
            <Typography className='drop-item' onClick={handleDrawField}>draw field</Typography>
            {/* <Divider className='divider'/>
            <Typography className='drop-item' onClick={handleDrawField}>draw property</Typography> */}
        </Grid>
      </Popper>
    </>
  );
};
  
export default FieldPopup;