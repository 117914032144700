import area from '@turf/area';
import center from '@turf/center';
import { API} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { ASSETS, BUFFER_STRIPS, CHEMICAL_MANURE, CLAY_MINERAL_CONTENT,
   COVER_CROPS, CROP_ROTATION, EROSION, EXPERTISE, GREEN_MANURE, GRID_LIST, 
   IRRIGATION, LEASED, MARKET_ALIGNMENT, NITROGEN, NO_TILL, N_P_RATIO, PEST_CONTROL, PH, 
   PHOSPOROUS, POTASSIUM, SALINITY, SEASONS, SOIL_CRUST, SOIL_MANAGEMENT_SCORE,
    SOIL_ORGANIC_CARBON, WATER_STORAGE,ANIMAL_MANURE } from '../constants';

export const groupBy=(arr, property)=>{
   return arr.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
         acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
   }, {});
};

export const getAreaSize=(data)=>{
   let size=(parseFloat(area(data) * 0.00024711)*100).toFixed(2);
   return size;
};

export const getCenter=(data)=>{
   let centerPoints=center(data);
   return{lng:centerPoints?.geometry?.coordinates[0],lat:centerPoints?.geometry?.coordinates[1]}
};

export const leading0 = (num) => num < 10 ? "0" + num : num;

export const timerUnits=[
  {id:1,unit:"days",pl:"10px"},
  {id:2,unit:"hours",pl:"10px"},
  {id:3,unit:"minutes",pl:"4px"},
  {id:4,unit:"seconds",pl:"1px"},
];

export const CheckOneByOne = async (farmer, i) => {
  // verify phone number
  const isAvailable = await API.graphql({ query: queries.getMember, variables: { phoneNumber: farmer.phoneNumber }});

  if (isAvailable.data.getMember !== null){
      return Promise.resolve(i) 
  } 
  else return null
};

export const CheckIfPhoneNumbersHaveBeenRegistered = (members) => {
  return Promise.all(members.map((farmer, i) => CheckOneByOne(farmer, i)))
};

export const AccountNeedsOptIn = (wallet) => {
  const D = [];
  ASSETS.forEach(type => {    
    if (type.type === 'asset'){
      let isAvailable = wallet.assets.some(id => id['asset-id'] === type.id )
      if (!isAvailable){D.push(isAvailable ? null : { id: type.id, name: type.name, type: 'asset' })}  
    }else {
      let isAvailable = wallet['apps-local-state'].some(id => id['id'] === type.id )
      if (!isAvailable){D.push(isAvailable ? null : { id: type.id, name: type.name, type: 'app' })}  
    };
  });

  return D;
};

export const OptInItems = (optIn) => {
  if (optIn !== undefined) {
      return optIn.optin.length === 1 ? optIn.optin[0].name : optIn.optin.length === 2 ? `${optIn.optin[0].name} and ${optIn.optin[1].name}` : optIn.optin.length > 2 ? 'new commodities and coins on Chitta' : false
  }else return false;
};

export const filterByLastOverpass = (acc, cur) => {
  const SatIndex = acc.findIndex(e => e.type === cur.type && e.grid === cur.grid);
  
  if (SatIndex !== -1){
    // check if match is later, if so update, else, discard.
    acc[SatIndex] = parseInt(acc[SatIndex].dt) < parseInt(cur.dt) ? cur : acc[SatIndex];
  };
  
  return SatIndex !== -1 ? acc : [...acc, cur];
};

export const fetchGrid=(fields)=>{
  let grids=[];
  
  if(fields.length){
    fields.forEach(field=>{
      if(field.TileGrid&&!grids.includes(field.TileGrid))grids.push(field.TileGrid)
    });
  }else {
    GRID_LIST.forEach(e=>{
      if(!grids.includes(e.grid))grids.push(e.grid)
    });
  }
  
  return grids;
};

export const getTimeUntil = (nextOverpass) => {    
  const df = Boolean(nextOverpass) ? nextOverpass.dl - Date.parse(new Date()) : 0;
  let timeLeft = {};
  if (df < 0) {
    timeLeft = {
      days: Math.floor(df / (1000 * 60 * 60 * 24 * -1)),
      hours: Math.floor((df / (1000 * 60 * 60)) % 24 * -1),
      minutes: Math.floor((df / 1000 / 60) % 60 * -1),
      seconds: Math.floor((df / 1000) % 60 * -1),
      T: true
    };
  }else {
    timeLeft = {
      days: Math.floor(df / (1000 * 60 * 60 * 24)),
      hours: Math.floor((df / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((df / 1000 / 60) % 60),
      seconds: Math.floor((df / 1000) % 60),
      T: false
    };
  };

  return timeLeft;
};

export const getFormattedName=name=>{
  const NAME=name.split(' ');
  const formattedName=`${NAME[0]} ${ NAME[1]?NAME[1][0]:''}`;
  
  return formattedName;
};

export const toFixedOf=(value, fixedOf)=>{
  return !isNaN(value) && !isNaN(fixedOf) ? parseFloat(parseFloat(value).toFixed(fixedOf)) : value;
};

export const formatNumberToSmallScale = (number) => {
  if ((number / Math.pow(10, 9)) >= 1) return `${toFixedOf(number / Math.pow(10, 9), 2)}B`
  else if ((number / Math.pow(10, 6)) >= 1) return `${toFixedOf(number / Math.pow(10, 6), 2)}M`
  // else if ((number / Math.pow(10, 3)) >= 1) return `${toFixedOf(number / Math.pow(10, 3), 2)}K`
  
  return number;
};

export const searchWord = (text, word) =>
  text ? (text.trim().toLowerCase().search(word.replace(/[*+?^${}()|[\]\\]/g, '\\$&').trim().toLowerCase()) >= 0) : false;

  export const getSequentialValue = (option, field) => {
    let color = "#d6eff5";
    let defaultColor = "#3cb2d0";
  
    switch (option.name) {
      case PH.label: {
        const value = field.assess.length?Math.abs(field.assess[0]?.ph[0]):null;
        if (value !== null) {
          if (value === 14) color = PH.colors.high;
          else if (value > 0 && value < 14)color = getSequentialColor(PH, value, 2);
          else if (value === 0) color = PH.colors.low;
          return [color, 1];
        } else return [defaultColor, 0];
      }
      case WATER_STORAGE.label: {
        const ws = field.assess.length ? field.assess[0]?.ws[0] : null;
        if (ws !== null) {
          if (ws >= 1.5) color = WATER_STORAGE.colors.high;
          else if (ws > 0 && ws < 1.5)color = getSequentialColor(WATER_STORAGE, ws, 10);
          else if (ws === 0) color = WATER_STORAGE.colors.low;
  
          return [color, 1];
        } else return [defaultColor, 0];
      }
      case IRRIGATION.label: {
        const irr = field.assess.length ? field.assess[0]?.irr[0] : null;
        if (irr !== null) {
          if (irr <= 0.5) color = IRRIGATION.colors.low;
          else color = IRRIGATION.colors.high;
  
          return [color, 1];
        } else return [defaultColor, 0];
      }
      case NO_TILL.label: {
        const tillage = field.assess.length ? field.assess[0]?.tillage[0] : null;
        if (tillage !== null) {
          if (tillage <= 0.5) color = NO_TILL.colors.low;
          else color = NO_TILL.colors.high;
  
          return [color, 1];
        } else return [defaultColor, 0];
      }
      case CLAY_MINERAL_CONTENT.label: {
        const clay = field.assess.length ? field.assess[0]?.clay[0] : null;
        if (clay !== null) {
          if (clay >= 1.5) color = CLAY_MINERAL_CONTENT.colors.high;
          else if (clay > 0 && clay < 1.5)color = getSequentialColor(CLAY_MINERAL_CONTENT, clay, 10);
          else if (clay === 0) color = CLAY_MINERAL_CONTENT.colors.low;
  
          return [color, 1];
        } else return [defaultColor, 0];
      }
      case SOIL_CRUST.label: {
        const crust=field.assess.length? Math.abs(field.assess[0]?.crust[0]): null;
        if(crust!==null){
          if (crust === 1) color = SOIL_CRUST.colors.high;
          else if (crust > 0 && crust < 1)color = getSequentialColor(SOIL_CRUST, crust, 16);
          else if (crust === 0) color = SOIL_CRUST.colors.low;
  
          return [color, 1];
        } else return [defaultColor, 0];
      }
      case SALINITY.label: {
        const salinity = field.assess.length ? field.assess[0]?.salt[0] : null;
        if (salinity !== null) {
          if (salinity === 5000) color = SALINITY.colors.high;
          else if (salinity > 0 && salinity < 5000)color = getSequentialColor(SALINITY, salinity, 0.006);
          else if (salinity === 0) color = SALINITY.colors.low;
  
          return [color, 1];
        } else return [defaultColor, 0];
      }
      case EROSION.label: {
        const erosion = field.assess.length ? field.assess[0]?.erosion[0] : null;
        if (erosion !== null) return [EROSION.colors.high, 1];
        else return [defaultColor, 0];
      }
      case SOIL_ORGANIC_CARBON.label: {
        const SOC = field.assess.length ? field.assess[0]?.SOC[0] : null;
        if (SOC !== null) {
          if (SOC === 1) color = SOIL_ORGANIC_CARBON.colors.high;
          else if (SOC > 0 && SOC < 1)color = getSequentialColor(SOIL_ORGANIC_CARBON, SOC, 15);
          else if (SOC === 0) color = SOIL_ORGANIC_CARBON.colors.low;
  
          return [color, 1];
        } else return [defaultColor, 0];
      }
      case POTASSIUM.label: {
        return [POTASSIUM.colors.high, 1];
      }
      case PHOSPOROUS.label: {
        return [PHOSPOROUS.colors.high, 1];
      }
      case NITROGEN.label: {
        return [NITROGEN.colors.high, 1];
      }
      case PEST_CONTROL.label: {
        return [PEST_CONTROL.colors.high, 1];
      }
      case GREEN_MANURE.label: {
        return [GREEN_MANURE.colors.high, 1];
      }
      case ANIMAL_MANURE.label: {
        return [ANIMAL_MANURE.colors.high, 1];
      }
      case CHEMICAL_MANURE.label: {
        return [CHEMICAL_MANURE.colors.high, 1];
      }
      case BUFFER_STRIPS.label: {
        return [BUFFER_STRIPS.colors.high, 1];
      }
      case COVER_CROPS.label: {
        return [COVER_CROPS.colors.high, 1];
      }
      case CROP_ROTATION.label: {
        return [CROP_ROTATION.colors.high, 1];
      }
      case N_P_RATIO.label: {
        return [N_P_RATIO.colors.high, 1];
      }
      case SEASONS.label: {
        return [SEASONS.colors.high, 1];
      }
      case EXPERTISE.label: {
        return [EXPERTISE.colors.high, 1];
      }
      case MARKET_ALIGNMENT.label: {
        return [MARKET_ALIGNMENT.colors.high, 1];
      }
      case LEASED.label: {
        return [LEASED.colors.high, 1];
      }
      case SOIL_MANAGEMENT_SCORE.label: {
        return [SOIL_MANAGEMENT_SCORE.colors.high, 1];
      }
      default: {
        return [defaultColor, 0];
      }
    }
  };
  
  const getSequentialColor = (seq, step, diff) => {
    const hue = seq.colors.low.split("(")[1].split(",")[0];
    const lightness = seq.colors.low.split("(")[1].split(",")[2].split("%")[0];
    return `hsl(${Math.floor(parseInt(hue) + diff * step)},100%,${lightness}%)`;
  };