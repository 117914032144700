import React from 'react'
import { Grid,Typography} from '@material-ui/core';
import { SEQUEL_COLORS } from '../../constants';

const Legends = ({selectedOption}) => {
  const label=selectedOption.name;
  return (
    <Grid className="legend">
        <Grid className="colorScale" style={{background: `linear-gradient(to right, ${SEQUEL_COLORS[label].low}, ${SEQUEL_COLORS[label].high})`}}/>
        <Grid style={{display:'flex',justifyContent:'space-between'}}>
          <Typography style={{fontWeight:'bold',color:'white'}}>LOW</Typography>
          <Typography style={{fontWeight:'bold',color:'white'}}>HIGH</Typography>
        </Grid>
    </Grid>
  )
}

export default Legends