import {API,Cache } from 'aws-amplify';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import { Snack } from '../components/widgets/Toast';
import {ALGO_SIGNER, ALGORAND_MOBILE, REDIRECT_TO_MANUAL, MANUAL, SIGN_IN_AMOUNT } from '../constants';
import {SET_CONNECT_METHOD,SET_REWARD,SET_ACCOUNTS} from '../constants/action-types';
import { generatePassword,signedTx,txnOptin} from '../helpers/algorand';
import config from '../constants/config';
import { signIn ,setUserCreds} from './user';
import Processing from '../components/widgets/Processing';
import { setAssets } from './user';

const sdk = require('algosdk');

const testnetServer = "https://testnet-api.algonode.cloud/";

const token = '';
const port = '';
  
export let algodClient = new sdk.Algodv2(token, testnetServer, port);

export const setConnectMethod=data=>({type:SET_CONNECT_METHOD,payload:data});

export const setAccounts = (accounts) => ({type: SET_ACCOUNTS,payload: accounts});

export const setRewards=reward=>({type:SET_REWARD,payload:reward});

export const connectAlgoSigner=(cb,selected)=>async (dispatch)=>{
    let algoSigner=window.AlgoSigner;

    algoSigner.connect().then(()=>{

        //getting testnet accounts
        algoSigner.accounts({ ledger: 'TestNet' }).then( acc => {
            let to = config.wallet.TO_TX_ID;
            dispatch(setAccounts(acc));
            if(acc.length===1||selected!==undefined){
                try{
                    if(acc.length){
                        const from=acc.length===1?acc[0].address:acc[selected].address;
                        signedTx(from,to,SIGN_IN_AMOUNT).then((sdkTx) => {
                            // Get the binary and base64 encode it

                            let base64Tx = algoSigner.encoding.msgpackToBase64(sdkTx.toByte());
                            
                            algoSigner.signTxn([{ txn: base64Tx }])
                            .then((resp)=>{
                                const userName=from;
                                const password=generatePassword(resp[0].blob);
                                const onResponse=(success)=>{
                                    if(!success){
                                        Snack('Please create an account manually.',4000,null,'info',true,{toastId: "create-algo-acc"});
                                        dispatch(setConnectMethod(REDIRECT_TO_MANUAL));
                                        dispatch(setUserCreds(userName,password));
                                    }else if(cb)cb(false);
                                };
                                dispatch(signIn(userName,password,null,onResponse));
                            });
                        });
                    }else Snack('Please add TestNet account in algosigner.',4000,null,'info',true,{toastId:'add-testnet'})
                }catch(e){
                console.log('Transaction sign error--> ',e)
            }}
        });
    }).catch(e=>Snack('Please create wallet for further process!',4000,null,'error',true));
};

export const connectAlgorandMobile=(cb)=>async (dispatch)=>{

    const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
        qrcodeModal: QRCodeModal,
    });

    if (!connector.connected) {
        connector.createSession();

        connector.on("connect", (error, payload) => {
            const { accounts } = payload.params[0];

            try{
                let to = config.wallet.TO_TX_ID;
                signedTx(accounts[0],to,SIGN_IN_AMOUNT).then((sdkTx) => {
                    const encodedTxn = Buffer.from(sdk.encodeUnsignedTransaction(sdkTx)).toString("base64");
                    const userName=accounts[0];
                    const password=generatePassword(encodedTxn);
                    
                    const onResponse=(success)=>{
                        if(!success){
                            Snack('Please create an account manually.',4000,null,'info',true,{toastId: "create-algo-acc"});
                            dispatch(setConnectMethod(REDIRECT_TO_MANUAL));
                            dispatch(setUserCreds(userName,password));
                        }else {
                            if(cb)cb(false);
                        };
                    };
                    dispatch(signIn(userName,password,null,onResponse));
                });
            }catch(e){
                console.log('Transaction sign error--> ',e)
            }
        });
    }
};

export const createWalletandEncrypt = (params,cb=null) => async () => {
    try{
        await API.get('sendsampleapi', '/wallets', params).then((response)=>{
            if(cb)cb(response);
        });
    }catch(e){
        console.log('unable to request sendsample API (/wallets) -->',e);
        if(cb)cb([]);
    }
};

export const connectWallet=(methodId,selected,cb=null)=>async (dispatch)=>{
    Snack('Connecting wallet...', 2000, Processing(),'dark',false,{toastId:'connecting-wallet'});
    
    try{
        switch(methodId){
            case ALGO_SIGNER :{
                dispatch(connectAlgoSigner(cb,selected));
                break;
            }
            case ALGORAND_MOBILE :{
                dispatch(connectAlgorandMobile(cb));
                break;
            }
            default:
                break;
        }
    }catch(e){
        console.log('connect-wallet-error--> ',e);
        dispatch(setConnectMethod(MANUAL));
        if(cb) cb(true);
    }
};

export const handleOptIn = () => async (dispatch,getState) => {
    const mode = Cache.getItem('loginMode');
    const {wallet,session}=getState().user.userInfo;
    
    switch(mode){
        case ALGO_SIGNER:{
            let algoSigner = window.AlgoSigner;
            if(algoSigner){
                console.log('algosigner')
                algoSigner.accounts({ ledger: 'TestNet' }).then(acc => {
                    try {
                        if(acc.length) {
                            txnOptin(wallet.address, wallet.address, 0, session.optin)
                            .then(async (resp) => {
                                if(resp){
                                    let binarySignedTxs = resp.map((tx) => algoSigner.encoding.base64ToMsgpack(tx.blob));
                                    let sendTxn = await algodClient.sendRawTransaction(binarySignedTxs).do();
                                    if (sendTxn) {
                                        Snack('Done. you can now receive new assets', 4000, null, 'success', true);
                                        dispatch(setAssets());
                                    }
                                }
                            });
                        } else Snack('Please add TestNet account in algosigner.', 4000, null, 'info', true, { toastId: 'add-testnet-algosigner' })
                    } catch (e) {
                        console.log('Transaction sign error--> ', e)
                    }
                });
            }else Snack('Please add algosigner extension!', 4000, null, 'error', true);
            break;
        }
        case ALGORAND_MOBILE:{
            let accounts=[];
            const connector = new WalletConnect({
                bridge: "https://bridge.walletconnect.org", // Required
                qrcodeModal: QRCodeModal,
            });
            
            if (!connector.connected) {
                connector.createSession();
                
                connector.on("connect", (error, payload) => {
                    const { address } = payload.params[0];
                    accounts.push(address[0]);
                });
            }else accounts.push(connector.accounts[0]);

            try {
                if (accounts.length) {
                    txnOptin(accounts[0], accounts[0], 0, session.optin)
                    .then(async (resp) => {
                        if(resp){
                            const requestParams = [resp];
                            const request = formatJsonRpcRequest("algo_signTxn", requestParams);
                            if(request)Snack('Go to your mobile wallet and approve the request!',2000,null,'info',true,null);
                            try{
                                const result = await connector.sendCustomRequest(request);
                                const u8Array = result.map((a)=>{return new Uint8Array(a)});
                                let sendTxn = await algodClient.sendRawTransaction(u8Array).do();
                                if (sendTxn) {
                                    Snack('Done. you can now receive new assets', 4000, null, 'success', true);
                                    dispatch(setAssets());
                                }             
                            }catch(e){
                                Snack(e.message,4000,null,'error',true,null);
                            }
                        }
                    });
                } else Snack('Please add TestNet account in algorand mobile wallet.', 4000, null, 'info', true, { toastId: 'add-testnet-mobile' })
            } catch (e) {
                console.log('Transaction sign error--> ', e)
            }
            break;
        }
        default:{
            Snack('Please signin using any of the wallets to receive optins!', 4000, null, 'error', true);
            break;
        }
    }
};