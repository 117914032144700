import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ASSETS_BY_ID } from '../../../constants';

const LinearProgressWithLabel = (props) => {
    const{value,hasNoMem}=props;

    return (
        <Box display="flex" alignItems="center" width='inherit' className={`${hasNoMem?'has-no-mem':''}`}>
            <Box width='inherit' mr={1}>
                <LinearProgress variant="determinate" color="primary" value={value} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" className="progrees-text">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const GetStarted = ({ hasNoMem }) => {
    const wallet = useSelector(state => state.user.userInfo.wallet);
    const hasCHTAsset = wallet.assets.filter(item => ASSETS_BY_ID[item['asset-id']] === 'CHT');
    const hasAlgo = wallet.amount > 0;
    const hasASA = wallet.assets.length > 0;
    const hasCHT = hasCHTAsset.length?hasCHTAsset[0].amount > 0 : false;
    const hasNFT = wallet.asset;

    const checkList = [
        { id: 1, label: 'SEND SOME ALGO TO YOUR ACCOUNT', checked: hasAlgo },
        { id: 2, label: 'OPTIN TO CROP LABELS FROM YOUR FARMERS PRODUCE', checked: hasASA },
        { id: 3, label: 'RECEIVE REWARDS WHEN GEOFENCING FARMFIELDS', checked: hasCHT },
        { id: 4, label: 'EARN CHT BY DELIVERING ACTIVITY REPORTS', checked: hasCHT },
        { id: 5, label: 'GENERATE SCORECARDS FOR YOUR FARMERS' },
        { id: 6, label: 'CREATE LOAN PLANS FOR YOUR FARMERS' },
        { id: 7, label: 'PUT YOUR CROP LABELS ON SALE' },
        { id: 8, label: 'DEPOSIT CHT TO OFFER INSURANCE' },
        { id: 9, label: 'ASSESS PRODUCTION AND OFFER SOLUTION DRIVEN CREDIT' },
        { id: 10, label: 'SHOWCASE YOUR ACHIEVEMENTS' }
    ];

    const checked = checkList.filter(item => item.checked).length;
    const progress = (100*checked)/checkList.length;
    
    return (
        <Grid container className='get-started-dashboard'>
            <Grid container className='get-started-header'>
                <Grid style={{margin:'0 auto'}}>
                    <Grid style={{width:'26.068rem'}}> 
                        <Typography variant='button' >GETTING STARTED</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className='get-started-content'>
                <Grid container className='check-list'>
                    <Grid className={`${hasNoMem?'has-no-mem':''}`}>
                    {checkList.map(item => <Grid container key={item.id} className={`check-list-item ${item.checked ? 'checked' : ''}`}>
                        <Typography variant='subtitle2'>{item.id}.{item.label}</Typography>
                    </Grid>)}</Grid>
                </Grid>
                <Grid container className='progress-container'>
                    <LinearProgressWithLabel hasNoMem={hasNoMem} value={progress} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GetStarted;
