import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector} from 'react-redux';
import Messages from './Messages';
import Content from './Content';
import Overlay from '../../widgets/Overlay';

const PostConnect = () => {
    const messageCount = useSelector(state => state.user.messageCount);

    return (
        <>
        <Overlay/>
        <Grid container className='post-connect'>
            <Grid container className='message-container' style={{"--message-count":`${messageCount}`}}>
                <Messages/>
            </Grid>
            <Grid container className='content-container' style={{"--message-count":`${messageCount}`}}>
                <Content/>
            </Grid>
        </Grid>
        </>
    );
};

export default PostConnect;