import React from 'react';
import { toast } from 'react-toastify';
import { IconButton, Grid, Typography } from '@material-ui/core';
import { InfoOutlined, WarningOutlined, ErrorOutlined, CheckCircleOutlined } from '@mui/icons-material';

const ToastContent = ({ message,custom, type, icon,show}) => {
    let Icon = <InfoOutlined />;
    if (type === 'success') { Icon = <CheckCircleOutlined /> }
    else if (type === 'warn') { Icon = <WarningOutlined /> }
    else if (type === 'error') { Icon = <ErrorOutlined /> }

    return (
        <Grid container direction='row' className={`custom-toast ${(custom || type === 'dark') ? 'pad' : ''}`}>
            {(type !== 'dark' && icon) && <Grid item ><IconButton >{Icon}</IconButton></Grid>}
            <Grid item ><Typography variant="body2" style={{ color: `${(type !== 'dark') ? 'black' : 'white'}`,marginRight:'0.45rem' }}>
                {message}</Typography></Grid>
            {(show&&custom) && <Grid item >{custom}</Grid>}
        </Grid>
    );
};

export const Snack = (message, autoClose, custom, type, icon, options,show) => {
    const toastOptions = {
        position: 'bottom-right',
        autoClose: autoClose ? autoClose : 4000,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
    };
    
    if (message) {
        toast[type](
            <ToastContent 
            message={message} 
            custom={custom}
            type={type} 
            icon={icon}
            show={show}/>,
            { ...toastOptions, className: `toast-${type}`,...options&&options}
        )
    };
};